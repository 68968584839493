// ------------------------------------
// Action Types
// ------------------------------------
const SET_NODE_DETAILS_OPEN = "SET_NODE_DETAILS_OPEN";
const OPEN_IMPORT_CSV_MODAL = "OPEN_IMPORT_CSV_MODAL";
const SET_LEFT_NODE_PANEL_OPEN = "SET_LEFT_NODE_PANEL_OPEN";
const SET_HOVER_NODE_ON_PANEL = "SET_HOVER_NODE_ON_PANEL";

export {
  SET_NODE_DETAILS_OPEN,
  OPEN_IMPORT_CSV_MODAL,
  SET_LEFT_NODE_PANEL_OPEN,
  SET_HOVER_NODE_ON_PANEL,
};
