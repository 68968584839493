/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import {
  PreviewPanel,
  DotMenu,
  IconButton,
  IconNames,
  Icon,
} from "@pypestream/ui-elements";
import { default as withRouter } from "utils/withRouter";
import config from "../../../../config";
import botManagerAPI from "../../../../bot-manager-api";
import Loader from "../../Loader";

import "./Preview.scss";

const PreviewModal = React.forwardRef(
  (
    {
      customer,
      botData,
      show,
      title,
      flags,
      handleClose,
      handleEdit,
      variant,
      botUserId,
      updateMetadata,
      sandboxPreviewMetadata,
      refreshPreview,
      history,
    },
    ref
  ) => {
    const [previewData, setPreviewData] = useState({});
    const [fetchingWidget, setFetchingWidget] = useState(false);
    const [pypeId, setPypeId] = useState("");
    const [streamId, setStreamId] = useState("");
    const [reloading, setReloading] = useState(false);
    const previewContainer = useRef(null);

    const refTimer = useRef(null);

    const scriptSrc = useMemo(() => {
      return `${config.webserviceEnvConfig.sandbox.wcwLauncherUrl}/api-launcher.js`.replace(
        "webchat",
        "web"
      );
    }, []);

    const menus = useMemo(
      () =>
        flags.pe19501
          ? [{ menu: "Edit Initializing Data", value: "edit" }]
          : [
              { menu: "Edit Initializing Data", value: "edit" },
              { menu: "Restart Solution", value: "restart" },
            ],
      [flags]
    );

    useEffect(() => {
      if (!flags.pe20632) {
        return;
      }
      history.listen(() => {
        if (window.parent?.Pypestream) {
          handleClose(window.parent?.Pypestream);
        }
      });
    }, []);

    const getPreviewMetaData = async (widgetData) => {
      return new Promise((resolve) => {
        const { customerName, botName } = botData.latestVersion;
        const pype_id = widgetData?.data[0]?.pype_id;
        const stream_id = widgetData?.data[0]?.stream_ids[0];
        let resPassthroughData = JSON.stringify({
          preview: `${customerName}.${botName}`,
        });
        setPypeId(pype_id);
        setStreamId(stream_id);

        botManagerAPI
          .getSolutionMetadata("sandbox", customer.gesId, pype_id, stream_id)
          .then((resMetadata) => {
            const metadata =
              resMetadata?.data?.preview_metadata ?? (flags?.pe20222 ? {} : []);
            if (metadata && metadata != "undefined" && metadata != undefined) {
              const metadataMap = flags?.pe20222
                ? metadata
                : (metadata || []).reduce(
                    (res, item) => ({
                      ...res,
                      [Object.keys(item)]: Object.values(item)[0],
                    }),
                    {}
                  );
              resPassthroughData = JSON.stringify({
                preview: `${customerName}.${botName}`,
                ...(metadataMap ?? {}),
              });
              updateMetadata(metadata);
            }
            resolve(resPassthroughData);
          })
          .catch((error) => {
            console.log(error?.message || (typeof error === "string" && error));
            // Resolving this promise on failure as if metadata call fails, we should proceed with default values.
            resolve(resPassthroughData);
          });
      });
    };

    const receiveMessage = useCallback(
      (event) => {
        if (fetchingWidget && event?.data?.type === "SETUP_COMPLETED") {
          setFetchingWidget(false);
        }
      },
      [fetchingWidget]
    );

    useEffect(() => {
      window.addEventListener("message", receiveMessage, false);
      return () => {
        window.removeEventListener("message", receiveMessage, false);
      };
    }, [receiveMessage]);

    const launchWidget = async (widgetData, passThroughData) => {
      const windowExists = typeof window.parent.Pypestream !== "undefined";

      if (!widgetData.error_reason) {
        if (windowExists && previewContainer.current) {
          if (refTimer?.current) {
            clearTimeout(refTimer?.current);
          }

          const Pypestream = window.parent.Pypestream;
          if (widgetData) {
            const config = {
              passthrough: passThroughData || "",
              APP_ID: widgetData[0].widget_ids[0],
              gtm_id: "GTM-P6DV8B4",
              disableWidgetSlideInOutAnimation: true,
            };

            try {
              await Pypestream("boot", config, previewContainer.current);
              Pypestream.show();
            } catch (error) {
              console.error(error);
            }
          }
        } else {
          refTimer.current = setTimeout(() => {
            launchWidget(widgetData, passThroughData);
          }, 500);
        }
      }
    };

    const reloadWidget = async () => {
      try {
        setFetchingWidget(true);
        const { latestVersion } = botData;
        const { id: latestVersionId, customerName, botName } = latestVersion;
        const customerGesId = customer.gesId;
        const currentEnv = "sandbox";
        const body = { customerName, botName };

        await botManagerAPI.setPreviewWidget(latestVersionId);
        const widgetData = await botManagerAPI.getPreviewWidget(
          customerGesId,
          currentEnv,
          body
        );
        const resPassthroughData = await getPreviewMetaData(widgetData);
        setPreviewData(widgetData.data);
        await launchWidget(widgetData.data, resPassthroughData);
      } catch (error) {
        console.log(error?.message || (typeof error === "string" && error));
        setFetchingWidget(false);
      }
    };

    useEffect(() => {
      if (show) {
        reloadWidget();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    const onClose = () => {
      if (window.parent?.Pypestream) {
        handleClose(window.parent?.Pypestream);
      }
    };

    const handleSubmitPassthrough = async (metadata) => {
      const previewMetadata = flags?.pe20257
        ? metadata
        : sandboxPreviewMetadata;

      try {
        JSON.parse(previewMetadata);
        const res = await botManagerAPI.updateSolutionMetadata(
          "sandbox",
          flags.pe22837 ? customer.gesId : botUserId,
          pypeId,
          streamId,
          previewMetadata
        );

        const updatedMetadata = {};
        const parsedMetadata = JSON.parse(previewMetadata).preview_metadata;
        parsedMetadata.forEach((item) => {
          const key = Object.keys(item);
          const value = Object.values(item);
          updatedMetadata[key] = value[0];
        });

        if (res.status === 201) {
          handleRefresh();
        }
      } catch (error) {
        console.log(error?.message || (typeof error === "string" && error));
      }
    };

    const handleRefresh = () => {
      const windowExists = typeof window.parent.Pypestream !== "undefined";

      if (windowExists) {
        if (flags?.pe22564 && window.parent.Pypestream?.restartChat) {
          window.parent.Pypestream.restartChat();
        } else {
          const { Pypestream } = window.parent;
          Pypestream.hide();
          Pypestream.shutdown();
          reloadWidget();
        }
      }
    };

    const onMenuClick = (menu) => {
      switch (menu.value) {
        case "edit":
          handleEdit();
          break;
        case "restart":
          handleRefresh();
          break;
        default:
          break;
      }
    };

    const refreshPreviewMicroapp = () => {
      setReloading(true);
      refreshPreview(() => {
        setReloading(false);
      });
    };

    React.useImperativeHandle(ref, () => ({
      handleSubmitPassthrough: handleSubmitPassthrough,
      refresh: handleRefresh,
    }));

    return (
      <div>
        <Helmet>
          {scriptSrc && (
            <script id="pypesScriptPreview" src={scriptSrc} async={true} />
          )}
        </Helmet>
        <PreviewPanel
          variant={variant}
          open={show}
          extra={
            <DotMenu
              closeOnMouseLeave={false}
              iconName="more"
              menus={menus}
              onClick={(menu) => onMenuClick(menu)}
              placement="bottom"
            />
          }
          title={
            flags.pe19501 ? (
              <div className="preview-solution-header">
                {title}
                {reloading ? (
                  <Icon fill="#0a3ab4" name={IconNames.Loading} rotateIcon />
                ) : (
                  <IconButton
                    data-testid="refresh-icon"
                    iconName={IconNames.Undo}
                    onClick={refreshPreviewMicroapp}
                  />
                )}
              </div>
            ) : (
              title
            )
          }
          onClose={onClose}
          className="previewPanel"
        >
          {show && (
            <div style={{ overflowX: "hidden" }}>
              <div style={{ margin: "0 -10px" }}>
                <div
                  style={{ height: "100%" }}
                  ref={previewContainer}
                  id="previewContainer"
                >
                  {fetchingWidget && !previewData.error_reason && (
                    <div
                      className="flex justify-center items-center absolute inset-0"
                      style={{
                        height: "calc(100vh - 80px)",
                        backgroundColor: "white",
                      }}
                    >
                      <Loader center={false} />
                    </div>
                  )}
                  {previewData.error_reason && (
                    <p style={{ margin: "1rem" }} className="error">
                      There was a problem while fetching your preview solution.
                      Please try again later.
                    </p>
                  )}
                </div>
              </div>
            </div>
          )}
        </PreviewPanel>
      </div>
    );
  }
);

PreviewModal.propTypes = {
  customer: PropTypes.object,
  botData: PropTypes.object,
  flags: PropTypes.object,
  history: PropTypes.object,
  show: PropTypes.bool,
  title: PropTypes.string,
  variant: PropTypes.string,
  botUserId: PropTypes.string,
  sandboxPreviewMetadata: PropTypes.string,
  handleClose: PropTypes.func,
  handleEdit: PropTypes.func,
  updateMetadata: PropTypes.func,
  refreshPreview: PropTypes.func,
};

PreviewModal.defaultProps = {
  customer: {},
  botData: {},
  flags: {},
  show: false,
  title: "",
  variant: "",
  botUserId: "",
  sandboxPreviewMetadata: "",
  handleEdit: () => {},
  updateMetadata: () => {},
  handleClose: () => {},
  refreshPreview: () => {},
};

PreviewModal.displayName = "PreviewModal";

export default withRouter(PreviewModal);
