// ------------------------------------
// Action Types
// ------------------------------------
const SET_SOLUTION_DATA = "SET_SOLUTION_DATA";
const GET_SAGA_EXAMPLE = "GET_SAGA_EXAMPLE";
const RESET_SOLUTION = "RESET_SOLUTION";
const IMPORT_SOLUTION = "IMPORT_SOLUTION";
const ADD_NODE_TO_BOTTOM = "ADD_NODE_TO_BOTTOM";
const ADD_SIBLING_NODE = "ADD_SIBLING_NODE";
const ADD_NODE_BETWEEN_PARENT_CHILD = "ADD_NODE_BETWEEN_PARENT_CHILD";
const DELETE_BOTTOM_NODE = "DELETE_BOTTOM_NODE";
const DELETE_MIDDLE_NODE = "DELETE_MIDDLE_NODE";
const UPDATE_NODE = "UPDATE_NODE";
const UPDATE_NODE_LIST = "UPDATE_NODE_LIST";
const ADD_NEW_BRANCH = "ADD_NEW_BRANCH";
const DELETE_BRANCH = "DELETE_BRANCH";
const UN_DO = "UN_DO";
const RE_DO = "RE_DO";
const CREATE_CHILD_NODE = "CREATE_CHILD_NODE";
const SET_GLOBAL_VARIABLES = "SET_GLOBAL_VARIABLES";
const SET_BOT_UPLOADING = "SET_BOT_UPLOADING";
const SET_SYSTEM_NODES = "SET_SYSTEM_NODES";
const SWITCH_TAB = "SWITCH_TAB";
const MOVE_NODE_BETWEEN = "MOVE_NODE_BETWEEN";
const SET_SOLUTION_ERRORS = "SET_SOLUTION_ERRORS";
const SET_SOLUTION_VERSION_INFO = "SET_SOLUTION_VERSION_INFO";
const SET_PERSISTENT_MENUS = "SET_PERSISTENT_MENUS";
const SET_GRAPH_HAS_CHANGES = "SET_GRAPH_HAS_CHANGES";
const CONNECT_DISCONNECTED_NODE = "CONNECT_DISCONNECTED_NODE";

export default {
  SET_SOLUTION_DATA,
  GET_SAGA_EXAMPLE,
  RESET_SOLUTION,
  IMPORT_SOLUTION,
  ADD_NODE_TO_BOTTOM,
  ADD_SIBLING_NODE,
  ADD_NODE_BETWEEN_PARENT_CHILD,
  DELETE_BOTTOM_NODE,
  DELETE_MIDDLE_NODE,
  UPDATE_NODE,
  UPDATE_NODE_LIST,
  ADD_NEW_BRANCH,
  DELETE_BRANCH,
  UN_DO,
  RE_DO,
  CREATE_CHILD_NODE,
  SET_GLOBAL_VARIABLES,
  SET_BOT_UPLOADING,
  SET_SYSTEM_NODES,
  SWITCH_TAB,
  MOVE_NODE_BETWEEN,
  SET_SOLUTION_ERRORS,
  SET_SOLUTION_VERSION_INFO,
  SET_PERSISTENT_MENUS,
  SET_GRAPH_HAS_CHANGES,
  CONNECT_DISCONNECTED_NODE,
};
