const config = {
  botManagerAPIurl: window.config.PS_BOT_MANAGER_FRONTEND_BOT_MANAGER_API_URL,
  s3bucketUrl: window.config.PS_BOT_MANAGER_FRONTEND_S3_BUCKET_URL,
  homepage: window.config.PS_BOT_MANAGER_FRONTEND_HOMEPAGE || "",
  n8nUrl: window.config.PS_N8N_BASE_URL || "",
  n8nEndpoint: window.config.PS_N8N_REST_URL || "",
  OktaAuthConfig: {
    url: window.config.PS_BOT_MANAGER_FRONTEND_OKTA_URL,
    issuer: window.config.PS_BOT_MANAGER_FRONTEND_OKTA_ISSUER,
    redirectUri: window.location.origin,
    clientId: window.config.PS_BOT_MANAGER_FRONTEND_OKTA_CLIENT_ID,
    scope: ["openid", "email", "profile", "pypestream_access"],
    tokenManager: {
      storage: "sessionStorage",
    },
  },
  supportedTrainingLanguages: ["english", "spanish"],
  supportedBotTypes: ["main", "survey"],
  amplitudeApiKey: window.config.PS_AMPLITUDE_API_KEY,
  fisherCatVercelLink:
    window.config.FISHERCAT_VERCEL_LINK || "https://fishercat.vercel.app",
  webserviceEnvConfig: window.config.PS_ENV_CONFIGS,
  launchDarklySdk: window.config.LAUNCHDARKLY_SDK,
  launchDarklyClientId: window.config.LAUNCHDARKLY_CLIENT_ID,
  mixpanelApiKey: window.config.MIXPANEL_PROJECT_ID,
  hotjarId: window.config.PS_ENV_CONFIGS["live"]?.HOTJAR_ID,
  hotjarLocalId: window.config.PS_ENV_CONFIGS["live"]?.HOTJAR_LOCAL_ID,
  readMeApiKey: window.config.README_API_KEY,
  pypestreamApiUrl:
    window.config.PYPESTREAM_API_URL ||
    "https://mocker-service.qa2.im.pype.tech",
};

export default config;
