/////// Colors ////////
// Background
export const backgroundBlack = "#000000";
export const backgroundGrey100 = "#f9f9f9";
export const backgroundGrey200 = "#f3f3f3";
export const backgroundGrey300 = "#dddddd";
export const backgroundGrey400 = "#bbbbbb";
export const backgroundGrey500 = "#8e8e8e";
export const backgroundGrey600 = "#7a6769";

// Interaction
export const interactionPrimary100 = "#cbdffb";
export const interactionPrimary200 = "#99bdf7";
export const interactionPrimary500 = "#0a3ab4";

// Error
export const otherError500 = "#e45f4f";

////// Typography ////////
export const mainFont = "Open Sans";
export const textPrimary = "#29302e";
export const textGray = "#7a6769";
export const colorPrimary = "#0a3ab4";
export const colorPrimary600 = "#062d9a";
export const colorPrimary700 = "#042181";
export const colorPlaceholder = "#716f6f";
