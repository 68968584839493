import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import botManagerAPI from "../bot-manager-api";
import { getFisherCatLink } from "../utils/functions";
import {
  getCustomer,
  getCustomersIfNeeded,
  initBot,
  getInterfaces,
  removeInterfaces,
  setNotification,
  setPreviewModalOpen,
} from "Manager/Store/actions";
import activityTracker from "../activityTracker";
import { OutlinedButton, Dropdown } from "@pypestream/ui-elements";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import "../Manager/Styles/Bot.scss";
import {
  setSolutionErrors,
  uploadSolutionGraph,
} from "Solution/store/solution/actions";
import { Emitter, EMITTER_EVENTS } from "Solution/services/emitter";
import { isEmpty } from "lodash";

class PreviewButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allPypes: [],
      streams: [],
      loadingInterfaces: false,
    };
    this.setPreviewData = this.setPreviewData.bind(this);
    this.isPublishDisabled = this.isPublishDisabled.bind(this);
    this.loadData = this.loadData.bind(this);
    this.getLastEnv = this.getLastEnv.bind(this);
    this.onPreviewFisherCatClick = this.onPreviewFisherCatClick.bind(this);
    this.uploadSolutionGraph = this.uploadSolutionGraph.bind(this);
  }

  async componentDidMount() {
    await this.loadData();
  }

  async componentDidUpdate() {
    await this.loadData();
  }

  async loadData() {
    if (!this.props.customer && this.props.customerId) {
      await this.props.getCustomer(this.props.customerId);
    }
    if (!this.props.botData && this.props.customerId && this.props.botId) {
      await this.props.initBot();
    }
  }

  isPublishDisabled() {
    const {
      botData: { canPublish },
      isTrainingNeeded,
      solutionInfo,
      processing,
    } = this.props;

    return !canPublish || isTrainingNeeded || solutionInfo.error || processing;
  }

  async uploadSolutionGraph(callback) {
    const { customerId, botId, flags, customer } = this.props;

    await this.props.uploadSolutionGraph(
      customerId,
      botId,
      true,
      (err, messages, errorType) => {
        if (err) {
          this.showSolutionErrors(errorType, messages);
        } else {
          setSolutionErrors([]);
          const botData = () => {
            if (isEmpty(customerId)) {
              return null;
            }

            return customer ? customer.botsData[botId] : null;
          };
          if (errorType === "open-preview") {
            if (flags?.pe22564) {
              const { latestVersion } = botData();
              const { id: latestVersionId } = latestVersion;
              if (latestVersionId) {
                botManagerAPI
                  .setPreviewWidget(latestVersionId)
                  .then(() => {
                    if (callback) {
                      callback();
                    }
                  })
                  .catch((error) => {
                    console.log(error.message);
                    callback();
                  });
              }
            } else {
              return;
            }
          }
          if (!flags?.pe22564 && callback) {
            callback(false);
          }
        }
      }
    );
  }

  async onPreviewFisherCatClick(callback) {
    const { customerId, botId, botData, customer, flags } = this.props;
    const env = this.getLastEnv();
    if (flags.pe23099) {
      this.uploadSolutionGraph(callback);
    }
    await botManagerAPI.getBotVersionPreview(
      customerId,
      botId,
      botData.latestVersion.version
    );
    const previewWidget = await botManagerAPI.getPreviewWidget(
      customer.gesId,
      env,
      {
        botName: botId,
        customerName: customerId,
      }
    );
    const widgetId = previewWidget.data[0].widget_ids[0];
    const streamId = previewWidget.data[0].stream_ids[0];
    const pypeId = previewWidget.data[0].pype_id;

    const link = getFisherCatLink({
      widgetId,
      customerId,
      botId,
      env: this.getLastEnv(),
      streamId,
      pypeId,
    });

    activityTracker.logEvent(
      activityTracker.eventTypeNames.OPEN_FISHERCAT_IN_NEW_TAB
    );
    window.open(link, "_blank");
  }

  getLastEnv() {
    const { botData } = this.props;
    let lastVersionIndex = 0;

    if (botData.hasDraft) {
      lastVersionIndex = botData.currentVersions.length - 2;
    } else {
      lastVersionIndex = botData.currentVersions.length - 1;
    }
    const lastVersion = botData.currentVersions[lastVersionIndex];

    return lastVersion?.envs?.includes("live") ? "live" : "sandbox";
  }

  showSolutionErrors(errorType, messages) {
    let notificationTitle = "";
    switch (errorType) {
      case "upload":
        notificationTitle = `<ul>${messages.reduce(
          (res, err, index) =>
            res +
            `<li><b>Error ${index + 1}:</b> ${
              err.err_msgs[0].error_description
            }</li>`,
          ""
        )}</ul>`;
        setSolutionErrors(messages);
        break;
      default:
        setSolutionErrors([]);
        notificationTitle = Array.isArray(messages)
          ? messages.join("<br>")
          : messages;
    }

    setNotification({
      openNotification: true,
      notificationDuration: 6000,
      notificationTitle,
      notificationType: "error",
    });
    Emitter.emit(EMITTER_EVENTS.SHOW_NODE_ERROR_PANEL);
  }

  setPreviewData(callback) {
    const { customerId, botId, flags } = this.props;
    if (flags.pe23099) {
      this.uploadSolutionGraph(callback);
    }
    this.props.setPreviewModalOpen(true, {
      variant: flags.pe20632 ? "responsive" : "temporary",
      customerId,
      botId,
    });
  }

  render() {
    const { customer, flags, botData, className, previewModalOpen } =
      this.props;

    const { isTrained } = botData || {};

    return flags["pe20552"] ? (
      <Dropdown
        anchorHorizontalOrigin="center"
        className={className}
        menu={[
          <Dropdown.Item
            key="admin"
            onClick={this.setPreviewData}
            disabled={previewModalOpen}
          >
            Conversation
          </Dropdown.Item>,
          <Dropdown.Item
            key="agent"
            onClick={() => this.onPreviewFisherCatClick()}
          >
            Page
          </Dropdown.Item>,
        ]}
      >
        <OutlinedButton
          disabled={!isTrained || !customer || this.isPublishDisabled()}
          onClick={this.setPreviewData}
        >
          Preview
        </OutlinedButton>
      </Dropdown>
    ) : (
      <OutlinedButton
        onClick={this.setPreviewData}
        className={className}
        disabled={!isTrained || !customer || this.isPublishDisabled()}
      >
        Preview
      </OutlinedButton>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let bots = [];
  const { customerId, botId } = ownProps;
  const customer = state.manager.customers[customerId];
  if (customer && customer.bots) {
    bots = customer.bots
      .sort()
      .map((bot) => ({ name: bot.substr(customer.id.length + 1) }));
  }
  return {
    customer,
    customerId,
    botId,
    bots,
    pypes: state.manager.pypes,
    streams: state.manager.streams,
    botData: customer ? customer.botsData[botId] : null,
    botUserSession: state.botUserSession,
    interfaces: state.manager.interfaces,
    isTrainingNeeded: state.manager.isTrainingNeeded,
    solutionInfo: state.manager.solutionInfo,
    previewModalOpen: state.manager.previewModalOpen,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getCustomer(customerId) {
    dispatch(getCustomersIfNeeded());
    return dispatch(getCustomer(customerId));
  },
  initBot(compilerVersion) {
    const { customerId, botId } = ownProps;
    return dispatch(initBot(customerId, botId, compilerVersion));
  },
  removeInterfaces: () => dispatch(removeInterfaces()),
  getInterfaces: (pypeId, env, interfacesAreEnabled) =>
    dispatch(getInterfaces(pypeId, env, interfacesAreEnabled)),
  setNotification: (data) => dispatch(setNotification(data)),
  setPreviewModalOpen: (open, config) =>
    dispatch(setPreviewModalOpen(open, config)),
  uploadSolutionGraph: (customerId, botId, isChecking, callback) =>
    dispatch(uploadSolutionGraph(customerId, botId, isChecking, callback)),
});

const PreviewButtonContainer = withLDConsumer()(
  connect(mapStateToProps, mapDispatchToProps)(PreviewButton)
);

PreviewButton.propTypes = {
  flags: PropTypes.object,
  botData: PropTypes.object,
  botUserSession: PropTypes.object,
  pypes: PropTypes.array,
  streams: PropTypes.object,
  bots: PropTypes.array,
  interfaces: PropTypes.array,
  isTrainingNeeded: PropTypes.bool,
  previewModalOpen: PropTypes.bool,
  customer: PropTypes.objectOf(PropTypes.any),
  customerId: PropTypes.string.isRequired,
  getCustomer: PropTypes.func.isRequired,
  botId: PropTypes.string.isRequired,
  initBot: PropTypes.func,
  setPreviewModalOpen: PropTypes.func,
  updatePypes: PropTypes.func,
  getPypes: PropTypes.func,
  updateBot: PropTypes.func,
  removeInterfaces: PropTypes.func,
  getInterfaces: PropTypes.func,
  setPypeLoading: PropTypes.func,
  setNotification: PropTypes.func,
  solutionInfo: PropTypes.object,
  className: PropTypes.string,
  processing: PropTypes.bool,
  uploadSolutionGraph: PropTypes.func,
};

PreviewButtonContainer.propTypes = {
  customerId: PropTypes.string.isRequired,
  botId: PropTypes.string.isRequired,
  className: PropTypes.string,
};

PreviewButtonContainer.defaultProps = {};

export default PreviewButtonContainer;
