import EventEmitter from "eventemitter3";

const eventEmitter = new EventEmitter();

const EMITTER_EVENTS = {
  NODE_DETAILS_TO_TOP: "NODE_DETAILS_TO_TOP",
  SCROLL_NODE_INTO_VIEW: "SCROLL_NODE_INTO_VIEW",
  SELECT_NODE_FROM_LINK_TO: "SELECT_NODE_FROM_LINK_TO",
  SHOW_NODE_ERROR_PANEL: "SHOW_NODE_ERROR_PANEL",
  LINKTO_HOVER_OVER_NODE: "LINKTO_HOVER_OVER_NODE",
  LINKTO_HOVER_OUT_NODE: "LINKTO_HOVER_OUT_NODE",
};

const Emitter = {
  on: (event, fn) => eventEmitter.on(event, fn),
  once: (event, fn) => eventEmitter.once(event, fn),
  off: (event, fn) => eventEmitter.off(event, fn),
  emit: (event, payload) => eventEmitter.emit(event, payload),
};

Object.freeze(Emitter);

export { Emitter, EMITTER_EVENTS };
