/* eslint-disable no-case-declarations */
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import uniq from "lodash/uniq";

import { TAB_LIST, NODE_SETTING, PREDEFINED_NODE_IDS } from "../../enum";

import ActionType from "./actionTypes";

import {
  searchParent,
  searchChildren,
  searchNode,
  getNodeInSolution,
} from "../../Utils/node-search";

const MAX_HISTORY_DEEP = 10;
const NodeType = NODE_SETTING.TYPE;
const EmbedTypes = NODE_SETTING.EMBED_TYPES;

// dummy data
const Data = {
  id: "n0",
  number: 0,
  type: NodeType.TOP_PARENT,
  title: "",
  parent: "",
  root: true,
  children: [
    {
      id: "n1",
      number: 1,
      type: "decision",
      title: "Welcome",
      messages: [],
      embeds: {},
      textfield: {},
      active: true,
      parent: "",
      children: [],
      root: true,
    },
  ],
};

export const DefaultNode = {
  title: "Node",
  type: NodeType.UNKNOWN,
  messages: [
    {
      type: "text",
      data: {
        text: "",
      },
    },
  ],
  embeds: {},
  textfield: {},
  children: [],
};

// ------------------------------------
// Normal functions
// ------------------------------------
const searchAndDoAction = (src, parent, actions, end) => {
  let flag = end;
  if (src.id === parent) {
    const fields = {
      [EmbedTypes.BUTTONS]: ["text", "images"],
      [EmbedTypes.LIST_PICKER]: ["text", "images"],
      [EmbedTypes.CAROUSEL]: ["standard"],
    };

    switch (actions.type) {
      case "add":
        if (src.children) {
          src.children = [...src.children, { ...actions.data }];
        } else {
          src.children = [{ ...actions.data }];
        }
        const linkData = {
          link: {
            number: actions.data.number,
            type: actions.data.type,
            label: actions.data.title,
          },
        };
        const embedValues = Object.keys(fields).reduce((res, field) => {
          let innerType = fields[field][0];

          if (window.featureFlags?.pe20398) {
            if (src.embeds && src.embeds[field]) {
              const embedContent = src.embeds[field];
              innerType = embedContent.type || innerType;
            }

            return {
              ...res,
              [field]: [...fields[field], "dynamic"].reduce(
                (sum, item) => {
                  const prevInnerContent = src.embeds?.[field] || {};
                  return {
                    ...sum,
                    [item]:
                      item === "dynamic"
                        ? {
                            ...(prevInnerContent.dynamic || {}),
                            linkTo: linkData.link,
                          }
                        : [
                            ...(prevInnerContent[item] || []).filter(
                              (c) => !!c?.link?.number
                            ),
                            linkData,
                          ],
                  };
                },
                { type: innerType }
              ),
            };
          }

          return {
            ...res,
            [field]: fields[field].reduce(
              (sum, item) => ({
                ...sum,
                [item]: [linkData],
              }),
              { type: innerType }
            ),
          };
        }, {});
        if (src.embeds && src.embeds.type) {
          switch (src.embeds.type) {
            case EmbedTypes.BUTTONS:
            case EmbedTypes.LIST_PICKER:
            case EmbedTypes.CAROUSEL:
            case EmbedTypes.NONE:
              src.embeds = {
                ...src.embeds,
                ...embedValues,
              };
              src.textfield = {
                ...(src.textfield || {}),
                type: EmbedTypes.NONE,
                None: {
                  enabled: false,
                  data: linkData,
                },
              };
              break;
            case EmbedTypes.DATE_PICKER:
            case EmbedTypes.TIME_PICKER:
              src.embeds = {
                ...src.embeds,
                [src.embeds.type]: {
                  link: {
                    number: actions.data.number,
                    type: actions.data.type,
                    label: actions.data.title,
                  },
                },
              };
              break;
            default:
              break;
          }
        } else {
          src.embeds = {
            type: EmbedTypes.UNKNOWN,
            ...embedValues,
          };
          src.textfield = {
            ...(src.textfield || {}),
            type: EmbedTypes.None,
            None: {
              enabled: false,
              data: linkData,
            },
          };
        }
        break;
      case "set-latest":
        src.clicked = true;
        break;
      case "update":
        src.title = actions.data.title;
        src.type = actions.data.type;
        src.embeds = actions.data.embeds;
        src.textfield = actions.data.textfield;
        src.messages = actions.data.messages;
        src.children = [...(actions.data.children || [])];
        src.parent = actions.data.parent;
        src.action = actions.data.action;
        src.isWorkflow = actions.data.isWorkflow;
        break;
      case "create-new-message-action":
        const { nodeInfo, posInfo } = actions.data;
        // update children property
        if (src.children) {
          src.children = [
            ...src.children.slice(0, posInfo),
            { ...nodeInfo },
            ...src.children.slice(posInfo),
          ];
        } else {
          src.children = [nodeInfo];
        }
        // update linkto property
        const newLink = {
          number: nodeInfo.number,
          type: nodeInfo.type,
          label: nodeInfo.title,
        };
        if (isEmpty(src.embeds)) {
          src.embeds = {
            type: EmbedTypes.UNKNOWN,
          };
        }
        switch (src.embeds.type) {
          case EmbedTypes.BUTTONS:
          case EmbedTypes.LIST_PICKER:
            if (!src.embeds[src.embeds.type]) {
              src.embeds[src.embeds.type] = {
                type: "text",
                text: [],
                images: [],
              };
            }
            const { text, images } = src.embeds[src.embeds.type];
            src.embeds[src.embeds.type].text = text
              ? [
                  ...text.slice(0, posInfo),
                  {
                    link: newLink,
                  },
                  ...text.slice(posInfo),
                ]
              : [
                  {
                    link: newLink,
                  },
                ];
            src.embeds[src.embeds.type].images = images
              ? [
                  ...images.slice(0, posInfo),
                  {
                    link: newLink,
                  },
                  ...images.slice(posInfo),
                ]
              : [
                  {
                    link: newLink,
                  },
                ];

            if (window.featureFlags?.pe19893) {
              src.embeds[src.embeds.type].dynamic = {
                ...(src.embeds[src.embeds.type].dynamic || {}),
                linkTo: newLink,
              };
            }
            break;
          case EmbedTypes.CAROUSEL:
            const { standard } = src.embeds[src.embeds.type];
            src.embeds[src.embeds.type].standard = standard
              ? [
                  ...standard.slice(0, posInfo),
                  {
                    link: newLink,
                  },
                  ...standard.slice(posInfo),
                ]
              : [
                  {
                    link: newLink,
                  },
                ];
            if (window.featureFlags?.pe19893) {
              src.embeds[src.embeds.type].dynamic = {
                ...(src.embeds[src.embeds.type].dynamic || {}),
                linkTo: newLink,
              };
            }
            break;
          default:
        }
        break;
      case "add-sibling":
        if (src.children) {
          const { pos, type } = actions.data.moveInfo;
          const insertPosition = getInsertPositionOfSiblingNode(src, pos, type);

          (src.children || []).splice(insertPosition, 0, {
            ...actions.data.nodeInfo,
          });
        }
        if (src.children && src.embeds && src.embeds.type) {
          const newLink = {
            number: actions.data.nodeInfo.number,
            type: actions.data.nodeInfo.type,
            label: actions.data.nodeInfo.title,
          };
          const { pos } = actions.data.moveInfo;
          Object.keys(fields).forEach((field) => {
            fields[field].forEach((item) => {
              if (!src.embeds[field]) {
                src.embeds[field] = {};
              }
              src.embeds[field]?.[item]?.splice(pos, 1, {
                label: newLink.label,
                link: newLink,
              });
            });

            if (
              window.featureFlags?.pe18449 &&
              src.embeds.type === EmbedTypes.NONE
            ) {
              if (isEmpty(src.textfield?.[EmbedTypes.NONE]?.data?.link)) {
                src.textfield = {
                  type: EmbedTypes.NONE,
                  [EmbedTypes.NONE]: {
                    ...src.textfield?.[EmbedTypes.NONE],
                    data: {
                      link: newLink,
                    },
                  },
                };
              }
            }
          });
        }
        break;
      case "add-node-between-parent-child":
        if (actions.data.moveInfo.type === "top") {
          const { moveInfo, nodeInfo } = actions.data;

          const childNodeIndex = src.children.findIndex(
            (item) => item.id === moveInfo.nodeId
          );
          const childLink = {
            number: src.children[childNodeIndex].number,
            type: src.children[childNodeIndex].type,
            label: src.children[childNodeIndex].title,
          };
          const newNodeEmbedValues = Object.keys(fields).reduce(
            (res, field) => ({
              ...res,
              [field]: fields[field].reduce(
                (sum, item) => ({
                  ...sum,
                  [item]: [{ link: childLink }],
                }),
                {
                  type: fields[field][0],
                  dynamic: window.featureFlags?.pe19893
                    ? { linkTo: childLink }
                    : {},
                }
              ),
            }),
            { type: "" }
          );
          const newNode = {
            ...nodeInfo,
            children: [
              {
                ...src.children[childNodeIndex],
                parent: nodeInfo.id,
              },
            ],
            parent: src.id,
            embeds: newNodeEmbedValues,
            textfield: {
              type: EmbedTypes.NONE,
              None: {
                enabled: false,
                data: { link: childLink },
              },
            },
          };
          src.children[childNodeIndex] = newNode;
          if (src?.embeds?.type) {
            const newLink = {
              number: actions.data.nodeInfo.number,
              type: actions.data.nodeInfo.type,
              label: actions.data.nodeInfo.title,
            };
            let linkIndex = -1;
            switch (src.embeds.type) {
              case EmbedTypes.BUTTONS:
              case EmbedTypes.LIST_PICKER:
              case EmbedTypes.CAROUSEL:
                fields[src.embeds.type].forEach((item) => {
                  linkIndex = (
                    src.embeds[src.embeds.type]?.[item] || []
                  ).findIndex(
                    (item) =>
                      `n${item.link.number}` === actions.data.moveInfo.nodeId
                  );
                  if (linkIndex >= 0) {
                    src.embeds[src.embeds.type][item][linkIndex].link = newLink;
                  }
                });
                if (window.featureFlags?.pe19893) {
                  src.embeds[src.embeds.type].dynamic = {
                    ...(src.embeds[src.embeds.type].dynamic || {}),
                    linkTo: newLink,
                  };
                }
                break;
              default:
                break;
            }
          }
        } else if (actions.data.moveInfo.bottom) {
          src.children = [
            {
              ...actions.data.nodeInfo,
              children: cloneDeep(
                src.children.map((item) => ({
                  ...item,
                  parent: actions.data.nodeInfo.id,
                }))
              ),
              parent: src.id,
              embeds: {
                type: EmbedTypes.UNKNOWN,
                Buttons: {
                  type: "text",
                  text: src.children.map((item) => ({
                    link: {
                      number: item.number,
                      type: item.type,
                      label: item.title,
                    },
                  })),
                  images: src.children.map((item) => ({
                    link: {
                      number: item.number,
                      type: item.type,
                      label: item.title,
                    },
                  })),
                },
              },
            },
          ];
          if (src?.embeds?.type) {
            const newLink = {
              number: actions.data.nodeInfo.number,
              type: actions.data.nodeInfo.type,
              label: actions.data.nodeInfo.title,
            };
            switch (src.embeds.type) {
              case EmbedTypes.BUTTONS:
              case EmbedTypes.LIST_PICKER:
                src.embeds[src.embeds.type].text = [
                  {
                    link: newLink,
                  },
                ];
                src.embeds[src.embeds.type].images = [
                  {
                    link: newLink,
                  },
                ];
                break;
              case EmbedTypes.CAROUSEL:
                src.embeds[EmbedTypes.CAROUSEL].standard = [
                  {
                    link: newLink,
                  },
                ];
                break;
              default:
                break;
            }
          }
          actions.data.nodeInfo = src.children[0];
        }
        break;
      case "add_branch":
        let topParentIndex;
        topParentIndex = src.children.findIndex(
          (item) => item.id === actions.data.topParent
        );
        if (topParentIndex >= 0) {
          src.children = [
            ...src.children.slice(0, topParentIndex + 1),
            actions.data.node,
            ...src.children.slice(topParentIndex + 1),
          ];
        } else {
          src.children = [...src.children, actions.data.node];
        }
        break;
      case "delete-branch":
        let branchIndex;
        let branchNode;
        branchNode = actions.data;
        branchIndex = src.children.findIndex(
          (item) => item.id === branchNode.id
        );
        if (branchIndex >= 0) {
          src.children.splice(branchIndex, 1);
        }
        if (
          src.type !== NodeType.ACTION &&
          src.embeds &&
          src.embeds.type &&
          src.embeds[src.embeds.type]
        ) {
          let temp = src.embeds[src.embeds.type];

          if (window.featureFlags?.pe19893) {
            Object.keys(fields).forEach((EmbedField) => {
              if (src.embeds?.[EmbedField]) {
                fields[EmbedField].forEach((tt) => {
                  src.embeds[EmbedField][tt] = (temp[tt] || []).filter(
                    (item) => item.link.number !== branchNode.number
                  );
                });
                src.embeds[EmbedField].dynamic = {
                  ...(src.embeds[EmbedField].dynamic || {}),
                  linkTo: {},
                };
              }
            });
          } else {
            switch (src.embeds.type) {
              case EmbedTypes.BUTTONS:
              case EmbedTypes.LIST_PICKER:
                src.embeds[src.embeds.type].text = (temp.text || []).filter(
                  (item) => item.link.number !== branchNode.number
                );
                src.embeds[src.embeds.type].images = (temp.images || []).filter(
                  (item) => item.link.number !== branchNode.number
                );
                break;
              case EmbedTypes.CAROUSEL:
                src.embeds[src.embeds.type].standard = (
                  temp.standard || []
                ).filter((item) => item.link.number !== branchNode.number);
                break;
              default:
                break;
            }
          }
        } else if (src.type === NodeType.ACTION) {
          const conditions = src?.action?.conditions || [];
          const index = conditions.findIndex(
            (con) => `n${con?.condition?.link?.number}` === branchNode.id
          );

          if (index >= 0) {
            src.action.conditions[index].condition.link = {};
          }
        }
        break;
      default:
    }
    return true;
  } else {
    switch (actions.type) {
      case "set-latest":
        src.clicked = false;
        break;
      default:
    }
  }

  if (!src.children || src.children.length === 0) {
    return flag;
  }

  src.children.forEach((item) => {
    flag = flag || searchAndDoAction(item, parent, actions, flag);
  });
  return flag;
};

const deleteBottomNodeLoop = (src, nodeId, end, matched) => {
  let flag = end;
  let target = matched;

  if (!src.children || src.children.length === 0) {
    return {
      flag,
      target,
    };
  }

  const index = src.children.findIndex((item) => item.id === nodeId);
  if (index >= 0) {
    src.children = [
      ...src.children.slice(0, index),
      ...src.children.slice(index + 1),
    ];
    // remove regarding embeds.
    if (src.type !== NodeType.ACTION && src.embeds) {
      const fields = {
        [EmbedTypes.BUTTONS]: ["text", "images"],
        [EmbedTypes.LIST_PICKER]: ["text", "images"],
        [EmbedTypes.CAROUSEL]: ["standard"],
      };
      switch (src.embeds.type) {
        case EmbedTypes.BUTTONS:
        case EmbedTypes.LIST_PICKER:
        case EmbedTypes.CAROUSEL:
          if (src.embeds[src.embeds.type]) {
            fields[src.embeds.type].forEach((item) => {
              const innerType = src.embeds[src.embeds.type][item];
              if (innerType) {
                src.embeds[src.embeds.type][item] = innerType?.filter(
                  (item) => item.link.number !== parseInt(nodeId.slice(1), 10)
                );
              }
            });
          }
          if (
            window.featureFlags?.pe19893 &&
            src.embeds[src.embeds.type] &&
            src.embeds[src.embeds.type].dynamic
          ) {
            [
              EmbedTypes.BUTTONS,
              EmbedTypes.LIST_PICKER,
              EmbedTypes.CAROUSEL,
            ].forEach((embedType) => {
              if (
                `n${src.embeds[embedType]?.dynamic?.linkTo?.number}` === nodeId
              ) {
                src.embeds[embedType].dynamic.linkTo = {};
              }
            });
          }
          break;
        default:
          break;
      }
    } else if (src.type === NodeType.ACTION) {
      const conditions = src?.action?.conditions || [];
      const index = conditions.findIndex(
        (con) => `n${con?.condition?.link?.number}` === nodeId
      );

      if (index >= 0) {
        src.action.conditions[index].condition.link = {};
      }
    }
    // in case parent node has None embed
    if (src.textfield?.type === EmbedTypes.NONE) {
      const { data: textfieldData } = src.textfield[EmbedTypes.NONE] || {};

      if (textfieldData) {
        textfieldData.link = {};
      }
    }
    flag = true;
    target = { ...src };
  }
  src.children.forEach((item) => {
    const { flag: newFlag, target: newTarget } = deleteBottomNodeLoop(
      item,
      nodeId,
      flag,
      target
    );
    flag = flag || newFlag;
    target = target || newTarget;
  });
  return { flag, target };
};

const deleteMiddleNodeLoop = (src, parent, id, end, counter, matched) => {
  let flag = end;
  let offsetOfCount = 0;
  let target = matched;

  const EmbedFields = {
    [EmbedTypes.BUTTONS]: ["text", "images", "dynamic"],
    [EmbedTypes.LIST_PICKER]: ["text", "images", "dynamic"],
    [EmbedTypes.CAROUSEL]: ["standard", "dynamic"],
  };

  if (!src.children || src.children.length === 0) {
    return { flag, offset: offsetOfCount, target };
  }

  if (src.id === parent) {
    // delete
    // check if delete is possible
    const deletingNodeIndex = src.children.findIndex((item) => item.id === id);
    const deletingNode = src.children[deletingNodeIndex];
    let possible = deletingNode && (deletingNode.children || []).length <= 1;

    if (possible) {
      src.children = [
        ...src.children.slice(0, deletingNodeIndex),
        ...(deletingNode.children || []).map((item) => ({
          ...item,
          parent: src.id,
        })),
        ...src.children.slice(deletingNodeIndex + 1),
      ];
      if (src.type !== NodeType.TOP_PARENT) {
        if (src.type === NodeType.ACTION) {
          const conditions = src?.action?.conditions || [];
          const index = conditions.findIndex(
            (con) => `n${con?.condition?.link?.number}` === id
          );

          if (index >= 0) {
            src.action.conditions[index].condition.link = {};
          }
        } else {
          let childType = "";
          const deletingEmbeds = deletingNode.embeds;
          const srcInnerEmbeds = src.embeds[src.embeds.type];
          const deletingEmbedsType = deletingEmbeds?.type || EmbedTypes.BUTTONS;
          const deletingInnerEmbeds = deletingEmbeds?.[deletingEmbedsType];

          if (!isEmpty(deletingInnerEmbeds)) {
            if (window.featureFlags?.pe19893) {
              if (
                [
                  EmbedTypes.BUTTONS,
                  EmbedTypes.LIST_PICKER,
                  EmbedTypes.CAROUSEL,
                ].includes(deletingEmbedsType) &&
                [
                  EmbedTypes.BUTTONS,
                  EmbedTypes.LIST_PICKER,
                  EmbedTypes.CAROUSEL,
                ].includes(deletingEmbedsType)
              ) {
                childType = deletingInnerEmbeds.type;
                (EmbedFields[src.embeds.type] || []).forEach((tt) => {
                  if (tt !== "dynamic") {
                    const innerIndex = (srcInnerEmbeds[tt] || []).findIndex(
                      (n) => n.link?.number === deletingNode.number
                    );
                    if (innerIndex >= 0) {
                      const childInfo =
                        childType === "dynamic"
                          ? [
                              {
                                link: deletingInnerEmbeds[childType].linkTo,
                              },
                            ]
                          : deletingInnerEmbeds[childType];
                      srcInnerEmbeds[tt] = [
                        ...srcInnerEmbeds[tt].slice(0, innerIndex),
                        ...childInfo,
                        ...srcInnerEmbeds[tt].slice(innerIndex + 1),
                      ];
                    }
                  } else if (window.featureFlags?.pe19893) {
                    const childInfo =
                      childType === "dynamic"
                        ? deletingInnerEmbeds[childType].linkTo
                        : deletingInnerEmbeds[childType][0].link;
                    srcInnerEmbeds.dynamic = {
                      ...(srcInnerEmbeds.dynamic || {}),
                      linkTo: childInfo,
                    };
                  }
                });
              }
            } else {
              switch (src.embeds.type) {
                case EmbedTypes.BUTTONS:
                case EmbedTypes.LIST_PICKER:
                  if (
                    [EmbedTypes.BUTTONS, EmbedTypes.LIST_PICKER].includes(
                      deletingEmbedsType
                    )
                  ) {
                    childType = deletingInnerEmbeds.type;
                    srcInnerEmbeds.text = [
                      ...srcInnerEmbeds.text.slice(0, deletingNodeIndex),
                      ...deletingInnerEmbeds[childType],
                      ...srcInnerEmbeds.text.slice(deletingNodeIndex + 1),
                    ];
                    srcInnerEmbeds.images = [
                      ...srcInnerEmbeds.images.slice(0, deletingNodeIndex),
                      ...deletingInnerEmbeds[childType],
                      ...srcInnerEmbeds.images.slice(deletingNodeIndex + 1),
                    ];
                  } else if (
                    [EmbedTypes.CAROUSEL].includes(deletingEmbedsType)
                  ) {
                    srcInnerEmbeds.text = [
                      ...srcInnerEmbeds.text.slice(0, deletingNodeIndex),
                      ...deletingInnerEmbeds.standard,
                      ...srcInnerEmbeds.text.slice(deletingNodeIndex + 1),
                    ];
                    srcInnerEmbeds.images = [
                      ...srcInnerEmbeds.images.slice(0, deletingNodeIndex),
                      ...deletingInnerEmbeds.standard,
                      ...srcInnerEmbeds.images.slice(deletingNodeIndex + 1),
                    ];
                  }
                  break;
                case EmbedTypes.CAROUSEL:
                  if (
                    [EmbedTypes.BUTTONS, EmbedTypes.LIST_PICKER].includes(
                      deletingEmbedsType
                    )
                  ) {
                    childType = deletingInnerEmbeds.type;
                    srcInnerEmbeds.standard = [
                      ...srcInnerEmbeds.standard.slice(0, deletingNodeIndex),
                      ...deletingInnerEmbeds[childType],
                      ...srcInnerEmbeds.standard.slice(deletingNodeIndex + 1),
                    ];
                  } else if (
                    [EmbedTypes.CAROUSEL].includes(deletingEmbedsType)
                  ) {
                    srcInnerEmbeds.standard = [
                      ...srcInnerEmbeds.standard.slice(0, deletingNodeIndex),
                      ...deletingInnerEmbeds.standard,
                      ...srcInnerEmbeds.standard.slice(deletingNodeIndex + 1),
                    ];
                  }
                  break;
                default:
                  break;
              }
            }
          }
          // in case src Node has None Embed.
          if (src.textfield?.type === EmbedTypes.NONE) {
            const { data: textfieldData } =
              src.textfield[EmbedTypes.NONE] || {};

            if (textfieldData) {
              textfieldData.link = {
                number: deletingNode.children[0]?.number ?? null,
              };
            }
          }
        }
      }
    } else {
      const orphanNodes = deletingNode.children.map((child, index) => {
        const number = counter + index + 1;
        deletingNode.children[index].parent = `n${number}`;
        if (window.featureFlags?.pe19699) {
          deletingNode.children[index].isDisconnected = true;
        }

        return {
          id: `n${number}`,
          number,
          type: NodeType.ORPHAN,
          title: "",
          parent: src.id,
          children: [child],
        };
      });

      // remove link to value
      let textType;
      let imagesType;
      let standardType;
      switch (src.embeds?.type) {
        case EmbedTypes.BUTTONS:
        case EmbedTypes.LIST_PICKER:
          textType = src.embeds[src.embeds.type].text;
          src.embeds[src.embeds.type].text = (textType || []).filter(
            (item) => item.link.number !== deletingNode.number
          );
          imagesType = src.embeds[src.embeds.type].images;
          src.embeds[src.embeds.type].images = (imagesType || []).filter(
            (item) => item.link.number !== deletingNode.number
          );
          break;
        case EmbedTypes.CAROUSEL:
          standardType = src.embeds[src.embeds.type].standard;
          src.embeds[src.embeds.type].standard = (standardType || []).filter(
            (item) => item.link.number !== deletingNode.number
          );
          break;
        default:
          break;
      }

      // in case src Node has None Embed.
      if (src.textfield?.type === EmbedTypes.NONE) {
        const { data: textfieldData } = src.textfield[EmbedTypes.NONE] || {};

        if (textfieldData) {
          textfieldData.link = {};
        }
      }

      src.children = [
        ...src.children.slice(0, deletingNodeIndex),
        ...orphanNodes,
        ...src.children.slice(deletingNodeIndex + 1),
      ];
      offsetOfCount = deletingNode.children.length;
    }

    flag = true;
    target = { ...src };
  }

  src.children.forEach((item) => {
    if (!flag) {
      const result = deleteMiddleNodeLoop(
        item,
        parent,
        id,
        flag,
        counter,
        target
      );
      flag = result.flag;
      offsetOfCount = result.offset;
      target = result.target;
    }
  });

  return { flag, offset: offsetOfCount, target };
};

const insertNodeToSolution = (src, parent, node) => {
  searchChildren(src, src.id, false, { active: false, clicked: false });
  searchAndDoAction(src, parent, { type: "add", data: node }, false);
  searchParent(src, parent, false, { active: true });
  return src;
};

const insertSiblingNodeToSolution = (src, nodeInfo, moveInfo) => {
  searchChildren(src, src.id, false, { active: false, clicked: false });
  searchAndDoAction(
    src,
    moveInfo.parent,
    { type: "add-sibling", data: { nodeInfo, moveInfo } },
    false
  );
  searchParent(src, nodeInfo.parent, false, { active: true });
  return src;
};

const addNodeBetweenParrentAndChildToSolution = (src, nodeInfo, moveInfo) => {
  searchChildren(src, src.id, false, { active: false, clicked: false });
  searchAndDoAction(
    src,
    moveInfo.parent,
    {
      type: "add-node-between-parent-child",
      data: { nodeInfo, moveInfo },
    },
    false
  );
  searchParent(src, moveInfo.parent, false, { active: true });
  searchChildren(src, moveInfo.hoverTarget, false, { active: true });
  return src;
};

const createChildNodeInSolution = (src, nodeInfo, posInfo) => {
  searchAndDoAction(
    src,
    nodeInfo.parent,
    { type: "create-new-message-action", data: { nodeInfo, posInfo } },
    false
  );
  searchChildren(src, src.id, false, { active: false, clicked: false });
  searchNode(src, [nodeInfo.parent], { clicked: true }, { clicked: false });
  searchParent(src, nodeInfo.parent, false, { active: true });
  searchChildren(src, nodeInfo.parent, false, { active: true });
  return src;
};

const deleteBottomNodeFromSolution = (src, id) => {
  const { target } = deleteBottomNodeLoop(src, id, false, null);
  return { src, target };
};

const deleteMiddleNodeFromSolution = (src, parent, id, counter) => {
  const result = deleteMiddleNodeLoop(src, parent, id, false, counter, null);
  return { src, counter: counter + result.offset, target: result.target };
};

const deleteBranchFromSolution = (src, node) => {
  searchAndDoAction(
    src,
    node.parent,
    { type: "delete-branch", data: node },
    false
  );
  return src;
};

const setActiveToLatestNode = (src, id) => {
  searchAndDoAction(src, id, { type: "set-latest" }, false);
  return src;
};
const updateNodeInsideSolution = (src, node) => {
  searchAndDoAction(
    src,
    node.id,
    { type: "update", data: node, isWorkflow: node.isWorkflow },
    false
  );
  return src;
};
const insertNewBranchToSolution = (src, node, hoverTarget) => {
  searchAndDoAction(
    src,
    "n0",
    { type: "add_branch", data: { node, topParent: hoverTarget } },
    false
  );
  return src;
};
const generateNextId = (counter, usedIdList) => {
  let newCounter = counter;

  let flag = true;

  while (flag) {
    newCounter++;
    flag = usedIdList.includes(newCounter);
  }

  return newCounter || 1;
};

const extractIds = (solution) => {
  let res = getIdLoop(solution);

  return [...PREDEFINED_NODE_IDS, ...res.sort((a, b) => (a - b ? 1 : -1))];
};

const getIdLoop = (node) => {
  let res = [node.number];

  if (node.children && node.children.length) {
    node.children.forEach((item) => {
      const ids = getIdLoop(item);

      res = [...res, ...ids];
    });
  }

  return res;
};

const getInsertPositionOfSiblingNode = (solution, pos, type) => {
  if (type !== "embed") {
    // pos means the position in children array
    return pos + 1;
  }

  if (solution.embeds?.type !== EmbedTypes.NONE) {
    return (solution.children || []).length;
  }

  return pos;
};

const resetUniqueNumbers = (node, counter, usedIdList, isSetParent) => {
  let number = generateNextId(counter, usedIdList);
  node.number = number;
  node.id = `n${number}`;

  if (isSetParent) {
    node.parent = `n${counter}`;
  }

  let resUsedIdList = [...usedIdList, number];
  let changedIdMap = {};

  (node.children || [])
    .filter((child) => child.type !== NodeType.PLUS)
    .forEach((child) => {
      const oldChildId = child.id;
      const res = resetUniqueNumbers(child, number, resUsedIdList, true);
      changedIdMap[oldChildId] = child.number;
      number = res[0];
      resUsedIdList = [...res[1]];
    });

  if (!isEmpty(changedIdMap)) {
    // Keep Connection on embeds and textfield If children ids are changed.
    const fields = {
      [EmbedTypes.BUTTONS]: ["text", "images"],
      [EmbedTypes.LIST_PICKER]: ["text", "images"],
      [EmbedTypes.CAROUSEL]: ["standard"],
    };

    Object.keys(fields).forEach((embedType) => {
      fields[embedType].forEach((innerType) => {
        const innerTypeData = node?.embeds?.[embedType]?.[innerType];

        (innerTypeData || [])
          .filter((element) => !!element?.link?.number)
          .forEach((element) => {
            const elementId = `n${element.link.number}`;
            if (changedIdMap[elementId] !== undefined) {
              element.link.number = changedIdMap[elementId];
            }
          });
      });
    });

    const textfieldData = node?.textfield?.[EmbedTypes.NONE]?.data;
    const linkNodeNumber = textfieldData?.link?.number;

    if (linkNodeNumber && changedIdMap[`n${linkNodeNumber}`] !== undefined) {
      textfieldData.link.number = changedIdMap[`n${linkNodeNumber}`];
    }
  }

  return [number, resUsedIdList];
};

// ------------------------------------
// Initial State
// ------------------------------------
export const getInitialState = () => ({
  [TAB_LIST.Dialog]: {
    past: [],
    present: {
      solution: Data,
      latestChangedNode: Data.id,
      counter: 1,
      defaultNameCounter: 1,
      usedIdList: [...PREDEFINED_NODE_IDS],
    },
    future: [],
  },
  [TAB_LIST.System]: {
    past: [],
    present: {
      solution: Data,
      latestChangedNode: Data.id,
      counter: 1,
      defaultNameCounter: 1,
      usedIdList: [...PREDEFINED_NODE_IDS],
    },
    future: [],
  },
  globalVariables: [],
  isBotUploading: false,
  systemNodes: [],
  selectedTab: TAB_LIST.Dialog,
  solutionErrors: [],
  customerId: "",
  botId: "",
  persistentMenus: [],
  hasChanges: false,
});

// ------------------------------------
// Reducers
// ------------------------------------
export const reducers = {
  [ActionType.SET_SOLUTION_DATA]: (state, action) => {
    return Object.assign({}, state, {
      ...action.data,
    });
  },
  [ActionType.RESET_SOLUTION]: (state) => {
    return Object.assign({}, state, {
      solution: Data,
      nodeId: Data.id,
      counter: 1,
      usedIdList: extractIds(Data),
      defaultNameCounter: 1,
    });
  },
  [ActionType.ADD_NODE_TO_BOTTOM]: (state, action) => {
    const { solution, counter, usedIdList } = state;
    const number = generateNextId(counter, usedIdList);
    const newNode = {
      ...action.data.node,
      number,
      id: `n${number}`,
      parent: action.data.parent,
    };
    const res = insertNodeToSolution(solution, action.data.parent, newNode);
    if (action.data.callback) {
      action.data.callback(newNode);
    }

    return Object.assign({}, state, {
      solution: cloneDeep(res),
      nodeId: `n${number}`,
      counter: number,
      usedIdList: [...usedIdList, number],
    });
  },
  [ActionType.ADD_SIBLING_NODE]: (state, action) => {
    const { solution, counter, usedIdList } = state;

    if (window.featureFlags?.pe18304) {
      const { nodeInfo, moveInfo } = action.data;
      const newNode = { ...nodeInfo };
      const [number, resUsedIdList] = resetUniqueNumbers(
        newNode,
        counter,
        uniq(usedIdList)
      );
      const res = insertSiblingNodeToSolution(solution, newNode, moveInfo);

      if (action.data.callback) {
        action.data.callback(getNodeInSolution(res, newNode.id));
      }

      return Object.assign({}, state, {
        solution: cloneDeep(res),
        nodeId: `n${number}`,
        counter: number,
        usedIdList: [...resUsedIdList],
      });
    }

    const number = generateNextId(counter, usedIdList);
    const newNode = {
      ...action.data.nodeInfo,
      number,
      id: `n${number}`,
    };
    const res = insertSiblingNodeToSolution(
      solution,
      newNode,
      action.data.moveInfo
    );

    if (action.data.callback) {
      action.data.callback(getNodeInSolution(res, newNode.id));
    }

    return Object.assign({}, state, {
      solution: cloneDeep(res),
      nodeId: `n${newNode.number}`,
      counter: number,
      usedIdList: [...usedIdList, number],
    });
  },
  [ActionType.ADD_NODE_BETWEEN_PARENT_CHILD]: (state, action) => {
    const { solution, counter, usedIdList } = state;

    const number = generateNextId(counter, usedIdList);
    const newNode = {
      ...action.data.nodeInfo,
      number,
      id: `n${number}`,
    };
    const res = addNodeBetweenParrentAndChildToSolution(
      solution,
      newNode,
      action.data.moveInfo
    );

    if (action.data.callback) {
      action.data.callback(getNodeInSolution(res, newNode.id));
    }

    return Object.assign({}, state, {
      solution: cloneDeep(res),
      nodeId: `n${newNode.number}`,
      counter: number,
      usedIdList: [...usedIdList, number],
    });
  },
  [ActionType.DELETE_BOTTOM_NODE]: (state, action) => {
    const { solution } = state;

    const { src: res, target } = deleteBottomNodeFromSolution(
      solution,
      action.data.id
    );

    if (action.data.callback) {
      action.data.callback(target);
    }

    return Object.assign({}, state, {
      solution: cloneDeep(res),
      nodeId: action.data.id,
    });
  },
  [ActionType.DELETE_MIDDLE_NODE]: (state, action) => {
    const { solution, counter } = state;

    const res = deleteMiddleNodeFromSolution(
      solution,
      action.data.parent,
      action.data.id,
      counter
    );

    if (action.data.callback) {
      action.data.callback(res.target);
    }

    return Object.assign({}, state, {
      solution: cloneDeep(res.src),
      nodeId: action.data.id,
      counter: res.counter,
    });
  },
  [ActionType.UPDATE_NODE]: (state, action) => {
    const { solution, counter, usedIdList } = state;

    const newNode = action.data.node;
    let newCounter = counter;
    if (newNode.messages) {
      newNode.messages = newNode.messages.map((bubble) => {
        let newBubble = bubble;
        if (!bubble?.data?.id) {
          newCounter = generateNextId(newCounter, usedIdList);
          usedIdList.push(newCounter);
          newBubble = {
            ...bubble,
            data: {
              ...bubble.data,
              id: newCounter,
            },
          };
        }
        return newBubble;
      });
    }
    const res = updateNodeInsideSolution(solution, newNode);

    return Object.assign({}, state, {
      solution: cloneDeep(res),
      nodeId: action.data.node.id,
      counter: newCounter,
      usedIdList: [...usedIdList],
    });
  },
  [ActionType.UPDATE_NODE_LIST]: (state, action) => {
    const { solution, counter, usedIdList } = state;

    const { nodes } = action.data;
    let newNodeList = nodes;
    let newCounter = counter;
    let sum;
    newNodeList.forEach((node) => {
      if (node.messages) {
        node.messages = node.messages.map((bubble) => {
          let newBubble = bubble;
          if (!bubble.data.id) {
            newCounter = generateNextId(newCounter, usedIdList);
            usedIdList.push(newCounter);
            newBubble = {
              ...bubble,
              data: {
                ...bubble.data,
                id: newCounter,
              },
            };
          }
          return newBubble;
        });
      }
      sum = updateNodeInsideSolution(solution, node);
    });

    return Object.assign({}, state, {
      solution: cloneDeep(sum),
      nodeId: action.data.nodes[2].id,
      counter: newCounter,
      usedIdList: [...usedIdList],
    });
  },
  [ActionType.ADD_NEW_BRANCH]: (state, action) => {
    const { solution, counter, usedIdList } = state;
    const { nodeInfo, branchInfo } = action.data;

    const number = generateNextId(counter, usedIdList);
    const newNode = {
      ...nodeInfo,
      number,
      id: `n${number}`,
    };
    const res = insertNewBranchToSolution(
      solution,
      newNode,
      branchInfo?.moveInfo?.nodeId ?? ""
    );
    if (action.data.callback) {
      action.data.callback(newNode);
    }

    return Object.assign({}, state, {
      solution: cloneDeep(res),
      nodeId: `n${newNode.number}`,
      counter: number,
      usedIdList: [...usedIdList, number],
    });
  },
  [ActionType.DELETE_BRANCH]: (state, action) => {
    const { solution } = state;
    const res = deleteBranchFromSolution(solution, action.data.node);

    if (action.data.callback && typeof action.data.callback === "function") {
      action.data.callback(action.data.node.id);
    }

    return Object.assign({}, state, {
      solution: cloneDeep(res),
      nodeId: action.data.node.id,
    });
  },
  [ActionType.CREATE_CHILD_NODE]: (state, action) => {
    const { solution, counter, defaultNameCounter, usedIdList } = state;

    const messageNo = defaultNameCounter === undefined ? 1 : defaultNameCounter;
    const number = generateNextId(counter, usedIdList);
    const newNode = {
      ...action.data.node,
      title: action.data.node?.title ?? `New Message ${messageNo}`,
      number,
      id: `n${number}`,
    };
    const res = createChildNodeInSolution(solution, newNode, action.data.pos);

    if (action.data.callback) {
      action.data.callback(newNode);
    }

    return Object.assign({}, state, {
      solution: cloneDeep(res),
      nodeId: `n${newNode.number}`,
      counter: number,
      usedIdList: [...usedIdList, number],
      defaultNameCounter: messageNo + 1,
    });
  },
  [ActionType.CONNECT_DISCONNECTED_NODE]: (state, action) => {
    const { solution } = state;

    const res = insertSiblingNodeToSolution(solution, action.data.node, {
      parent: action.data.parent,
      type: "embed",
      pos: action.data.pos,
    });

    if (action.data.callback) {
      action.data.callback(getNodeInSolution(res, action.data.parent));
    }

    return Object.assign({}, state, {
      solution: cloneDeep(res),
    });
  },
};

export default (state = getInitialState(), action) => {
  const { selectedTab } = state;
  const { past, present, future } = state[selectedTab] || {};
  let newState = {};

  switch (action.type) {
    case ActionType.UN_DO:
      if (present.latestChangedNode) {
        setActiveToLatestNode(
          past[past.length - 1].solution,
          present.latestChangedNode
        );
      }

      newState = {
        ...state,
        [selectedTab]: {
          past: past.slice(0, past.length - 1),
          present: past[past.length - 1],
          future: [present, ...future],
        },
        hasChanges: true,
      };

      return Object.assign({}, state, newState);
    case ActionType.RE_DO:
      if (present.latestChangedNode) {
        setActiveToLatestNode(future[0].solution, future[0].latestChangedNode);
      }

      newState = {
        ...state,
        [selectedTab]: {
          past: [...past, present],
          present: future[0],
          future: future.slice(1),
        },
        hasChanges: true,
      };

      return Object.assign({}, state, {
        ...newState,
      });
    case ActionType.SET_GLOBAL_VARIABLES:
      return Object.assign({}, state, {
        ...state,
        globalVariables: cloneDeep(action.data.data),
      });
    case ActionType.SET_BOT_UPLOADING:
      return Object.assign({}, state, {
        ...state,
        isBotUploading: action.data.flag,
      });
    case ActionType.SET_SYSTEM_NODES:
      return Object.assign({}, state, {
        ...state,
        systemNodes: action.data.nodes,
      });
    case ActionType.SWITCH_TAB:
      return Object.assign({}, state, {
        selectedTab: action.data || TAB_LIST.Dialog,
      });
    case ActionType.IMPORT_SOLUTION:
      let solutionData = {};

      [TAB_LIST.Dialog, TAB_LIST.System].forEach((tab) => {
        const sln = action.data.data[tab];
        const usedIdList = extractIds(sln);
        solutionData[tab] = {
          past: [],
          present: {
            solution: sln,
            nodeId: sln.id,
            counter: 1,
            usedIdList,
            defaultNameCounter: 1,
          },
          future: [],
        };
      });
      return Object.assign({}, state, {
        ...solutionData,
      });
    case ActionType.SET_SOLUTION_ERRORS:
      return Object.assign({}, state, {
        solutionErrors: action.data,
      });
    case ActionType.SET_SOLUTION_VERSION_INFO:
      return Object.assign({}, state, {
        customerId: action.data.customerId,
        botId: action.data.botId,
      });
    case ActionType.SET_PERSISTENT_MENUS:
      return Object.assign({}, state, {
        persistentMenus: [...action.data],
        hasChanges: true,
      });
    case ActionType.SET_GRAPH_HAS_CHANGES:
      return Object.assign({}, state, {
        hasChanges: action.data,
      });
    default:
      if (Object.keys(reducers).includes(action.type)) {
        const newPresent = reducers[action.type](
          {
            solution: cloneDeep(present.solution),
            counter: present.counter,
            usedIdList: [
              ...state[TAB_LIST.Dialog].present.usedIdList,
              ...state[TAB_LIST.System].present.usedIdList,
            ],
            defaultNameCounter: present.defaultNameCounter,
          },
          action
        );
        if (present.solution === newPresent.solution) {
          return state;
        }

        newState = {
          ...state,
          [selectedTab]: {
            past:
              action.type === ActionType.RESET_SOLUTION
                ? []
                : past.length < MAX_HISTORY_DEEP
                ? [...past, present]
                : [...past.slice(1), present],
            present: {
              solution: newPresent.solution,
              latestChangedNode: newPresent.nodeId,
              counter: newPresent.counter,
              usedIdList: newPresent.usedIdList,
              defaultNameCounter: newPresent.defaultNameCounter,
            },
            future: [],
          },
          hasChanges: true,
        };

        return Object.assign({}, state, { ...newState });
      } else if (action.type === ActionType.MOVE_NODE_BETWEEN) {
        const { from, to, nodeInfo, callback } = action.data;

        // 1. Remove node from `from`.
        const deleteActionType = nodeInfo.bottom
          ? ActionType.DELETE_BOTTOM_NODE
          : ActionType.DELETE_MIDDLE_NODE;
        const newFromPresent = reducers[deleteActionType](
          {
            solution: cloneDeep(state[from].present.solution),
            counter: state[from].present.counter,
            usedIdList: state[from].present.usedIdList,
            defaultNameCounter: state[from].present.defaultNameCounter,
          },
          {
            data: {
              id: nodeInfo.id,
              parent: nodeInfo.parent,
            },
          }
        );
        // 2. Add node as new branchh to `to`
        const newToPresent = reducers[ActionType.ADD_NEW_BRANCH](
          {
            solution: cloneDeep(state[to].present.solution),
            counter: state[to].present.counter,
            usedIdList: state[to].present.usedIdList,
            defaultNameCounter: state[to].present.defaultNameCounter,
          },
          {
            data: {
              nodeInfo: {
                ...nodeInfo,
                children: [],
                parent: "",
              },
            },
          }
        );
        if (callback) {
          callback("");
        }
        return Object.assign({}, state, {
          ...state,
          [from]: {
            past:
              state[from].past.length < MAX_HISTORY_DEEP
                ? [...state[from].past, state[from].present]
                : [...state[from].past.slice(1), state[from].present],
            present: {
              solution: newFromPresent.solution,
              latestChangedNode: newFromPresent.nodeId,
              counter: newFromPresent.counter,
              usedIdList: newFromPresent.usedIdList,
              defaultNameCounter: newFromPresent.defaultNameCounter,
            },
            future: [],
          },
          [to]: {
            past:
              state[to].past.length < MAX_HISTORY_DEEP
                ? [...state[to].past, state[to].present]
                : [...state[to].past.slice(1), state[to].present],
            present: {
              solution: newToPresent.solution,
              latestChangedNode: newToPresent.nodeId,
              counter: newToPresent.counter,
              usedIdList: newToPresent.usedIdList,
              defaultNameCounter: newToPresent.defaultNameCounter,
            },
            future: [],
          },
        });
      }

      return state;
  }
};
