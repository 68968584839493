import React from "react";
import {
  doesUserHasRole,
  PYPE_MANAGER_ROLES,
  PYPE_AGENT_ROLES,
} from "@pypestream/bot-user-session";
import { Redirect } from "react-router-dom";

class AllowedPath extends React.Component {
  render() {
    const hasPype =
      doesUserHasRole(PYPE_MANAGER_ROLES) || doesUserHasRole(PYPE_AGENT_ROLES);

    if (hasPype) {
      return <Redirect to="/pype-manager" />;
    } else {
      return <Redirect to="/login" />;
    }
  }
}

export default AllowedPath;
