import React, { useEffect, createContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  doesUserHasRole,
  BOT_MANAGER_ROLES,
} from "@pypestream/bot-user-session";
import { createTheme } from "@material-ui/core/styles";
import { setNotification } from "Manager/Store/actions";
import { MuiThemeProvider } from "@material-ui/core";
import { withLDConsumer, useLDClient } from "launchdarkly-react-client-sdk";
import { Notification } from "@pypestream/ui-elements";
import activityTracker from "./activityTracker";
import RouterComponent from "./RouterComponent";
import { downloadTxtFile } from "utils/qaDump";

export const QaDumpContext = createContext();

function App(props) {
  const {
    isAuthenticated,
    authenticationError,
    openNotification,
    notificationTitle,
    notificationType,
    notificationDuration,
    notificationIcon,
    user,
    setNotification,
  } = props;

  const ldClient = useLDClient();

  // qaDump data
  const qaDumpData = {
    config: () => console.log("Window configuration", window.config),
    env: () => console.log("Environment variables", process.env),
    flags: () => console.log("Feature flags", props.flags),
    downloadData: () =>
      downloadTxtFile(window.config, process.env, props.flags),
  };

  // setting qaDumpData globally
  window.qaDumpData = qaDumpData;

  useEffect(() => {
    const userAttributes = user && user.optimizelyUserAttributes;

    if (isAuthenticated && doesUserHasRole(BOT_MANAGER_ROLES)) {
      ldClient.identify({
        key: user.user_id, // Should always be present if user is authenticated
        email: userAttributes.email,
        custom: {
          //prettier-ignore
          "domain": window.location.href,
          //previously - ...userAttributes, was present here. Leaving comment just in case. Can be removed with next succesful merge.
        },
      });

      activityTracker.mixpanel.identify(userAttributes.email);

      const keysWithNoEmail = Object.keys(userAttributes).filter(
        (key) => key !== "email"
      );
      const userAttributesWithNoEmail = keysWithNoEmail.reduce((acc, key) => {
        acc[key] = userAttributes[key];
        return acc;
      }, {});

      if (activityTracker.mixpanel.people) {
        activityTracker.mixpanel.people.set({
          ...userAttributesWithNoEmail,
          $email: userAttributes.email,
          $name: user.name,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isAuthenticated]);

  const style = {};
  if (!isAuthenticated) {
    style.backgroundColor = "#f8f8f8";
  }

  const theme = createTheme({
    props: {
      // Name of the component
      MuiButtonBase: {
        // The properties to apply
        disableRipple: true, // No more ripple, on the whole application!
      },
    },
  });

  return (
    <QaDumpContext.Provider id="qaDump" value={qaDumpData}>
      <MuiThemeProvider theme={theme}>
        <RouterComponent
          authenticationError={authenticationError}
          isAuthenticated={isAuthenticated}
          initialEntries={props.initialEntries}
        />
        <Notification
          open={openNotification}
          title={
            <div dangerouslySetInnerHTML={{ __html: notificationTitle }} />
          }
          type={notificationType}
          autoHideDuration={notificationDuration}
          icon={notificationIcon}
          onClose={() => setNotification({ openNotification: false })}
        />
      </MuiThemeProvider>
    </QaDumpContext.Provider>
  );
}

App.propTypes = {
  isAuthenticated: PropTypes.bool,
  user: PropTypes.object,
  authenticationError: PropTypes.bool,
  openNotification: PropTypes.bool,
  notificationTitle: PropTypes.string,
  notificationType: PropTypes.string,
  notificationIcon: PropTypes.string,
  notificationDuration: PropTypes.number,
  getEnvsFromBack: PropTypes.func,
  setNotification: PropTypes.func,
  initialEntries: PropTypes.any,
  flags: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    isAuthenticated: state.botUserSession.isAuthenticated,
    authenticationError: state.botUserSession.error !== null,
    user: state.botUserSession.user,
    openNotification: state.manager.openNotification,
    notificationDuration: state.manager.notificationDuration,
    notificationTitle: state.manager.notificationTitle,
    notificationType: state.manager.notificationType,
    notificationIcon: state.manager.notificationIcon,
  };
}

const mapDispatchToProps = (dispatch) => ({
  setNotification: (data) => dispatch(setNotification(data)),
});

export default withLDConsumer()(
  connect(mapStateToProps, mapDispatchToProps)(App)
);
