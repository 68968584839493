import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { userLogout } from "@pypestream/bot-user-session";
import { connect } from "react-redux";

const Logout = ({ onUserLogout }) => {
  useEffect(() => {
    onUserLogout();
  }, [onUserLogout]);

  return <Redirect to="/login" />;
};

Logout.propTypes = {
  onUserLogout: PropTypes.func,
};

Logout.defaultProps = {
  onUserLogout: () => {},
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  onUserLogout: () => {
    dispatch(userLogout());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
