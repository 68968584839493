/* eslint-disable no-useless-escape */
import config from "../config";

/*
ExecuteWorkflow script changelog:

v1: Pre-Authentication
Version used before N8N API required Authentication. Will not work with N8N API if auth is required.

v2: N8N API Auth
Authenticates to N8N API using credentials in n8n_config.py

v3:  Fix Nested JSON Output
PE-21480 : Deeply-nested JSON is returned to solution exactly as expected

v4: Fixes for javascript code, binary output, and error handling
PE-21663 : Execute Workflow removes all curly braces from javascript code inside workflow
PE-21736: N8N binary output should be excluded from main workflow output, but accessible in a local variable.
PE-20898: Enable app workflow errors to display in Preview

v5: Fixes for Global Variable input interpolation
PE-21768: ExecuteWorkflow variable interpolation drops everything after last replaced value

v6: Dynamic Interactions + bug fixes
PE-21960 : ExecuteWorkflow: generate dynamic embed strings according to pypestream_metadata
PE-21958 : N8N: Return a useful error message if  workflow contains node types which have been removed
PE-21955 : N8N workflow input: exception raised if global variable value is a number
PE-21954 : N8N workflow output: single element arrays are being converted into scalars
PE-21953 : Undefined variables in N8N workflow are being replaced by empty string
PE-21943 : Merge ExecuteWorkflow code from Preview 1 into bot-framework
PE-21910 : Modify ExecuteWorkflow to change output based on metadata
PE-21834 : Modify ExecuteWorkflow Script to output variables for embed controls
 */

export const executeWorkflowDefaultScript_v5_FixGlobalVars = `
# -*- coding: utf-8 -*-
r'''
    ______  ______  _____________________  _________    __  ___
   / __ \\ \\/ / __ \\/ ____/ ___/_  __/ __ \\/ ____/   |  /  |/  /
  / /_/ /\\  / /_/ / __/  \\__ \\ / / / /_/ / __/ / /| | / /|_/ /
 / ____/ / / ____/ /___ ___/ // / / _, _/ /___/ ___ |/ /  / /
/_/     /_/_/   /_____//____//_/ /_/ |_/_____/_/  |_/_/  /_/
action node script

'''
import re
import sys
import time
import traceback
from pathlib import Path
from urllib.error import HTTPError

import requests
import json
from collections import abc, namedtuple

N8N_BASE_URL = "${config.n8nEndpoint}"

CURRENT_DIR = Path(__file__).parent

# Bot Manager only allows .py or .csv extensions
N8N_CONFIG_FILE = "n8n_config.py"
N8N_CONFIG_PATH = CURRENT_DIR / N8N_CONFIG_FILE


class N8NWorkflowTimeoutError(Exception):
    """ Raised when a workflow execution times out """
    pass


class N8NDataTransformError(Exception):
    """ Raised when there is an error transforming data from N8N encoded format to standard nested JSON """
    pass


class N8NNodeExecutionError(Exception):
    """ Raised when N8N reports errors executing a node """
    def __init__(self, message, n8n_stacktrace, **kwargs):
        Exception.__init__(self, message,**kwargs)
        self.n8n_stacktrace = n8n_stacktrace


class N8NRunWorkflowError(Exception):
    """ Raised when there is an error calling the N8N run workflow endpoint """
    pass


class N8NProtocolError(Exception):
    """ Raised when there is an error parsing the data returned by N8N """
    pass

class Interpolate:
    @staticmethod
    def replace(original: str, variables: dict):
        """
        Non-recursive String Interpolation:
        Each placeholder in original like '{VARNAME}' is replaced with the value of variable VARNAME.
        If the value also contains a placeholder, it is NOT replaced. This is done to avoid surprises.
        Whitespace is allowed between the brackets and the variable name.
        :rtype:
        """
        pattern = r"(\\{\\s*([A-Za-z0-9_]+)\\s*\\})"

        processed = []

        # for each regex match in orig_str
        prev_end = 0
        for match in re.finditer(pattern, original):
            varname = match.group(2)
            if varname in variables:
                match_start = match.start(1)

                # Add everything since the last match to the processed list
                processed.append(original[prev_end:match_start])

                # Add current value to processed list
                processed.append(variables[varname])

                # Track the last match endpoint
                prev_end = match.end(1)

        # if processed list is not empty, join it
        if len(processed) > 0:
            processed.append(original[prev_end:])
            return "".join(processed)
        else:
            # There were no matches, return the original string
            return original


def replace_val(to_update, key, log, **kwargs)->None:
    global_vars = kwargs.get("gv")

    curr_value = to_update[key]
    if not isinstance(curr_value, str):
        # Don't try to replace values that aren't strings
        return

    to_update[key] = Interpolate.replace(curr_value, global_vars)


def update_nested_json(nested, function, log, **kwargs):
    """
    Iterates through all keys in a nested json and
    performs the requested function on the eventual
    bottom level key and the dict it belongs to.
    """
    for key, value in list(nested.items()):
        if isinstance(value, abc.Mapping):
            update_nested_json(value, function, log, **kwargs)
        elif isinstance(value, list):
            for item in value:
                if isinstance(item, abc.Mapping):
                    update_nested_json(item, function, log, **kwargs)
        else:
            function(nested, key, log, **kwargs)
    log(f"After updating nodes with GV: {nested}")


def inject_global_vars_into_workflow(workflow_data, global_vars, log):
    if "data" in workflow_data and "nodes" in workflow_data["data"]:
        for node in workflow_data["data"]["nodes"]:
            update_nested_json(node, replace_val, log, gv=global_vars)

    workflow_data["data"].pop("createdAt")
    workflow_data["data"].pop("updatedAt")
    workflow_data["workflowData"] = workflow_data["data"]
    workflow_data.pop("data")

    return workflow_data


def fine_tune_payload(payload, result_data):
    if isinstance(payload,dict):
        for data in payload:
            if isinstance(payload[data],str):
                try:
                    payload[data] = result_data[int(payload[data])]
                except ValueError:
                    pass


class N8NConfig:
    # Config Keys
    WORKFLOW_BASE_URL = "n8n_workflow_base_url"
    BASE_URL = "n8n_base_url"
    EMAIL = "n8n_login_email"
    PASSWORD = "n8n_login_password"
    TIMEOUT = "n8n_timeout_seconds"
    RETRY_SLEEP = "n8n_retry_sleep_seconds"

    ALL_KEYS = [EMAIL, PASSWORD, BASE_URL, WORKFLOW_BASE_URL, TIMEOUT, RETRY_SLEEP]

    DEFAULTS = {
        TIMEOUT: 300,
        BASE_URL: N8N_BASE_URL,
        RETRY_SLEEP: 5
    }

    def __init__(self, config_file_path, overrides, log):
        if config_file_path is None:
            config_file_path = N8N_CONFIG_PATH

        self.config_file_path = Path(config_file_path)

        self._validate_n8n_config(overrides, "overrides parameter")
        self.overrides = overrides
        self._log = log

        self.load_config(config_file_path)

    def load_config(self, config_file_path):
        # Read defaults from config file
        defaults = self._read_config_file()
        self._validate_n8n_config(defaults, config_file_path)
        self.defaults_from_file = defaults

        # Merge with overrides
        config_dict = self._merge_defaults_and_overrides()

        self.login_email = config_dict.get(self.EMAIL)
        self.login_password = config_dict.get(self.PASSWORD)

        self.timeout_seconds = self._get(config_dict, self.TIMEOUT)
        self.retry_sleep_seconds = self._get(config_dict, self.RETRY_SLEEP)

        # Set URLs
        self.n8n_base_url = self._get(config_dict, self.BASE_URL)

        # Default is just the base URL + /workflows
        wf_url_default = self.join_url(self.n8n_base_url, "workflows")
        self.workflow_base_url = self._get(config_dict, self.WORKFLOW_BASE_URL, wf_url_default)

    def _get(self, config_dict, key, default=None):
        """ Get a value from the config dict and return the default if it's not present """
        config_val = config_dict.get(key)
        if config_val is None:
            if default is None:
                default = self.DEFAULTS.get(key)
            config_val = default
        return config_val

    @staticmethod
    def join_url(a, b):
        return a.rstrip('/') + '/' + b.lstrip('/')

    def _merge_defaults_and_overrides(self):
        """
        Merge default settings from config file with override values
        Return the resulting config as a dict
        """
        # Avoid overriding a value with None
        overrides = {key: value for key, value in self.overrides.items() if value is not None}

        merged_config = {**self.defaults_from_file, **overrides}
        return merged_config

    def _validate_n8n_config(self, config, source_description):
        for key in config:
            if key not in N8NConfig.ALL_KEYS:
                raise Exception(f"Invalid key in {source_description}: {key}")

    @staticmethod
    def pop_n8n_config_settings(dict_to_modify):
        """ Remove n8n config settings from dict_to_modify and return those settings in a dict """
        return {key: dict_to_modify.pop(key, None) for key in N8NConfig.ALL_KEYS}

    def _read_config_file(self):
        n8n_config = {}
        if self.config_file_path.exists():
            with open(self.config_file_path) as f:
                n8n_config = json.load(f)
        else:
            self._log(f"Warning: config file not found: {self.config_file_path}")
        return n8n_config


class N8NExecutionData:
    @staticmethod
    def is_integer(s):
        try:
            int(s)
            return True
        except ValueError:
            return False

    @classmethod
    def _expand(cls, array, idx):
        root = array[idx]
        if isinstance(root, dict):
            result = {key: cls._expand_string(array, val) for key, val in root.items()}
        elif isinstance(root, list):
            if len(root) == 1:
                result = cls._expand_string(array, root[0])
            else:
                result = [cls._expand_string(array, val) for val in root]
        else:
            result = root
        return result

    @classmethod
    def _expand_string(cls, array, val):
        """ If val is a string representing integer N, then it is a pointer to index N. Recursively expand
        the value at that index and replace the value with the result. Otherwise return the val unchanged.
        """
        if isinstance(val, str) and cls.is_integer(val):
            val = cls._expand(array, int(val))
        return val

    @classmethod
    def decode_execution_data(cls, array):
        try:
            return cls._expand(array, 0)
        except Exception as e:
            raise N8NDataTransformError("There was an error decoding the workflow result data.") from e

    @staticmethod
    def get_node_rundata(decoded_output, node_name):
        run_data = decoded_output['resultData']['runData']
        node_data = run_data.get(node_name)
        return node_data

    @classmethod
    def get_last_executed_node_data(cls, decoded_output):
        # logic adapted from N8N function getDataLastExecutedNodeData in
        # https://github.com/pypestream/coywolf-n8n/blob/fd2b09b7351387960b3092f61c7821e78469a363/packages/cli/src/WorkflowHelpers.ts
        last_executed_node = decoded_output['resultData'].get('lastNodeExecuted')

        if not last_executed_node:
            return None

        last_node_data = cls.get_node_rundata(decoded_output, last_executed_node)
        if not last_node_data:
            return None

        if isinstance(last_node_data, list):
            return last_node_data[-1]
        else:
            return last_node_data

    @classmethod
    def get_last_executed_results(cls, decoded_output:dict):
        """
        Get the results of the last executed node.
        :param decoded_output:  Result of calling decode_execution_data on the 'data' key in the workflow output
        :return: (main_data, error) tuple. main_data is the 'main' key of the last executed node. error is the 'error' key.
        """
        last_node_data = cls.get_last_executed_node_data(decoded_output)

        cls.maybe_raise_n8n_node_error(last_node_data)

        main_data = last_node_data.get('data', {}).get('main')

        return main_data

    @classmethod
    def maybe_raise_n8n_node_error(cls, last_node_data):
        error = last_node_data.get('error')

        # Example error object:
        # {
        # 'message': "Unexpected token 'return' [Line 122]",
        # 'stack': "/Users/ryan/src/pypestream/coywolf-n8n/packages/nodes-base/dist/nodes/Function:4\\n  return channel;\\n  ^^^^^^\\n\\nSyntaxError: Unexpected token 'return'\\n    at makeNiceSyntaxError (/Users/ryan/src/pypestream/coywolf-n8n/node_modules/vm2/lib/transformer.js:41:16)\\n    at transformer (/Users/ryan/src/pypestream/coywolf-n8n/node_modules/vm2/lib/transformer.js:80:8)\\n    at NodeVM.run (/Users/ryan/src/pypestream/coywolf-n8n/node_modules/vm2/lib/nodevm.js:413:17)\\n    at Object.execute (/Users/ryan/src/pypestream/coywolf-n8n/packages/nodes-base/nodes/Function/Function.node.ts:122:22)\\n    at Workflow.runNode (/Users/ryan/src/pypestream/coywolf-n8n/packages/workflow/src/Workflow.ts:1173:28)\\n    at /Users/ryan/src/pypestream/coywolf-n8n/packages/core/src/WorkflowExecute.ts:814:41\\n    at processTicksAndRejections (node:internal/process/task_queues:96:5)"
        # }

        if error:
            message = error.get('message')
            n8n_stacktrace = error.get('stack')
            # message = f"{summary}\\n Stack Trace:\\n{stack}"
            raise N8NNodeExecutionError(message, n8n_stacktrace)

    @classmethod
    def remove_json_wrappers(cls, json_wrapped_data):
        """
        # N8N wraps each row of data with a json object. Remove the wrapper.
        :param json_wrapped_data:
        :type json_wrapped_data:
        :return:
        :rtype:
        """
        if not json_wrapped_data:
            return None
        if isinstance(json_wrapped_data, list):
            # [{'json': DATA1 }, {'json': DATA2 }] -> [DATA1, DATA2]
            return [cls.remove_json_wrappers(val) for val in json_wrapped_data]
        if isinstance(json_wrapped_data, dict):
            # {'json': DATA } -> DATA
            if 'json' in json_wrapped_data:
                return json_wrapped_data['json']
            return json_wrapped_data

    @staticmethod
    def parse_items(wrapped_data):
        """
        Parse items from N8N into a list of N8NDataItem objects
        :param wrapped_data:
        :type wrapped_data:
        :return:
        :rtype:
        """
        results = []
        if not wrapped_data:
            return None

        if isinstance(wrapped_data, dict):
            # Convert to list to iterate over below
            wrapped_data = [wrapped_data]

        if isinstance(wrapped_data, list):
            for item in wrapped_data:
                results.append(N8NDataItem(item))
        else:
            raise N8NDataTransformError(f"Unexpected data type: {type(wrapped_data)}")

        return results

class N8NDataItem:
    def __init__(self, item):
        self.paired_item = N8NDataItem.unbox(item, 'pairedItem')

        # json key exists in every item or N8N throws an error.
        self.json_data = N8NDataItem.unbox(item, 'json')
        self.binary_data = None

        if "binary" in item:
            self.binary_data = N8NDataItem.unbox(item, 'binary')

    @classmethod
    def unbox(cls, item:dict, key:str='json'):
        """
        If item is a dict mapping key to boxed_value, return boxed_value.
        Otherwise return the item itself.
        If boxed_value is an empty dict, replace it with None
        Examples:
        unbox({'json': {DATA} }) -> {DATA}
        unbox({DATA}) -> {DATA}
        :param item: A dict boxing a value
        :param key: Specify the key of the boxed value. Default is 'json'.
        :return: The boxed value
        """
        if key in item:
            val = item[key]
            # Convert empty dict to None
            return val if val else None
        return item



class N8NExecutor:
    def __init__(self, n8n_config, log):
        self.n8n_config = n8n_config
        self._log = log
        self._session = None

    def session(self):
        # Lazy init of session
        if not self._session:
            self.init_session()
        return self._session

    def init_session(self):
        """ Create session with n8n server and login if credentials are found"""

        # The session manages authentication cookies automatically
        self._session = requests.Session()
        email = self.n8n_config.login_email
        password = self.n8n_config.login_password
        n8n_base_url = self.n8n_config.n8n_base_url

        if email and password:
            self._log(f"logging in as user {email}")
            login_response = self.login(email, password, n8n_base_url)
            self._login_data = login_response.json()
        else:
            self._log("Skipping login because credentials were not supplied")

    def login(self, email, password, n8n_base_url=None):
        if not n8n_base_url:
            n8n_base_url = N8N_BASE_URL

        url = f"{n8n_base_url}/login"
        params = {
            "email": email,
            "password": password
        }
        response = self.session().post(url, json=params)
        response.raise_for_status()
        return response

    def get_workflow_object(self, workflow_name):
        """
        Gets workflow object by workflow name
        """
        url = f"{self.n8n_config.workflow_base_url}/name/{workflow_name}"
        response = self.session().get(url)
        response.raise_for_status()
        workflow_obj = response.json()

        return workflow_obj

    def create_workflow(self, workflow_obj):
        url = self.n8n_config.workflow_base_url
        response = self.session().post(url, json=workflow_obj)
        if 400 <= response.status_code < 500:
            body_json = None
            try:
                body_json = response.json()
            except:
                pass
            error_msg = f'{response.status_code} Client Error: {response.reason} for url: {url}\\n{body_json}'
            raise HTTPError(error_msg, response=response)

        response.raise_for_status()
        return response

    def update_workflow(self, workflow_obj, workflow_id):
        if not workflow_id:
            workflow_id = workflow_obj.get("id")
        url = f"{self.n8n_config.workflow_base_url}/{workflow_id}"
        response = self.session().patch(url, json=workflow_obj)
        response.raise_for_status()
        return response

    def create_or_update_workflow(self, workflow_obj):
        workflow_name = workflow_obj['name']
        wf_prev = self.get_workflow_object(workflow_name)
        if wf_prev:
            workflow_id = wf_prev["data"].get("id")
            self._log(f"Workflow {workflow_name} already exists with id={workflow_id}. Updating.")
            self.update_workflow(workflow_obj, workflow_id)
        else:
            self._log(f"Creating workflow {workflow_name}")
            return self.create_workflow(workflow_obj)

    def create_workflow_if_not_exists(self, workflow_obj):
        workflow_name = workflow_obj['name']
        workflow_obj = self.get_workflow_object(workflow_name)
        if workflow_obj:
            self._log(f"Workflow {workflow_name} already exists")
            return workflow_obj
        else:
            self._log(f"Creating workflow {workflow_name}")
            return self.create_workflow(workflow_obj)

    def start_workflow(self, workflow_data):
        """
        Executes workflow by passing workflow object
        and returns the status and execution id
        """
        url = f"{self.n8n_config.workflow_base_url}/run"
        response = self.session().post(url, json=workflow_data)
        if response.status_code != 200:
            raise N8NRunWorkflowError(f"Error starting workflow. HTTP Status={response.status_code}.\\nResponse: {response.text}")

        try:
            execution_id = response.json()["data"]["executionId"]
        except Exception as e:
            raise N8NProtocolError(f"Error getting executionId from reponse: {response.text}") from e

        return execution_id

    def should_stop_polling(self, response, validation_func=None):
        # Stops polling if the response is success and the
        # provided validation function returns True
        if response is None:
            # Continue polling
            return False

        if response.status_code == 200:
            if validation_func:
                return validation_func(response)
            return True

        # Non-success response: stop polling
        return True

    def is_execution_finished(self, response):
        # Validation function which returns True only if
        # workflow execution is finished
        if response is None:
            return False
        json_dict = response.json()

        is_finished = False
        data = json_dict.get('data')
        if data and data.get('stoppedAt') or data.get('finished'):
            is_finished = True
        return is_finished

    def get_execution_result(self, execution_id, log):
        url = f"{self.n8n_config.n8n_base_url}/executions/{execution_id}"

        # Throws TimeoutException
        response = self.poll_with_timeout(
            target=lambda: self.session().get(url),
            check_success=lambda r: self.should_stop_polling(r, self.is_execution_finished),
            timeout_seconds=self.n8n_config.timeout_seconds,
            retry_sleep_seconds=self.n8n_config.retry_sleep_seconds,
            log=log
        )

        response.raise_for_status()

        try:
            result_data = json.loads(response.json()["data"]["data"])
            # log(f"Result data from executions/{execution_id}: {result_data}")
        except Exception as e:
            raise N8NProtocolError(f"Error reading execution result data: {response.text}") from e

        return result_data

    def poll_with_timeout(self, target, check_success, timeout_seconds, retry_sleep_seconds, log):
        ignored_exceptions = ()
        loop_count = 0
        start = time.time()
        while True:
            elapsed = time.time() - start
            if elapsed > timeout_seconds:
                raise N8NWorkflowTimeoutError(f"Timed out waiting for execution result after {elapsed} seconds and {loop_count} attempts")
            loop_count += 1

            try:
                result = target()
                if check_success(result):
                    break
            except ignored_exceptions:
                formatted = traceback.format_exc()
                log(f"Caught Ignored Exception in get_execution_result: {formatted}")

            # Sleep before next loop
            log(f"Sleeping {retry_sleep_seconds} seconds before retry {loop_count}")
            time.sleep(retry_sleep_seconds)

        return result

    def make_result_data_old(self, result_data):
        return_payload = {}
        for elements in result_data:
            if "json" in elements and isinstance(elements, dict):
                for element in elements:
                    if isinstance(elements[element], str):
                        return_payload.update(result_data[int(elements[element])])
                    else:
                        return_payload[element] = element[elements]
        fine_tune_payload(return_payload, result_data)
        for data in return_payload:
            fine_tune_payload(return_payload[data], result_data)
        return return_payload


class ExecuteWorkflow:
    def execute(self, log, payload=None, bot_or_context=None):
        try:
            self._log = log

            log(f"Input payload: {payload}")
            workflow_name = payload["jsonFileName"]

            # Remove n8n settings from payload to avoid leaking sensitive info
            n8n_config_path = payload.pop("n8n_config_path", None)
            log(f"payload after removing n8n config: {payload}")

            if not n8n_config_path:
                n8n_config_path = N8N_CONFIG_PATH
            n8n_overrides = N8NConfig.pop_n8n_config_settings(payload)

            n8n_config = N8NConfig(n8n_config_path, n8n_overrides, log)
            self.n8n = N8NExecutor(n8n_config, log)

            workflow_obj = self.n8n.get_workflow_object(workflow_name)

            # extract global vars and create workflow_data
            workflow_data = inject_global_vars_into_workflow(workflow_obj, payload, log)

            output_payload = self.execute_n8n(workflow_data, workflow_name, log)

            # Add input payload to output payload. This is useful for troubleshooting
            # why global vars are not being injected into the workflow.
            output_payload["input_payload"] = payload

            return self.finalize_payload(output_payload)

        except Exception:
            # Top-Level Exception Handler: This should be mostly programming errors in Action Node script.
            # If we throw exception from here, it will be caught by bot-framework and end user will see a
            # generic message like: A call to action node ExecuteWorkflow failed for {Customer}.{Solution}.{Version}
            # So instead, we show them a slighly more helpful message with the Exception type and message.

            # Avoid including Stack Trace here until we consider any security implications.
            return self.exception_payload(workflow_name, log, include_stack_trace=False)

    def execute_n8n(self, workflow_data, workflow_name, log):
        """
        Executes workflow in n8n and format the result for output
        :param workflow_data:
        :param workflow_name:
        :param log:
        :return:
        """

        # pass updated workflow object and execute workflow
        workflow_data["mode"] = "cli"

        try:
            # raises N8NRunWorkflowError and N8NProtocolError
            execution_id = self.n8n.start_workflow(workflow_data)

            # raises N8NWorkflowTimeoutError and N8NProtocolError
            result_data = self.n8n.get_execution_result(execution_id, log)

            # raises N8NDataTransformError
            execution_result_full = N8NExecutionData.decode_execution_data(result_data)

            # raises N8NNodeExecutionError
            last_result = N8NExecutionData.get_last_executed_results(execution_result_full)

            log(f"Successful execution of workflow: {workflow_name}")

            return_payload = self.success_payload(last_result)

        except Exception:
            return_payload = self.exception_payload(workflow_name, log, include_stack_trace=True)
        return return_payload

    def finalize_payload(self, payload:dict):
        result = self.copy_uppercase_keys_to_lowercase(payload)

        # Add list of variables to payload
        result["all_variables"] = [str(key) for key in result.keys()]

        return result

    def copy_uppercase_keys_to_lowercase(self, payload):
        result = {}
        for key in payload:
            result[key] = payload[key]
            if key.isupper():
                result[key.lower()] = payload[key]
        return result

    def success_payload(self, last_result):
        payload = {}

        # In case user wants to make this input to another workflow
        payload["workflow_output_n8n_format"] = last_result

        data_items = N8NExecutionData.parse_items(last_result)

        # Partition data_items into json and binary subsets
        json_items, binary_items  = self.partition_json_and_binary(data_items)

        # Return binary items to Solution unaltered.
        # TODO: Is there a better way to handle this?
        payload["ignored_binary_items"] = [x.binary_data for x in binary_items]

        # Return list of json items to Solution unaltered.
        # TODO: Is there anything useful we can do with the paired_item?
        wf_output = [x.json_data for x in json_items]

        if len(wf_output) == 0:
            # Return None instead of empty list
            wf_output = None
        elif len(wf_output) == 1:
            # Convert single-item list to single item
            wf_output = wf_output[0]

        payload["WORKFLOW_OUTPUT"] = wf_output
        payload["SUCCESS"] = "true"

        return payload

    def partition_json_and_binary(self, data_items):
        binary_items = []
        json_items = []
        for data_item in data_items:
            if data_item.binary_data:
                binary_items.append(data_item)
            else:
                json_items.append(data_item)
        return json_items, binary_items

    def exception_payload(self, workflow_name, log, include_stack_trace=False, show_troubleshooting=True):
        # get exception tuple
        exc_type, exc_value, exc_traceback = sys.exc_info()

        error_message = f"{exc_type.__name__}: {exc_value}"

        output = "Error executing workflow."

        if show_troubleshooting:
            output += "\\nTo troubleshoot, see variables error_message and error_detail"

        formatted = traceback.format_exc()
        payload = {
            "SUCCESS": "false",
            "WORKFLOW_OUTPUT": output,
            "error_message": error_message,
        }

        if include_stack_trace:
            if isinstance(exc_value, N8NNodeExecutionError):
                # For this type of error, the important stack trace is reported by N8N
                payload["error_detail"] = exc_value.n8n_stacktrace
            else:
                # TODO: Consider security implications of this.
                payload["error_detail"] = formatted

        # payload["WORKFLOW_OUTPUT"] += ("\\n" + payload["ERROR_DETAIL"])

        log(f"Workflow {workflow_name} Exception: \\n{formatted}")
        return payload


`;

// This version contains the code from executeWorkflowDefaultScript_Auth above, and
// also fixes the output from the last executed node (PE-21480, tested in coywolf-bot-framework under PE-21247)
export const executeWorkflowDefaultScript_Auth_OutputFix = `
# -*- coding: utf-8 -*-
r'''
    ______  ______  _____________________  _________    __  ___
   / __ \\ \\/ / __ \\/ ____/ ___/_  __/ __ \\/ ____/   |  /  |/  /
  / /_/ /\\  / /_/ / __/  \\__ \\ / / / /_/ / __/ / /| | / /|_/ /
 / ____/ / / ____/ /___ ___/ // / / _, _/ /___/ ___ |/ /  / /
/_/     /_/_/   /_____//____//_/ /_/ |_/_____/_/  |_/_/  /_/
action node script

'''
import traceback
from pathlib import Path
from urllib.error import HTTPError

import polling2
import requests
import json
from collections import abc

N8N_BASE_URL = "${config.n8nEndpoint}"

CURRENT_DIR = Path(__file__).parent

# Bot Manager only allows .py or .csv extensions
N8N_CONFIG_FILE = "n8n_config.py"
N8N_CONFIG_PATH = CURRENT_DIR / N8N_CONFIG_FILE

def replace_val(value, key, log, **kwargs)->None:
    gv = kwargs.get("gv")
    for var in gv:
        if gv[var] and isinstance(value[key], str):
            value[key] = value[key].replace(var, gv[var]).replace('{','').replace('}','')


def update_nested_json(nested, function, log, **kwargs):
    """
    Iterates through all keys in a nested json and
    performs the requested function on the eventual
    bottom level key and the dict it belongs to.
    """
    for key, value in list(nested.items()):
        if isinstance(value, abc.Mapping):
            update_nested_json(value, function, log, **kwargs)
        elif isinstance(value, list):
            for item in value:
                if isinstance(item, abc.Mapping):
                    update_nested_json(item, function, log, **kwargs)
        else:
            function(nested, key, log, **kwargs)
    log(f"After updating nodes with GV: {nested}")


def inject_global_vars_into_workflow(workflow_data, global_vars, log):
    if "data" in workflow_data and "nodes" in workflow_data["data"]:
        for node in workflow_data["data"]["nodes"]:
            update_nested_json(node, replace_val, log, gv=global_vars)

    workflow_data["data"].pop("createdAt")
    workflow_data["data"].pop("updatedAt")
    workflow_data["workflowData"] = workflow_data["data"]
    workflow_data.pop("data")

    return workflow_data


def fine_tune_payload(payload, result_data):
    if isinstance(payload,dict):
        for data in payload:
            if isinstance(payload[data],str):
                try:
                    payload[data] = result_data[int(payload[data])]
                except ValueError:
                    pass


class N8NConfig:
    # Config Keys
    WORKFLOW_BASE_URL = "n8n_workflow_base_url"
    BASE_URL = "n8n_base_url"
    EMAIL = "n8n_login_email"
    PASSWORD = "n8n_login_password"
    TIMEOUT = "n8n_timeout_seconds"

    ALL_KEYS = [EMAIL, PASSWORD, BASE_URL, WORKFLOW_BASE_URL, TIMEOUT]

    DEFAULTS = {
        TIMEOUT: 300,
        BASE_URL: N8N_BASE_URL,
    }

    def __init__(self, config_file_path, overrides, log):
        if config_file_path is None:
            config_file_path = N8N_CONFIG_PATH

        self.config_file_path = Path(config_file_path)

        self._validate_n8n_config(overrides, "overrides parameter")
        self.overrides = overrides
        self._log = log

        self.load_config(config_file_path)

    def load_config(self, config_file_path):
        # Read defaults from config file
        defaults = self._read_config_file()
        self._validate_n8n_config(defaults, config_file_path)
        self.defaults_from_file = defaults

        # Merge with overrides
        config_dict = self._merge_defaults_and_overrides()

        self.login_email = config_dict.get(self.EMAIL)
        self.login_password = config_dict.get(self.PASSWORD)

        self.timeout_seconds = self._get(config_dict, self.TIMEOUT)

        # Set URLs
        self.n8n_base_url = self._get(config_dict, self.BASE_URL)

        # Default is just the base URL + /workflows
        wf_url_default = self.join_url(self.n8n_base_url, "workflows")
        self.workflow_base_url = self._get(config_dict, self.WORKFLOW_BASE_URL, wf_url_default)

    def _get(self, config_dict, key, default=None):
        """ Get a value from the config dict and return the default if it's not present """
        config_val = config_dict.get(key)
        if config_val is None:
            if default is None:
                default = self.DEFAULTS.get(key)
            config_val = default
        return config_val

    @staticmethod
    def join_url(a, b):
        return a.rstrip('/') + '/' + b.lstrip('/')

    def _merge_defaults_and_overrides(self):
        """
        Merge default settings from config file with override values
        Return the resulting config as a dict
        """
        # Avoid overriding a value with None
        overrides = {key: value for key, value in self.overrides.items() if value is not None}

        merged_config = {**self.defaults_from_file, **overrides}
        return merged_config

    def _validate_n8n_config(self, config, source_description):
        for key in config:
            if key not in N8NConfig.ALL_KEYS:
                raise Exception(f"Invalid key in {source_description}: {key}")

    @staticmethod
    def pop_n8n_config_settings(dict_to_modify):
        """ Remove n8n config settings from dict_to_modify and return those settings in a dict """
        return {key: dict_to_modify.pop(key, None) for key in N8NConfig.ALL_KEYS}

    def _read_config_file(self):
        n8n_config = {}
        if self.config_file_path.exists():
            with open(self.config_file_path) as f:
                n8n_config = json.load(f)
        else:
            self._log(f"Warning: config file not found: {self.config_file_path}")
        return n8n_config


class N8NExecutionData:
    @staticmethod
    def is_integer(s):
        try:
            int(s)
            return True
        except ValueError:
            return False

    @classmethod
    def _expand(cls, array, idx):
        root = array[idx]
        if isinstance(root, dict):
            result = {key: cls._expand_string(array, val) for key, val in root.items()}
        elif isinstance(root, list):
            if len(root) == 1:
                result = cls._expand_string(array, root[0])
            else:
                result = [cls._expand_string(array, val) for val in root]
        else:
            result = root
        return result

    @classmethod
    def _expand_string(cls, array, val):
        """ If val is a string representing integer N, then it is a pointer to index N. Recursively expand
        the value at that index and replace the value with the result. Otherwise return the val unchanged.
        """
        if isinstance(val, str) and cls.is_integer(val):
            val = cls._expand(array, int(val))
        return val

    @classmethod
    def decode_execution_data(cls, array):
        return cls._expand(array, 0)

    @staticmethod
    def get_node_json_data(decoded_output, node_name):
        """ Returns the json data for the node with the given name. """
        run_data = decoded_output['resultData']['runData']
        node_data = run_data[node_name]
        node_json_data = node_data['data']['main']['json']
        return node_json_data

    @classmethod
    def get_last_executed_json_data(cls, decoded_output):
        last_executed_node = decoded_output['resultData']['lastNodeExecuted']
        return cls.get_node_json_data(decoded_output, last_executed_node)


class N8NExecutor:
    def __init__(self, n8n_config, log):
        self.n8n_config = n8n_config
        self._log = log
        self._session = None

    def session(self):
        # Lazy init of session
        if not self._session:
            self.init_session()
        return self._session

    def init_session(self):
        """ Create session with n8n server and login if credentials are found"""

        # The session manages authentication cookies automatically
        self._session = requests.Session()
        email = self.n8n_config.login_email
        password = self.n8n_config.login_password
        n8n_base_url = self.n8n_config.n8n_base_url

        if email and password:
            self._log(f"logging in as user {email}")
            login_response = self.login(email, password, n8n_base_url)
            self._login_data = login_response.json()
        else:
            self._log("Skipping login because credentials were not supplied")

    def login(self, email, password, n8n_base_url=None):
        if not n8n_base_url:
            n8n_base_url = N8N_BASE_URL

        url = f"{n8n_base_url}/login"
        params = {
            "email": email,
            "password": password
        }
        response = self.session().post(url, json=params)
        response.raise_for_status()
        return response

    def get_workflow_object(self, workflow_name):
        """
        Gets workflow object by workflow name
        """
        url = f"{self.n8n_config.workflow_base_url}/name/{workflow_name}"
        response = self.session().get(url)
        response.raise_for_status()
        workflow_obj = response.json()

        return workflow_obj

    def create_workflow(self, workflow_obj):
        url = self.n8n_config.workflow_base_url
        response = self.session().post(url, json=workflow_obj)
        if 400 <= response.status_code < 500:
            body_json = None
            try:
                body_json = response.json()
            except:
                pass
            error_msg = f'{response.status_code} Client Error: {response.reason} for url: {url}\\n{body_json}'
            raise HTTPError(error_msg, response=response)

        response.raise_for_status()
        return response

    def update_workflow(self, workflow_obj, workflow_id):
        if not workflow_id:
            workflow_id = workflow_obj.get("id")
        url = f"{self.n8n_config.workflow_base_url}/{workflow_id}"
        response = self.session().patch(url, json=workflow_obj)
        response.raise_for_status()
        return response

    def create_or_update_workflow(self, workflow_obj):
        workflow_name = workflow_obj['name']
        wf_prev = self.get_workflow_object(workflow_name)
        if wf_prev:
            workflow_id = wf_prev["data"].get("id")
            self._log(f"Workflow {workflow_name} already exists with id={workflow_id}. Updating.")
            self.update_workflow(workflow_obj, workflow_id)
        else:
            self._log(f"Creating workflow {workflow_name}")
            return self.create_workflow(workflow_obj)

    def create_workflow_if_not_exists(self, workflow_obj):
        workflow_name = workflow_obj['name']
        workflow_obj = self.get_workflow_object(workflow_name)
        if workflow_obj:
            self._log(f"Workflow {workflow_name} already exists")
            return workflow_obj
        else:
            self._log(f"Creating workflow {workflow_name}")
            return self.create_workflow(workflow_obj)

    def get_execution_id(self, workflow_data):
        """
        Executes workflow by passing workflow object
        and returns the status and execution id
        """
        url = f"{self.n8n_config.workflow_base_url}/run"
        response = self.session().post(url, json=workflow_data)
        if response.status_code != 200:
            return False, response.text

        execution_id = response.json()["data"]["executionId"]
        return True, execution_id

    def should_stop_polling(self, response, validation_func=None):
        # Stops polling if the response is success and the
        # provided validation function returns True
        if response is None:
            # Continue polling
            return False

        if response.status_code == 200:
            if validation_func:
                return validation_func(response)
            return True

        # Non-success response: stop polling
        return True

    def is_execution_finished(self, response):
        # Validation function which returns True only if
        # workflow execution is finished
        if response is None:
            return False
        json_dict = response.json()

        is_finished = False
        data = json_dict.get('data')
        if data and data['finished']:
            is_finished = True
        return is_finished

    def get_execution_result(self, execution_id, log):
        url = f"{self.n8n_config.n8n_base_url}/executions/{execution_id}"
        response = polling2.poll(
            lambda: self.session().get(url),
            step=5, step_function=lambda step: max(step + 5, 60),
            check_success=lambda r: self.should_stop_polling(r, self.is_execution_finished),
            timeout=self.n8n_config.timeout_seconds
        )
        response.raise_for_status()
        result_data = json.loads(response.json()["data"]["data"])
        log(f"Result data from executions/{execution_id}: {result_data}")
        return result_data

    def make_result_data_old(self, result_data):
        return_payload = {}
        for elements in result_data:
            if "json" in elements and isinstance(elements, dict):
                for element in elements:
                    if isinstance(elements[element], str):
                        return_payload.update(result_data[int(elements[element])])
                    else:
                        return_payload[element] = element[elements]
        fine_tune_payload(return_payload, result_data)
        for data in return_payload:
            fine_tune_payload(return_payload[data], result_data)
        return return_payload


class ExecuteWorkflow:
    def execute(self, log, payload=None, bot_or_context=None):
        return_payload = {}
        try:
            self._log = log

            log(f"Input payload: {payload}")
            workflow_name = payload["jsonFileName"]

            # Remove n8n settings from payload to avoid leaking sensitive info
            n8n_config_path = payload.pop("n8n_config_path", None)
            log(f"payload after removing n8n config: {payload}")

            if not n8n_config_path:
                n8n_config_path = N8N_CONFIG_PATH
            n8n_overrides = N8NConfig.pop_n8n_config_settings(payload)

            n8n_config = N8NConfig(n8n_config_path, n8n_overrides, log)
            self.n8n = N8NExecutor(n8n_config, log)

            workflow_obj = self.n8n.get_workflow_object(workflow_name)

            # extract global vars and create workflow_data
            workflow_data = inject_global_vars_into_workflow(workflow_obj, payload, log)

            # pass updated workflow object and execute workflow
            workflow_data["mode"] = "cli"
            success, execution_id = self.n8n.get_execution_id(workflow_data)

            # get execution result and return payload
            if success:
                result_data = self.n8n.get_execution_result(execution_id, log)

                execution_result = self.n8n.make_result_data_old(result_data)
                execution_result_full = N8NExecutionData.decode_execution_data(result_data)

                execution_result_new = N8NExecutionData.get_last_executed_json_data(execution_result_full)

                return_payload["SUCCESS"] = "true"
                return_payload["WORKFLOW_OUTPUT_OLD"] = execution_result
                return_payload["WORKFLOW_OUTPUT"] = execution_result_new
                return_payload["WORKFLOW_OUTPUT_FULL"] = execution_result_full

                log(f"Successful execution of workflow: {workflow_name}")
                log(f"Execution result (Old): {json.dumps(execution_result, indent=2)}")
                log(f"Execution result (New): {json.dumps(execution_result_new, indent=2)}")
                log(f"Execution result (Full): {json.dumps(execution_result_full, indent=2)}")
            else:
                log(f"Error during workflow {workflow_name} execution: {execution_id}")
                return_payload["SUCCESS"] = "false"

            return return_payload

        except Exception:
            formatted = traceback.format_exc()
            log(f"Workflow {workflow_name} Exception: \\n{formatted}")
            return_payload["SUCCESS"] = "false"
            return return_payload

`;

export const executeWorkflowDefaultScript_v6_DynamicInteractions = `
# -*- coding: utf-8 -*-
r"""
    ______  ______  _____________________  _________    __  ___
   / __ \\ \\/ / __ \\/ ____/ ___/_  __/ __ \\/ ____/   |  /  |/  /
  / /_/ /\\  / /_/ / __/  \\__ \\ / / / /_/ / __/ / /| | / /|_/ /
 / ____/ / / ____/ /___ ___/ // / / _, _/ /___/ ___ |/ /  / /
/_/     /_/_/   /_____//____//_/ /_/ |_/_____/_/  |_/_/  /_/
action node script

"""
import re
import sys
import time
import traceback
from pathlib import Path
from string import Formatter
from urllib.error import HTTPError

import requests
import json
from collections import abc


CURRENT_DIR = Path(__file__).parent

N8N_BASE_URL = "${config.n8nEndpoint}"

# Bot Manager only allows .py or .csv extensions
N8N_CONFIG_FILE = "n8n_config.py"
N8N_CONFIG_PATH = CURRENT_DIR / N8N_CONFIG_FILE


class OutputMode:
    PREVIEW_1 = "preview_1_compat"
    EXTRACT_ALL = "extract_all"
    EXTRACT_SINGLE = "extract_single"
    DYNAMIC_INTERACTIONS = "dynamic_interaction"


DYNAMIC_INTERACTION_NEXT_NODE = ""

# Output Keys
IGNORED_BINARY_ITEMS = "ignored_binary_items"
PYPESTREAM_METADATA = "pypestream_metadata"
WORKFLOW_OUTPUT = "WORKFLOW_OUTPUT"
EMBED_CAROUSEL = 'embed_carousel'
EMBED_LISTPICKER = 'embed_listpicker'
EMBED_QUICK_REPLY = 'embed_quick_reply'


class DefaultOutput:
    Success = {
        "SUCCESS": "true",
        EMBED_QUICK_REPLY: None,
        EMBED_LISTPICKER: None,
        EMBED_CAROUSEL: None,
        IGNORED_BINARY_ITEMS: None
    }

    Failure = {
        "SUCCESS": "false",
        WORKFLOW_OUTPUT: None,
        "error_message": None,
        "error_detail": None,
    }


class N8NWorkflowTimeoutError(Exception):
    """ Raised when a workflow execution times out """
    pass


class N8NDataTransformError(Exception):
    """ Raised when there is an error transforming data from N8N encoded format to standard nested JSON """
    pass


class N8NExecutionError(Exception):
    """
    Exceptions which occur inside N8N.
    The important error detail is provided by N8N.
    """

    # This directory and all above will be removed from stack trace
    N8N_DEFAULT_DIRNAME="coywolf-n8n"

    def __init__(self, message, error_detail, **kwargs):
        super().__init__(message)

        n8n_dirname = kwargs.pop("n8n_dirname", None)
        cause = kwargs.pop("cause", None)
        """
        Example Cause:
        {
            "status": "rejected", 
            "reason": {
                "message": "getaddrinfo ENOTFOUND api.genderize.io", 
                "name": "Error", 
                "stack": ("Error: getaddrinfo ENOTFOUND api.genderize.io\\n"
                          "    at GetAddrInfoReqWrap.onlookup [as oncomplete] (node:dns:71:26)", "code": "ENOTFOUND")
            }
        }
        """

        if not n8n_dirname:
            n8n_dirname = self.N8N_DEFAULT_DIRNAME

        self.error_detail_orig = error_detail
        self.error_detail = self.remove_path_prefixes(error_detail, n8n_dirname)

        if cause:
            self.error_detail += f"\\nCause: {cause}"

    @staticmethod
    def remove_path_prefixes(error_detail, last_dirname):
        pattern = r"/\\S+/" + last_dirname + r"/"
        # remove all instances of the pattern from the error detail
        return re.sub(pattern, "", error_detail)


class N8NNodeExecutionError(N8NExecutionError):
    """ Raised when N8N reports errors executing a node """
    def __init__(self, message, error_detail, **kwargs):
        super().__init__(message, error_detail, **kwargs)


class N8NRunWorkflowError(N8NExecutionError):
    """ Raised when there is an error calling the N8N run workflow endpoint """
    def __init__(self, message, error_detail, **kwargs):
        super().__init__(message, error_detail, **kwargs)


class N8NProtocolError(Exception):
    """ Raised when there is an error parsing the data returned by N8N """
    pass


class Interpolate:
    @staticmethod
    def replace(original: str, variables: dict, replace_undefined: bool = False) -> str:
        """
        Non-recursive String Interpolation:
        Each placeholder in original like "{VARNAME}" is replaced with the value of variable VARNAME.
        If the value also contains a placeholder, it is NOT replaced. This is done to avoid surprises.
        Whitespace is allowed between the brackets and the variable name.
        :param original: The string containing variables to interpolate
        :param variables: The dictionary of variables to use for interpolation
        :param replace_undefined: If True, undefined variables are replaced with an empty string
        :rtype:
        """
        pattern = r"(\\{\\s*([A-Za-z0-9_]+)\\s*\\})"

        processed = []

        # for each regex match in orig_str
        prev_end = 0
        for match in re.finditer(pattern, original):
            varname = match.group(2)
            match_start = match.start(1)

            # Add everything since the last match to the processed list
            processed.append(original[prev_end:match_start])

            if varname in variables:
                # Add variable value to processed list
                val = str(variables[varname])
                processed.append(val)
            else:
                if replace_undefined:
                    # Add empty string to processed list
                    processed.append("")
                else:
                    # Add matched string to processed list
                    processed.append(match.group(1))

            # Track the last match endpoint
            prev_end = match.end(1)

        # if processed list is not empty, join it
        if len(processed) > 0:
            processed.append(original[prev_end:])
            return "".join(processed)
        else:
            # There were no matches, return the original string
            return original


def replace_val(to_update, key, log, **kwargs)->None:
    global_vars = kwargs.get("gv")

    curr_value = to_update[key]
    if not isinstance(curr_value, str):
        # Don't try to replace values that aren't strings
        return

    to_update[key] = Interpolate.replace(curr_value, global_vars)


def update_nested_json(nested, function, log, **kwargs):
    """
    Iterates through all keys in a nested json and
    performs the requested function on the eventual
    bottom level key and the dict it belongs to.
    """
    for key, value in list(nested.items()):
        if isinstance(value, abc.Mapping):
            update_nested_json(value, function, log, **kwargs)
        elif isinstance(value, list):
            for item in value:
                if isinstance(item, abc.Mapping):
                    update_nested_json(item, function, log, **kwargs)
        else:
            function(nested, key, log, **kwargs)
    log(f"After updating nodes with GV: {nested}")


def inject_global_vars_into_workflow(workflow_data, global_vars, log):
    wf_data = workflow_data.get("data")
    if not wf_data:
       raise Exception(f"Workflow data is missing 'data' key. workflow_data: {workflow_data}")

    nodes = wf_data.get("nodes")
    if nodes:
        for node in nodes:
            update_nested_json(node, replace_val, log, gv=global_vars)

    wf_data.pop("createdAt", None)
    wf_data.pop("updatedAt", None)
    workflow_data["workflowData"] = wf_data
    workflow_data.pop("data")

    return workflow_data


class N8NConfig:
    # Config Keys
    WORKFLOW_BASE_URL = "n8n_workflow_base_url"
    BASE_URL = "n8n_base_url"
    EMAIL = "n8n_login_email"
    PASSWORD = "n8n_login_password"
    TIMEOUT = "n8n_timeout_seconds"
    RETRY_SLEEP = "n8n_retry_sleep_seconds"

    ALL_KEYS = [EMAIL, PASSWORD, BASE_URL, WORKFLOW_BASE_URL, TIMEOUT, RETRY_SLEEP]

    DEFAULTS = {
        TIMEOUT: 300,
        BASE_URL: N8N_BASE_URL,
        RETRY_SLEEP: 5
    }

    def __init__(self, config_file_path, overrides, log):
        if config_file_path is None:
            config_file_path = N8N_CONFIG_PATH

        self.config_file_path = Path(config_file_path)

        self._validate_n8n_config(overrides, "overrides parameter")
        self.overrides = overrides
        self._log = log

        self.load_config(config_file_path)

    def load_config(self, config_file_path):
        # Read defaults from config file
        defaults = self._read_config_file()
        self._validate_n8n_config(defaults, config_file_path)
        self.defaults_from_file = defaults

        # Merge with overrides
        config_dict = self._merge_defaults_and_overrides()

        self.login_email = config_dict.get(self.EMAIL)
        self.login_password = config_dict.get(self.PASSWORD)

        self.timeout_seconds = self._get(config_dict, self.TIMEOUT)
        self.retry_sleep_seconds = self._get(config_dict, self.RETRY_SLEEP)

        # Set URLs
        self.n8n_base_url = self._get(config_dict, self.BASE_URL)

        # Default is just the base URL + /workflows
        wf_url_default = self.join_url(self.n8n_base_url, "workflows")
        self.workflow_base_url = self._get(config_dict, self.WORKFLOW_BASE_URL, wf_url_default)

    def _get(self, config_dict, key, default=None):
        """ Get a value from the config dict and return the default if it's not present """
        config_val = config_dict.get(key)
        if config_val is None:
            if default is None:
                default = self.DEFAULTS.get(key)
            config_val = default
        return config_val

    @staticmethod
    def join_url(a, b):
        return a.rstrip("/") + "/" + b.lstrip("/")

    def _merge_defaults_and_overrides(self):
        """
        Merge default settings from config file with override values
        Return the resulting config as a dict
        """
        # Avoid overriding a value with None
        overrides = {key: value for key, value in self.overrides.items() if value is not None}

        merged_config = {**self.defaults_from_file, **overrides}
        return merged_config

    def _validate_n8n_config(self, config, source_description):
        for key in config:
            if key not in N8NConfig.ALL_KEYS:
                raise Exception(f"Invalid key in {source_description}: {key}")

    @staticmethod
    def pop_n8n_config_settings(dict_to_modify):
        """ Remove n8n config settings from dict_to_modify and return those settings in a dict """
        return {key: dict_to_modify.pop(key, None) for key in N8NConfig.ALL_KEYS}

    def _read_config_file(self):
        n8n_config = {}
        if self.config_file_path.exists():
            with open(self.config_file_path) as f:
                n8n_config = json.load(f)
        else:
            self._log(f"Warning: config file not found: {self.config_file_path}")
        return n8n_config


class N8NExecutionData:
    @staticmethod
    def is_integer(s):
        try:
            int(s)
            return True
        except ValueError:
            return False

    @classmethod
    def _expand(cls, array, idx):
        root = array[idx]
        if isinstance(root, dict):
            result = {key: cls._expand_string(array, val) for key, val in root.items()}
        elif isinstance(root, list):
            result = [cls._expand_string(array, val) for val in root]
        else:
            result = root
        return result

    @classmethod
    def _expand_string(cls, array, val):
        """ If val is a string representing integer N, then it is a pointer to index N. Recursively expand
        the value at that index and replace the value with the result. Otherwise return the val unchanged.
        """
        if isinstance(val, str) and cls.is_integer(val):
            val = cls._expand(array, int(val))
        return val

    @classmethod
    def decode_execution_data(cls, array):
        try:
            return cls._expand(array, 0)
        except Exception as e:
            raise N8NDataTransformError("There was an error decoding the workflow result data.") from e

    @staticmethod
    def get_node_rundata(decoded_output, node_name):
        run_data = decoded_output["resultData"]["runData"]
        node_data = run_data.get(node_name)
        return node_data

    @classmethod
    def get_last_executed_node_data(cls, decoded_output):
        # logic adapted from N8N function getDataLastExecutedNodeData in
        # https://github.com/pypestream/coywolf-n8n/blob/fd2b09b7351387960b3092f61c7821e78469a363/packages/cli/src/WorkflowHelpers.ts
        last_executed_node = decoded_output["resultData"].get("lastNodeExecuted")

        if not last_executed_node:
            return None

        last_node_data = cls.get_node_rundata(decoded_output, last_executed_node)
        if not last_node_data:
            return None

        if isinstance(last_node_data, list):
            return last_node_data[-1]
        else:
            return last_node_data

    @classmethod
    def get_last_executed_results(cls, decoded_output:dict):
        """
        Get the results of the last executed node.
        :param decoded_output:  Result of calling decode_execution_data on the "data" key in the workflow output
        :return: (main_data, error) tuple. main_data is the "main" key of the last executed node. error is the "error" key.
        """
        last_node_data = cls.get_last_executed_node_data(decoded_output)

        cls.maybe_raise_n8n_node_error(last_node_data)

        main_data = last_node_data.get("data", {}).get("main")

        return main_data

    @classmethod
    def maybe_raise_n8n_node_error(cls, last_node_data):
        error = last_node_data.get("error")

        """
        Example error object:
        {
        "message": "Unexpected token "return" [Line 122]",
        "stack": "/Users/ryan/src/pypestream/coywolf-n8n/packages/nodes-base/dist/nodes/Function:4
          return channel;
          ^^^^^^
        
        SyntaxError: Unexpected token "return"
            at makeNiceSyntaxError (/Users/ryan/src/pypestream/coywolf-n8n/node_modules/vm2/lib/transformer.js:41:16)
            at transformer (/Users/ryan/src/pypestream/coywolf-n8n/node_modules/vm2/lib/transformer.js:80:8)
            at NodeVM.run (/Users/ryan/src/pypestream/coywolf-n8n/node_modules/vm2/lib/nodevm.js:413:17)
            at Object.execute (/Users/ryan/src/pypestream/coywolf-n8n/packages/nodes-base/nodes/Function/Function.node.ts:122:22)
            at Workflow.runNode (/Users/ryan/src/pypestream/coywolf-n8n/packages/workflow/src/Workflow.ts:1173:28)
            at /Users/ryan/src/pypestream/coywolf-n8n/packages/core/src/WorkflowExecute.ts:814:41
            at processTicksAndRejections (node:internal/process/task_queues:96:5)"
        }
        """

        if error:
            message = error.get("message")
            n8n_stacktrace = error.get("stack")
            cause = error.get("cause")

            raise N8NNodeExecutionError(message, n8n_stacktrace, cause=cause)

    @classmethod
    def remove_json_wrappers(cls, json_wrapped_data):
        """
        # N8N wraps each row of data with a json object. Remove the wrapper.
        :param json_wrapped_data:
        :type json_wrapped_data:
        :return:
        :rtype:
        """
        if not json_wrapped_data:
            return None
        if isinstance(json_wrapped_data, list):
            # [{"json": DATA1 }, {"json": DATA2 }] -> [DATA1, DATA2]
            return [cls.remove_json_wrappers(val) for val in json_wrapped_data]
        if isinstance(json_wrapped_data, dict):
            # {"json": DATA } -> DATA
            if "json" in json_wrapped_data:
                return json_wrapped_data["json"]
            return json_wrapped_data

    @staticmethod
    def parse_items(wrapped_data):
        """
        Parse items from N8N into a list of N8NDataItem objects
        :param wrapped_data:
        :type wrapped_data:
        :return:
        :rtype:
        """
        results = []
        if not wrapped_data:
            return None

        if isinstance(wrapped_data, list):
            for execution_data in wrapped_data:
                for item in execution_data:
                    results.append(N8NDataItem(item))
        else:
            raise N8NDataTransformError(f"Unexpected data type: {type(wrapped_data)}")

        return results


class N8NDataItem:
    def __init__(self, item):
        self.paired_item = N8NDataItem.unbox(item, "pairedItem")

        # json key exists in every item or N8N throws an error.
        self.json_data = N8NDataItem.unbox(item, "json")
        self.binary_data = None

        if "binary" in item:
            self.binary_data = N8NDataItem.unbox(item, "binary")

    @classmethod
    def unbox(cls, item:dict, key:str="json"):
        """
        If item is a dict mapping key to boxed_value, return boxed_value.
        Otherwise return the item itself.
        If boxed_value is an empty dict, replace it with None
        Examples:
        unbox({"json": {DATA} }) -> {DATA}
        unbox({DATA}) -> {DATA}
        :param item: A dict boxing a value
        :param key: Specify the key of the boxed value. Default is "json".
        :return: The boxed value
        """
        if key in item:
            val = item[key]
            # Convert empty dict to None
            return val if val else None
        return item


class N8NExecutor:
    def __init__(self, n8n_config, log):
        self.n8n_config = n8n_config
        self._log = log
        self._session = None

    def session(self):
        # Lazy init of session
        if not self._session:
            self.init_session()
        return self._session

    def init_session(self):
        """ Create session with n8n server and login if credentials are found"""

        # The session manages authentication cookies automatically
        self._session = requests.Session()
        email = self.n8n_config.login_email
        password = self.n8n_config.login_password
        n8n_base_url = self.n8n_config.n8n_base_url

        if email and password:
            self._log(f"logging in as user {email}")
            self._login_response = self.login(email, password, n8n_base_url)
            self._login_data = self._login_response.json()
        else:
            raise Exception(f"Missing email or password. Email: {email}, Password Length: {len(password)}")
            self._log("Skipping login because credentials were not supplied")

    def _get_http_error_reason(self, http_response):
        if isinstance(http_response.reason, bytes):
            # We attempt to decode utf-8 first because some servers
            # choose to localize their reason strings. If the string
            # isn't utf-8, we fall back to iso-8859-1 for all other
            # encodings. (See PR #3538)
            try:
                reason = http_response.reason.decode('utf-8')
            except UnicodeDecodeError:
                reason = http_response.reason.decode('iso-8859-1')
        else:
            reason = http_response.reason
        return reason

    def login(self, email, password, n8n_base_url=None):
        if not n8n_base_url:
            n8n_base_url = N8N_BASE_URL

        url = f"{n8n_base_url}/login"
        params = {
            "email": email,
            "password": password
        }
        response = self.session().post(url, json=params)

        if response.status_code == 401:
            reason = self._get_http_error_reason(response)
            http_error_msg = f'401 Client Error: {reason} for url: {url}. Username: {email},' + \\
                             f' Password Length: {len(password)}.  If your password has been changed recently,' + \\
                             f' it may still need to be changed in N8N.'
            raise requests.HTTPError(http_error_msg, response=response)
        else:
            response.raise_for_status()
        return response

    def get_workflow_object(self, workflow_name):
        """
        Gets workflow object by workflow name
        """
        url = f"{self.n8n_config.workflow_base_url}/name/{workflow_name}"
        response = self.session().get(url)

        if response.status_code == 401:
            reason = self._get_http_error_reason(response)
            http_error_msg = f'401 Client Error: {reason} for url: {url}.'
            raise requests.HTTPError(http_error_msg, response=response)

        response.raise_for_status()
        workflow_obj = response.json()

        return workflow_obj

    def create_workflow(self, workflow_obj):
        url = self.n8n_config.workflow_base_url
        response = self.session().post(url, json=workflow_obj)
        if 400 <= response.status_code < 500:
            body_json = None
            try:
                body_json = response.json()
            except:
                pass
            error_msg = f"{response.status_code} Client Error: {response.reason} for url: {url}\\n{body_json}"
            raise HTTPError(error_msg, response=response)

        response.raise_for_status()
        return response

    def update_workflow(self, workflow_obj, workflow_id):
        if not workflow_id:
            workflow_id = workflow_obj.get("id")
        url = f"{self.n8n_config.workflow_base_url}/{workflow_id}"
        response = self.session().patch(url, json=workflow_obj)
        response.raise_for_status()
        return response

    def create_or_update_workflow(self, workflow_obj):
        workflow_name = workflow_obj["name"]
        wf_prev = self.get_workflow_object(workflow_name)
        if wf_prev:
            workflow_id = wf_prev["data"].get("id")
            self._log(f"Workflow {workflow_name} already exists with id={workflow_id}. Updating.")

            # Remove id from workflow_obj to prevent update from trying to change id
            workflow_obj.pop("id", None)

            self.update_workflow(workflow_obj, workflow_id)
        else:
            self._log(f"Creating workflow {workflow_name}")
            return self.create_workflow(workflow_obj)

    def create_workflow_if_not_exists(self, workflow_obj):
        workflow_name = workflow_obj["name"]
        workflow_obj = self.get_workflow_object(workflow_name)
        if workflow_obj:
            self._log(f"Workflow {workflow_name} already exists")
            return workflow_obj
        else:
            self._log(f"Creating workflow {workflow_name}")
            return self.create_workflow(workflow_obj)

    def start_workflow(self, workflow_data):
        """
        Executes workflow by passing workflow object
        and returns the status and execution id
        """
        url = f"{self.n8n_config.workflow_base_url}/run"
        response = self.session().post(url, json=workflow_data)
        if response.status_code != 200:
            resp_dict = response.json()
            if resp_dict:
                message = resp_dict.get("message")
                hint = resp_dict.get("hint")
                stack = resp_dict.get("stack")
                raise N8NRunWorkflowError(f"Error starting workflow: {message} {hint}", stack)
            else:
                raise N8NRunWorkflowError(f"Error starting workflow. HTTP Status={response.status_code}", response.text)

        try:
            execution_id = response.json()["data"]["executionId"]
        except Exception as e:
            raise N8NProtocolError(f"Error getting executionId from reponse: {response.text}") from e

        return execution_id

    def should_stop_polling(self, response, validation_func=None):
        # Stops polling if the response is success and the
        # provided validation function returns True
        if response is None:
            # Continue polling
            return False

        if response.status_code == 200:
            if validation_func:
                return validation_func(response)
            return True

        # Non-success response: stop polling
        return True

    def is_execution_finished(self, response):
        # Validation function which returns True only if
        # workflow execution is finished
        if response is None:
            return False
        json_dict = response.json()

        is_finished = False
        data = json_dict.get("data")
        if data and (data.get("stoppedAt") or data.get("finished")):
            is_finished = True
        return is_finished

    def get_execution_result(self, execution_id, log):
        url = f"{self.n8n_config.n8n_base_url}/executions/{execution_id}"

        # Throws TimeoutException
        response = self.poll_with_timeout(
            target=lambda: self.session().get(url),
            check_success=lambda r: self.should_stop_polling(r, self.is_execution_finished),
            timeout_seconds=self.n8n_config.timeout_seconds,
            retry_sleep_seconds=self.n8n_config.retry_sleep_seconds,
            log=log
        )

        response.raise_for_status()

        try:
            result_data = json.loads(response.json()["data"]["data"])
            # log(f"Result data from executions/{execution_id}: {result_data}")
        except Exception as e:
            raise N8NProtocolError(f"Error reading execution result data: {response.text}") from e

        return result_data

    def poll_with_timeout(self, target, check_success, timeout_seconds, retry_sleep_seconds, log):
        ignored_exceptions = ()
        loop_count = 0
        start = time.time()
        while True:
            elapsed = time.time() - start
            if elapsed > timeout_seconds:
                raise N8NWorkflowTimeoutError(f"Timed out waiting for execution result after {elapsed} seconds and {loop_count} attempts")
            loop_count += 1

            try:
                result = target()
                if check_success(result):
                    break
            except ignored_exceptions:
                formatted = traceback.format_exc()
                log(f"Caught Ignored Exception in get_execution_result: {formatted}")

            # Sleep before next loop
            log(f"Sleeping {retry_sleep_seconds} seconds before retry {loop_count}")
            time.sleep(retry_sleep_seconds)

        return result


class EmbedFormatter(Formatter):
    """
    A formatter that inserts an empty string if a given
    key is not provided.
    """

    def get_value(self, key, args, kwargs):
        if isinstance(key, str):
            return kwargs.get(key, "")
        return Formatter.get_value(key, *args, **kwargs)


class DynamicInteractions:
    def __init__(self, log=None):
        if log is None:
            log = lambda x: False
        self.log = log

    def get_additional_cta_str(
            self,
            additional_cta,
            formatter,
    ):
        """
        Constructs an additional CTA string from a list of
        additional CTAs.  There could be 0, 1, or 2 additional
        CTAs specified in each option, but the final string
        needs spacers for 2.
        """
        if not additional_cta:
            additional_cta = [{}, {}]

        elif len(additional_cta) == 1:
            additional_cta.append({})

        additional_cta = [
            formatter.format("~{label}*{url}*{code}~", **item)
            for item in additional_cta
        ]
        additional_cta = "*".join(additional_cta)
        return additional_cta

    PROPERTY_NAMES = [
        "additional_cta", "alt_text", "code", "description", "image", "int_ref", "label",
        "opt_text", "subtitle", "title", "url"
    ]

    def make_embed_str(self, embed_type, dynamic_options, static_options=None,
                       node_name=DYNAMIC_INTERACTION_NEXT_NODE) -> str:
        """
        Formats an embed interaction string, given an embed type,
        node name, dynamic embed options, and any additional static
        options.

        Parameters:
        :embed_type        supports button, selection, listpicker, quick_reply, and carousel
        :dynamic_options   a list of dictionaries containing the needed embed attributes
        :node_name         The name of the next node.  Any spaces will be replaced with underscore.

        :return a string with the formatted embed interaction options
        """
        embed = EmbedFormatter()

        option_strings = {
            "listpicker": "<<{label}~{image}~{alt_text}~{description}~{int_ref}*{code}>>",
            "quick_reply": "<<{label}~{url}~{int_ref}*{code}>>",
            "carousel": "<<{title}~{image}*{alt_text}*{url}*{subtitle}*{opt_text}*{label}"
                        "*{code}*{additional_cta}>>",
        }

        embed_options = dynamic_options + (static_options if static_options else [])

        if embed_type == "carousel":
            for option in embed_options:
                option["additional_cta"] = self.get_additional_cta_str(
                    option.get("additional_cta", []),
                    embed,
                )

        options = [
            embed.format(
                option_strings[embed_type],
                int_ref=node_name.replace(" ", "_"),
                **option,
            )
            for option in embed_options
        ]
        return ",".join(options)

    @classmethod
    def convert_n8n_item(cls, n8n_item):
        option = {
            **n8n_item
        }
        output_code = option.pop("output_code", None)
        if output_code:
            option["code"] = output_code
        if "additional_cta" not in option:
            ctas = [
                option.pop("call_to_action_2", None),
                option.pop("call_to_action_3", None)
            ]

            additional_cta = [c for c in ctas if c is not None]

            if (len(additional_cta) > 0):
                option["additional_cta"] = additional_cta
        return option

    def check_input(self, input_dicts):
        # Check if any of the input_dicts have keys not in PROPERTY_NAMES
        for input_dict in input_dicts:
            for key in input_dict:
                if key not in self.PROPERTY_NAMES:
                    self.log(f"Warning: Unknown property name: {key} in input_dict")


class ExecuteWorkflow:
    def execute(self, log, payload=None, bot_or_context=None):
        try:
            self._log = log

            log(f"Input payload: {payload}")
            workflow_name = payload["jsonFileName"]

            # Remove n8n settings from payload to avoid leaking sensitive info
            n8n_config_path = payload.pop("n8n_config_path", None)
            log(f"payload after removing n8n config: {payload}")

            if not n8n_config_path:
                n8n_config_path = N8N_CONFIG_PATH
            n8n_overrides = N8NConfig.pop_n8n_config_settings(payload)

            input_minus_n8n_config = payload.copy()

            n8n_config = N8NConfig(n8n_config_path, n8n_overrides, log)
            self.n8n = N8NExecutor(n8n_config, log)

            workflow_obj = self.n8n.get_workflow_object(workflow_name)

            self.remove_leading_stars_from_values(payload)

            # extract global vars and create workflow_data
            workflow_data = inject_global_vars_into_workflow(workflow_obj, payload, log)

            output_payload = self.execute_n8n(workflow_data, workflow_name, log)

            # Add input payload to output payload. This is useful for troubleshooting
            # why global vars are not being injected into the workflow.
            output_payload["input_payload"] = input_minus_n8n_config

            return self.finalize_payload(output_payload)

        except Exception:
            # Top-Level Exception Handler: This should be mostly programming errors in Action Node script.
            # If we throw exception from here, it will be caught by bot-framework and end user will see a
            # generic message like: A call to action node ExecuteWorkflow failed for {Customer}.{Solution}.{Version}
            # So instead, we show them a slighly more helpful message with the Exception type and message.

            # Avoid including Stack Trace here until we consider any security implications.
            return self.exception_payload(workflow_name, log, include_stack_trace=False)

    @staticmethod
    def remove_leading_stars_from_values(payload:dict):
        # Remove leading star from payload vars. This is a quick hack to
        # fix an issue we found for the all-hands demo on Oct 20 2022.
        # We should remove this later.
        for key, val in payload.items():
            if val and isinstance(val, str):
                # The leading stars are seen for variables from dynamic interactions.  If multiple selections
                # are made, the values are separated by a comma and each value is prefixed by a star.
                value_parts = val.split(",")
                star_removed = [v.lstrip("*") for v in value_parts]
                payload[key] = ",".join(star_removed)

    def execute_n8n(self, workflow_data, workflow_name, log):
        """
        Executes workflow in n8n and format the result for output
        :param workflow_data:
        :param workflow_name:
        :param log:
        :return:
        """

        # pass updated workflow object and execute workflow
        workflow_data["mode"] = "cli"

        try:
            # raises N8NRunWorkflowError and N8NProtocolError
            execution_id = self.n8n.start_workflow(workflow_data)

            # raises N8NWorkflowTimeoutError and N8NProtocolError
            result_data = self.n8n.get_execution_result(execution_id, log)

            # raises N8NDataTransformError
            execution_result_full = N8NExecutionData.decode_execution_data(result_data)

            # raises N8NNodeExecutionError
            last_result = N8NExecutionData.get_last_executed_results(execution_result_full)

            log(f"Successful execution of workflow: {workflow_name}")

            return_payload = self.success_payload(last_result)

        except Exception:
            return_payload = self.exception_payload(workflow_name, log, include_stack_trace=True)
        return return_payload

    def finalize_payload(self, payload:dict):
        result = self.copy_uppercase_keys_to_lowercase(payload)

        # Add list of variables to payload
        result["all_variables"] = [str(key) for key in result.keys()]

        return result

    def copy_uppercase_keys_to_lowercase(self, payload):
        result = {}
        for key in payload:
            result[key] = payload[key]
            if key.isupper():
                result[key.lower()] = payload[key]
        return result

    def success_payload(self, last_result, log=print):
        payload = {
            **DefaultOutput.Success,
            "workflow_output_n8n_format": last_result
        }
        payload_extra = {}

        # Save to a variables in case user wants to make this input to another workflow

        data_items = N8NExecutionData.parse_items(last_result)

        # If first item is our special metadata value, remove it from the data_items list
        metadata = self.pop_metadata_item(data_items)

        output_mode = OutputMode.PREVIEW_1
        extract_key = "data"
        if metadata:
            output_mode = metadata.get("output_mode")
            extract_key = metadata.get("extract_key", "data")

        # Set the payload according to output mode
        if output_mode == OutputMode.EXTRACT_SINGLE:
            if len(data_items) > 0:
                payload[WORKFLOW_OUTPUT] = data_items[0].json_data.get(extract_key)
            else:
                #TODO: Test this in a unit test
                payload[WORKFLOW_OUTPUT] = None
        elif output_mode == OutputMode.EXTRACT_ALL:
            payload[WORKFLOW_OUTPUT] = [x1.json_data.get(extract_key) for x1 in data_items]
        elif output_mode == OutputMode.DYNAMIC_INTERACTIONS:
            payload_extra = self.do_output_mode_dynamic_interactions(data_items)
        else:
            # we expect output_mode == OutputMode.PREVIEW_1
            if output_mode != OutputMode.PREVIEW_1:
                log(f"WARNING: Defaulting to {OutputMode.PREVIEW_1} mode but output_mode is unexpectedly: {output_mode}")
            payload_extra = self.do_output_mode_preview_1(data_items)

        payload.update(payload_extra)
        return payload

    def do_output_mode_dynamic_interactions(self, data_items):
        payload_extra = {}
        special_output, embed_variables = self.make_dynamic_embeds(data_items)
        payload_extra[WORKFLOW_OUTPUT] = special_output
        # Copy any embed variables to payload which are truthy
        for key, val in embed_variables.items():
            if val:
                payload_extra[key] = val
        return payload_extra

    def do_output_mode_preview_1(self, data_items):
        payload_extra = {}
        # Partition data_items into json and binary subsets
        json_items, binary_items = self.partition_json_and_binary(data_items)
        # Return binary items to Solution unaltered.
        # TODO: Is there a better way to handle this?
        payload_extra[IGNORED_BINARY_ITEMS] = [x.binary_data for x in binary_items]
        # Return list of json items to Solution unaltered.
        # TODO: Is there anything useful we can do with the paired_item?
        wf_output = [x.json_data for x in json_items]
        # Preview 1 behavior.  We should probably not do this anymore.
        if len(wf_output) == 0:
            # Return None instead of empty list
            wf_output = None
        elif len(wf_output) == 1:
            # Convert single-item list to single item
            wf_output = wf_output[0]
        payload_extra[WORKFLOW_OUTPUT] = wf_output
        return payload_extra

    def pop_metadata_item(self, data_items):
        metadata = None

       # Check if first item is metadata
        if len(data_items) > 0 and PYPESTREAM_METADATA in data_items[0].json_data:
            """
            Example metadata item:
              {
                "json": {
                  "pypestream_metadata": {
                    "output_mode": "extract_single",
                    "extract_key": "extract_prop"
                  }
                }
              },
            """

            # Remove the metadata item
            metadata_item = data_items.pop(0)
            metadata = metadata_item.json_data[PYPESTREAM_METADATA]
        return metadata

    def partition_json_and_binary(self, data_items):
        binary_items = []
        json_items = []
        for data_item in data_items:
            if data_item.binary_data:
                binary_items.append(data_item)
            else:
                json_items.append(data_item)
        return json_items, binary_items

    def exception_payload(self, workflow_name, log, include_stack_trace=False, show_troubleshooting=True):
        # get exception tuple
        exc_type, exc_value, exc_traceback = sys.exc_info()

        if issubclass(exc_type, N8NExecutionError):
            error_message = str(exc_value)
        else:
            error_message = f"{exc_type.__name__}: {exc_value}"

        output = "Error executing workflow."

        if show_troubleshooting:
            output += "\\nTo troubleshoot, see variables error_message and error_detail"

        formatted = traceback.format_exc()
        payload = {
            **DefaultOutput.Failure,
            WORKFLOW_OUTPUT: output,
            "error_message": error_message,
            "error_detail": "",   #Ensure that this variable is always present for errors
        }

        if include_stack_trace:
            if issubclass(exc_type, N8NExecutionError):
                # For this type of error, the important stack trace is reported by N8N
                payload["error_detail"] = exc_value.error_detail
            else:
                # TODO: Consider security implications of this.
                payload["error_detail"] = formatted

        log(f"Workflow {workflow_name} Exception: \\n{formatted}")
        return payload

    def make_dynamic_embeds(self, data_items):
        dii = DynamicInteractions(self._log)

        # Convert N8N data items into input format for DynamicInteractions
        input_dicts = []
        for data_item in data_items:
            n8n_item = data_item.json_data
            option = DynamicInteractions.convert_n8n_item(n8n_item)
            input_dicts.append(option)

        # Log warnings for unsupported input properties
        dii.check_input(input_dicts)


        interactions = [
            (EMBED_QUICK_REPLY, "quick_reply", "Quick Reply"),
            (EMBED_LISTPICKER, "listpicker", "List Picker"),
            (EMBED_CAROUSEL, "carousel", "Carousel"),
        ]

        embed_strings = { }
        embed_strings_produced = []
        for output_var, interaction_type, friendly_name in interactions:
            try:
                embed_string = dii.make_embed_str(interaction_type, input_dicts)
                if embed_string:
                    embed_strings[output_var] = embed_string
                    embed_strings_produced.append(friendly_name)
            except Exception as e:
                self._log(f"Error making embed string for {friendly_name} embed: {e}")

        special_output = "The workflow produced data for these dynamic interactions:\\n"
        special_output += ", ".join(embed_strings_produced)
        special_output += ".\\nIf you want to use one of these dynamic interactions, you need to choose one in the designer UI."

        return special_output, embed_strings

`;
