import React, { useState, useEffect, useCallback } from "react";
import botManagerAPI from "bot-manager-api";
import PropTypes from "prop-types";

const UnpublishedChangesBadge = ({ botId, customerId }) => {
  const [hasChanges, setHasChanges] = useState(false);
  const checkUnpublishedChanges = useCallback(() => {
    if (!botId || !customerId) {
      return;
    }
    const id = `${customerId}.${botId}`;

    botManagerAPI.getUnpublished(id).then(({ data }) => {
      setHasChanges(data.data.has_changes);
    });
  }, [setHasChanges, botId, customerId]);

  useEffect(() => {
    const interval = setInterval(checkUnpublishedChanges, 5000);
    return () => clearInterval(interval);
  }, [checkUnpublishedChanges]);

  if (!hasChanges) {
    return null;
  }
  return (
    <div
      data-testid="UnpublishedChangesBadge"
      style={{
        backgroundColor: "#f1b617",
        borderRadius: "16px",
        boxSizing: "border-box",
        color: "#29302e",
        padding: "6px 12px",
      }}
    >
      <span
        style={{
          fontSize: "14px",
          fontWeight: 600,
          lineHeight: "20px",
          display: "inline-flex",
          alignItems: "center",
        }}
      >
        Unpublished Changes
      </span>
    </div>
  );
};

UnpublishedChangesBadge.propTypes = {
  botId: PropTypes.string.isRequired,
  customerId: PropTypes.string.isRequired,
};

export default UnpublishedChangesBadge;
