import produce from "immer";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { NODE_SETTING } from "../../enum";

import {
  SELECT_SAMPLE_NODE,
  UPDATE_SAMPLE_NODE,
  CLEAR_SAMPLE_NODE,
  ADD_CHILD_SAMPLE_NODE,
  FORMAT_SAMPLE_NODE,
  PROCESSING,
} from "./actionTypes";

const EmbedTypes = NODE_SETTING.EMBED_TYPES;
const NodeType = NODE_SETTING.TYPE;

const getInitialState = () => ({
  nodeInfo: {},
});

// ------------------------------------
// Normal Functions
// ------------------------------------
const populateEmbedsInfo = (nodeInfo, isEmbed, srcEmbedType) => {
  let res = nodeInfo;

  // 1. get common field.
  let commonValues = [];
  let srcType = "";
  if (isEmbed) {
    const embedInfo = nodeInfo.embeds[srcEmbedType];
    if (embedInfo) {
      switch (srcEmbedType) {
        case EmbedTypes.BUTTONS:
        case EmbedTypes.LIST_PICKER:
        case EmbedTypes.CAROUSEL:
          if (embedInfo.type !== "dynamic") {
            commonValues = (embedInfo[embedInfo.type] || [])
              .map(({ label, link }) => ({ label, link }))
              .filter(({ label, link }) => !!label || !!link.number);
            srcType = embedInfo.type;
          }

          if (window.featureFlags?.pe19893) {
            if (embedInfo.type === "dynamic") {
              commonValues = [
                {
                  ...(embedInfo.dynamic || {}),
                  link: embedInfo.dynamic?.linkTo,
                },
              ].filter(({ link }) => !!link?.number);
              srcType = embedInfo.type;
            }
          }
          // commonValues = (embedInfo[embedInfo.type] || [])
          //   .map(({ label, link }) => ({ label, link }))
          //   .filter(({ label, link }) => !!label || !!link.number);
          srcType = embedInfo.type;
          break;
        default:
          break;
      }
    }
  } else {
    const textFieldInfo = nodeInfo.textfield["None"];
    if (
      textFieldInfo &&
      textFieldInfo.data &&
      textFieldInfo.data.link &&
      textFieldInfo.data.link.number
    ) {
      commonValues = [{ link: textFieldInfo.data.link }];
    }
  }

  // 2. update other embeds.
  const fields = {
    [EmbedTypes.BUTTONS]: ["text", "images"],
    [EmbedTypes.LIST_PICKER]: ["text", "images"],
    [EmbedTypes.CAROUSEL]: ["standard"],
  };

  if (isEmbed) {
    if (srcType) {
      if (srcType !== "dynamic") {
        Object.keys(fields).forEach((type) => {
          fields[type].forEach((subType) => {
            if (type !== srcEmbedType || subType !== srcType) {
              if (res.embeds[type]) {
                if (res.embeds[type][subType]) {
                  res.embeds[type][subType] = commonValues.map(
                    ({ label, link }, index) => ({
                      ...(res.embeds[type][subType][index] || {}),
                      label,
                      link,
                    })
                  );
                } else {
                  res.embeds[type][subType] = commonValues;
                }
              } else {
                res.embeds[type] = {
                  type: fields[type][0],
                  [subType]: commonValues,
                };
              }
            }
          });
        });
      } else {
        if (window.featureFlags?.pe19893 && !isEmpty(commonValues)) {
          [
            EmbedTypes.BUTTONS,
            EmbedTypes.LIST_PICKER,
            EmbedTypes.CAROUSEL,
          ].forEach((type) => {
            if (res.embeds[type]) {
              res.embeds[type] = {
                ...(res.embeds[type] || {}),
                dynamic: {
                  sourceNode: commonValues[0].sourceNode,
                  sourceVar: commonValues[0].sourceVar,
                  linkTo: commonValues[0].linkTo,
                },
              };
            } else {
              res.embeds[type] = {
                type: "dynamic",
                dynamic: {
                  sourceNode: commonValues[0].sourceNode,
                  sourceVar: commonValues[0].sourceVar,
                  linkTo: commonValues[0].linkTo,
                },
              };
            }
          });
        }
      }
      // Update TextField info
      const link = commonValues.length > 0 ? commonValues[0].link : {};
      if (res.textfield) {
        if (res.textfield["None"]) {
          res.textfield["None"].data = {
            ...res.textfield["None"].data,
            link,
          };
        } else {
          res.textfield["None"] = {
            data: {
              link,
            },
          };
        }
      } else {
        res.textfield = {
          None: { data: { link } },
          type: srcEmbedType,
        };
      }
    }
  } else if (srcEmbedType === "None") {
    const link = commonValues.length > 0 ? commonValues[0].link : {};
    Object.keys(fields).forEach((type) => {
      fields[type].forEach((subType) => {
        res.embeds = res.embeds || {};
        if (res.embeds[type]) {
          if (
            res.embeds[type][subType] &&
            res.embeds[type][subType].length > 0
          ) {
            res.embeds[type][subType][0].link = link;
          } else {
            res.embeds[type][subType] = commonValues;
          }
        } else {
          res.embeds[type] = {
            type: fields[type][0],
            [subType]: commonValues,
          };
        }
      });
    });
  }
  return res;
};

const orderChanged = (array1, array2) => {
  const filterValues = (ar1, ar2) =>
    (ar1 || []).filter((a) => (ar2 || []).includes(a));
  const newArray1 = filterValues(array1, array2);
  const newArray2 = filterValues(array2, array1);

  return !isEqual(newArray1, newArray2);
};

const rearrangeChildren = (nodeInfo, embed) => {
  let resNodeInfo = { ...nodeInfo };
  const supportedEmbedTypes = [
    EmbedTypes.BUTTONS,
    EmbedTypes.CAROUSEL,
    EmbedTypes.LIST_PICKER,
  ];
  const { type: embedType } = embed || {};
  const embedData = embed[embedType];

  if (
    supportedEmbedTypes.includes(embedType) &&
    !isEmpty(embedData) &&
    embedData.type !== "dynamic"
  ) {
    // Check if the order is changed
    let oldNodeOrders = [];

    oldNodeOrders = (nodeInfo.embeds[embedType]?.[embedData.type] || [])
      .map((node) => node?.link?.number)
      .filter((order) => !!order);

    const newNodeOrders = (embedData[embedData.type] || [])
      .map((node) => node?.link?.number)
      .filter((order) => !!order);

    if (!isEmpty(newNodeOrders) && orderChanged(newNodeOrders, oldNodeOrders)) {
      // The order was changed.
      const childrenInfo = (resNodeInfo.children || []).reduce(
        (res, child) => ({ ...res, [child.number]: child }),
        {}
      );
      let orderIndex = 0;
      const newChildren = [];
      (resNodeInfo.children || []).forEach((child) => {
        if (newNodeOrders.includes(child.number)) {
          newChildren.push(childrenInfo[newNodeOrders[orderIndex]]);
          orderIndex += 1;
        } else {
          newChildren.push(child);
        }
      });
      resNodeInfo = {
        ...resNodeInfo,
        children: [...newChildren],
      };
    }
  }

  return resNodeInfo;
};

// ------------------------------------
// Reducers
// ------------------------------------
export default produce((draft = getInitialState(), action) => {
  let nodeInfo = draft.nodeInfo;
  const actionData = cloneDeep(action.data);
  const prevNodeInfo = cloneDeep(draft.nodeInfo);

  switch (action.type) {
    case PROCESSING:
      nodeInfo.processing = actionData.data;
      break;
    case SELECT_SAMPLE_NODE:
      // called when node is clicked on.
      draft.nodeInfo = actionData.node;
      break;
    case UPDATE_SAMPLE_NODE:
      // called when you make changes on node details window.
      nodeInfo.isWorkflow = actionData.isWorkflow || false;

      switch (actionData.type) {
        case "embeds":
        case "textfield":
          if (nodeInfo[actionData.type]) {
            const changedType = actionData.type;
            const embedType = actionData.data.type;

            nodeInfo[changedType].type = embedType;
            nodeInfo[changedType][embedType] = {
              ...nodeInfo[changedType][embedType],
              ...(actionData.data[embedType] || {}),
            };

            if (changedType === "embeds") {
              // rearrange children in case the order is changed.
              if (window.featureFlags?.pe20494) {
                nodeInfo.children = rearrangeChildren(
                  prevNodeInfo,
                  actionData.data
                ).children;
              } else {
                nodeInfo = rearrangeChildren(nodeInfo, actionData.data);
              }
            }
          } else {
            nodeInfo[actionData.type] = { ...(actionData.data || {}) };
          }

          nodeInfo = populateEmbedsInfo(
            nodeInfo,
            actionData.type === "embeds",
            actionData.data.type
          );
          break;
        case "messages":
          nodeInfo.messages = actionData.data;
          break;
        default:
          nodeInfo[actionData.type] = actionData.data;
          break;
      }
      break;
    case CLEAR_SAMPLE_NODE:
      draft.nodeInfo = {};
      break;
    case ADD_CHILD_SAMPLE_NODE:
      if (nodeInfo.children) {
        nodeInfo.children = [
          ...nodeInfo.children.slice(0, actionData.pos),
          { ...actionData.node },
          ...nodeInfo.children.slice(actionData.pos),
        ];
      } else {
        nodeInfo.children = [actionData.node];
      }
      break;
    case FORMAT_SAMPLE_NODE:
      if (actionData.type === NodeType.DECISION) {
        nodeInfo.embeds = {};
        nodeInfo.textfield = {};
        nodeInfo.messages = [];
      } else if (actionData.type === NodeType.ACTION) {
        if (actionData.isWorkflow) {
          nodeInfo.action = {
            script: "ExecuteWorkflow.py",
            conditions: [
              {
                condition: {
                  id: `condition-1`,
                  variable: "SUCCESS",
                  value: "true",
                  operator: "equal",
                  options: [],
                  link: {},
                },
                type: "if",
              },
              {
                condition: {
                  id: `condition-2`,
                  variable: "SUCCESS",
                  value: "false",
                  operator: "equal",
                  options: [],
                  link: {},
                },
                type: "else",
              },
            ],
            outputs_variables: nodeInfo.outputs_variables || [
              { value: "SUCCESS", label: "SUCCESS" },
              { value: "WORKFLOW_OUTPUT", label: "WORKFLOW_OUTPUT" },
            ],
            inputs: {
              parameters: JSON.stringify({
                jsonFileName: `${actionData.customerId}.${actionData.botId}_${nodeInfo.id}`,
              }),
            },
          };
        } else {
          nodeInfo.action = null;
        }
        break;
      }
      break;
    default:
      break;
  }

  return draft;
});
