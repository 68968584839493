import config from "../../../../../config";

const VALID_IMAGE_FORMAT = ["PNG", "GIF", "JPG", "JPEG"];
const VALID_VIDEO_FORMAT = ["MP4"];

const getImageDimension = (url) =>
  new Promise((resolve) => {
    const img = new Image();
    const dimensions = {
      width: "",
      height: "",
    };

    img.onload = function () {
      dimensions.width = this?.naturalWidth;
      dimensions.height = this?.naturalHeight;

      resolve(dimensions);
    };

    img.onerror = () => {
      resolve({ width: "0", height: "0" });
    };

    img.src = url;
  });

const getVideoDimension = (url) =>
  new Promise((resolve) => {
    const video = document.createElement("video");

    video.addEventListener(
      "loadedmetadata",
      function () {
        // retrieve dimensions
        const height = this.videoHeight;
        const width = this.videoWidth;
        // send back result
        resolve({ height, width });
      },
      false
    );

    video.src = url;
  });

const getFileType = (asset) => {
  let type = "";

  if (asset) {
    const lastDot = asset.lastIndexOf(".");
    if (lastDot) {
      type = asset.substr(lastDot + 1)?.toUpperCase();
      type = type.split("?").shift();
    }
  }

  return type;
};

const getFileCategory = (type) => {
  let category = "";

  if (VALID_IMAGE_FORMAT.includes(type)) {
    category = "image";
  } else if (VALID_VIDEO_FORMAT.includes(type)) {
    category = "video";
  }

  return category;
};

const getFiles = async (assets, customerId, botId) => {
  if (assets) {
    const filteredAssets = assets
      .filter((asset) => !!asset)
      .map((asset) => {
        const imgSrc =
          `${config.s3bucketUrl}/${customerId}/${botId}/assets/${asset}`.replace(
            / /g,
            "%20"
          );

        return {
          name:
            asset?.includes(".") && asset?.substring(0, asset.lastIndexOf(".")),
          src: imgSrc,
          type: getFileType(asset),
          category: getFileCategory(getFileType(asset)),
        };
      })
      .filter((asset) => !!asset.category);

    const dimensions = await Promise.all(
      filteredAssets.map((asset) => {
        if (asset.src && asset.category === "image") {
          return getImageDimension(asset.src);
        }

        if (asset.src && asset.category === "video") {
          return getVideoDimension(asset.src);
        }

        return Promise.resolve({});
      })
    );

    return filteredAssets.map((asset, index) => ({
      ...asset,
      dimensions: dimensions[index],
    }));
  }

  return [];
};

export {
  VALID_IMAGE_FORMAT,
  VALID_VIDEO_FORMAT,
  getFiles,
  getFileType,
  getFileCategory,
};
