import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { connect } from "react-redux";
import {
  doesUserHasRole,
  BOT_MANAGER_ROLES,
  PermissionDenied,
} from "@pypestream/bot-user-session";
import { Route, Redirect } from "react-router-dom";

import AllowedPath from "./AllowedPath";

const PrivateRoute = withLDConsumer()(
  ({
    component: Component,
    isAuthenticated,
    authenticationError,
    flags,
    ...rest
  }) => {
    const isAllowed = isAuthenticated && doesUserHasRole(BOT_MANAGER_ROLES);

    useEffect(() => {
      window.featureFlags = flags;
    }, [flags]);

    const renderedComponent = useCallback(
      (props) => {
        if (isAuthenticated && isAllowed) {
          const { pathname } = props.location;
          if (flags.pe20998 && pathname === "/customers") {
            // Redirect to Home Page if you are on customers page in case FF is on
            return <Redirect to={{ pathname: "/" }} />;
          }

          if (flags.pe20998 === false && pathname === "/") {
            // Redirect to Customers page if you are on home page in case FF is off
            return <Redirect to={{ pathname: "/customers" }} />;
          }

          if (flags.pe20999 === false && pathname.split("/")[4] === "manage") {
            // Display previous Dialog UI in case FF is off
            return (
              <Redirect
                to={{ pathname: pathname.split("/").slice(0, 4).join("/") }}
              />
            );
          }

          if (flags.pe20999 && pathname.startsWith("/bot/")) {
            const entities = pathname.split("/").filter((name) => !!name);
            const isEndWithSlash = pathname.endsWith("/");
            // Redirect to manage in case FF is on
            if (entities.length === 3) {
              return (
                <Redirect
                  to={{
                    pathname: `${pathname}${
                      isEndWithSlash ? "manage" : "/manage"
                    }`,
                  }}
                />
              );
            }
          }

          return <Component {...props} />;
        }

        if (isAuthenticated && !isAllowed) {
          return <AllowedPath {...props} />;
        }

        if (authenticationError) {
          return <PermissionDenied />;
        }

        return (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        );
      },
      [isAuthenticated, authenticationError, isAllowed, flags]
    );

    return <Route {...rest} render={renderedComponent} />;
  }
);

PrivateRoute.propTypes = {
  component: PropTypes.any,
  isAuthenticated: PropTypes.bool,
  authenticationError: PropTypes.bool,
  location: PropTypes.object,
};

export default withLDConsumer()(connect(() => ({}))(PrivateRoute));
