// ------------------------------------
// Action Types
// ------------------------------------
const SELECT_SAMPLE_NODE = "SELECT_SAMPLE_NODE";
const UPDATE_SAMPLE_NODE = "UPDATE_SAMPLE_NODE";
const CLEAR_SAMPLE_NODE = "CLEAR_SAMPLE_NODE";
const ADD_CHILD_SAMPLE_NODE = "ADD_CHILD_SAMPLE_NODE";
const FORMAT_SAMPLE_NODE = "FORMAT_SAMPLE_NODE";
const PROCESSING = "PROCESSING";

export {
  SELECT_SAMPLE_NODE,
  UPDATE_SAMPLE_NODE,
  CLEAR_SAMPLE_NODE,
  ADD_CHILD_SAMPLE_NODE,
  FORMAT_SAMPLE_NODE,
  PROCESSING,
};
