export const BOT_OVERVIEW = 0;
export const BOT_FILES = 1;
export const BOT_INTENTS = 2;
export const BOT_ENTITY = 3;
export const BOT_ACTION = 4;
export const BOT_TEMPLATES = 5;
export const BOT_TESTING = 6;
export const BOT_SETTINGS = 7;
export const BOT_INTERFACES = 8;
export const BOT_NLU_OVERVIEW = 9;
export const BOT_DIALOG = 10;
export const BOT_CHANNELS = 11;
export const BOT_ANALYTICS = 12;
export const BOT_MONITOR = 13;

export const BOT_VERSION_FLAGS = {
  UNKNOWN: 0,
  DEFAULT_NONE_CONTAINERIZED: 3,
  API_CONTAINERIZED: 4,
  NLU_ENABLED: 5,
  NLU_ROUTING: 6,
};

export const TEMPLATE_FILE_STATE = {
  NO_FILE: 0,
  ON_SERVER: 1,
  NEW: 2,
  FROM_DATA: 3,
};

export const BOT_UPLOAD_STATE = {
  NO_CHANGE: 0,
  NOT_READY: 1,
  READY: 2,
};

export const CSV_HEADERS_H = [
  "Node Number",
  "Node Type",
  "Node Name",
  "NLP Keywords",
  "Entry Node?",
  "Next Nodes",
  "Message",
  "Rich Asset Type",
  "Rich Asset Content",
  "Answer Required?",
  "Behaviors",
  "Command",
  "Description",
  "Output",
  "Node Input",
  "Parameter Input",
  "Decision Variable",
  "What Next?",
  "Node Tags",
  "Skill Tag",
  "Variable",
  "Platform Flag",
];

export const CSV_HEADERS_H_V5 = [
  "Node Number",
  "Node Type",
  "Node Name",
  "Intent",
  "Entity Type",
  "Entity",
  "NLU Disabled?",
  "Next Nodes",
  "Message",
  "Rich Asset Type",
  "Rich Asset Content",
  "Answer Required?",
  "Behaviors",
  "Command",
  "Description",
  "Output",
  "Node Input",
  "Parameter Input",
  "Decision Variable",
  "What Next?",
  "Node Tags",
  "Skill Tag",
  "Variable",
  "Platform Flag",
];

export const CSV_HEADERS_H_V6 = [
  "Node Number",
  "Node Type",
  "Node Name",
  "NLU Routing",
  "NLU Disabled?",
  "Next Nodes",
  "Message",
  "Rich Asset Type",
  "Rich Asset Content",
  "Answer Required?",
  "Behaviors",
  "Command",
  "Description",
  "Output",
  "Node Input",
  "Parameter Input",
  "Decision Variable",
  "What Next?",
  "Node Tags",
  "Skill Tag",
  "Variable",
  "Platform Flag",
];

export const NLU_ENTITY_HEADERS_H = ["Type", "Entity", "Synonym"];
export const NLU_INTENT_HEADERS_H = ["Utterance", "Intent", "Type"];
export const NLU_INTENT_HEADERS_SIMPLIFIED_H = ["Utterance", "Intent"];

export const DESIGNING_NLU_BOTS_URL =
  "https://pypestream.atlassian.net/servicedesk/customer/kb/view/676528163";
export const ACTION_NODE_URL =
  "https://pypestream.atlassian.net/servicedesk/customer/kb/view/669581408";

export const BLACK_LIST_FILE_NAMES = [
  "bot.zip",
  "nlu.zip",
  "dist/bot.json",
  "dist/bot.gpickle",
  "dist/config.json",
];

export const NLU_BOT_HEADERS = [...CSV_HEADERS_H_V5, "Design Information"];

export const NLU_INITIAL_INTENT = [
  ["can i restart from the beginning", "start_node"],
  ["can we go back to the beginning, please", "start_node"],
  ["can we start again", "start_node"],
  ["can we start over from the beginning", "start_node"],
  ["go back to beginning", "start_node"],
  ["go back to the beginning of the conversation", "start_node"],
  ["i want to restart", "start_node"],
  ["i want to restart the conversation", "start_node"],
  ["i want to start over", "start_node"],
  ["let's restart the conversation", "start_node"],
  ["let's start over", "start_node"],
  ["let's try this again", "start_node"],
  ["start from the beginning", "start_node"],
  ["start the conversation over", "start_node"],
  ["take me to the beginning", "start_node"],
  ["can this be over now?", "end_chat"],
  ["end this chat", "end_chat"],
  ["get me out of here", "end_chat"],
  ["go away", "end_chat"],
  ["i want the chat to be over", "end_chat"],
  ["i want to end the chat", "end_chat"],
  ["i want to get out of here", "end_chat"],
  ["i want to stop now", "end_chat"],
  ["i want to stop talking to you", "end_chat"],
  ["make the chat over", "end_chat"],
  ["make this chat end now", "end_chat"],
  ["please stop this thing", "end_chat"],
  ["stop", "end_chat"],
  ["stop the chat now, please", "end_chat"],
  ["can i speak with an agent", "transfer_to_agent"],
  ["can i speak with a representative", "transfer_to_agent"],
  ["connect me to a human, please", "transfer_to_agent"],
  ["connect me to customer service", "transfer_to_agent"],
  ["customer service", "transfer_to_agent"],
  ["do you have any human agents", "transfer_to_agent"],
  ["give me an agent", "transfer_to_agent"],
  ["human, please", "transfer_to_agent"],
  ["i need some help from a real person", "transfer_to_agent"],
  ["i want to speak to a customer service representative", "transfer_to_agent"],
  ["i want to speak to an agent", "transfer_to_agent"],
  ["i want to speak to a person", "transfer_to_agent"],
  ["i want to talk to customer service", "transfer_to_agent"],
  ["let me speak to a person", "transfer_to_agent"],
  ["speak to a human", "transfer_to_agent"],
];

export const NLU_INITIAL_ENTITY = [
  ["address", "mail", ""],
  ["address", "email", ""],
];

export const NLU_BOT_DEFAULT_DATA = [
  {
    "Node Number": "'-1800",
    "Node Type": "D",
    "Node Name": "Agent",
    "Next Nodes": 1,
    "Design Information": JSON.stringify({
      groupNumber: -5001,
      readOnly: true,
    }),
  },
  {
    "Node Number": "'-929",
    "Node Type": "D",
    "Node Name": "Start Over",
    "Next Nodes": 1,
    "Design Information": JSON.stringify({
      groupNumber: -5001,
      readOnly: true,
    }),
  },
  {
    "Node Number": "'-212",
    "Node Type": "D",
    "Node Name": "End Conversation",
    "Next Nodes": 1,
    "Design Information": JSON.stringify({
      groupNumber: -5001,
      readOnly: true,
    }),
  },
  {
    "Node Number": 1,
    "Node Type": "D",
    "Node Name": "Start Node",
    Message:
      "I can help you get tickets for an event, directions and answer some common questions. What would you like to do first?",
    "Design Information": JSON.stringify({
      x: 350,
      y: 50,
      width: 200,
      height: 50,
      groupNumber: -5000,
      readOnly: true,
    }),
  },
  {
    "Node Number": 2,
    "Node Type": "D",
    "Node Name": "Out Of Scope",
    Intent: "out_of_scope",
    "Next Nodes": 1,
    Message: "Sorry, your human language isn't clear to me.",
    "Design Information": JSON.stringify({
      groupNumber: -5001,
      readOnly: true,
    }),
  },
];

export const NLU_BOT_DEFAULT_CSV_ROWS = NLU_BOT_DEFAULT_DATA.map((row) =>
  NLU_BOT_HEADERS.map((field) => row[field] || "")
);

export const NLU_MODEL_STATUS = {
  NO_DATA: "NO_DATA",
  TRAINED: "TRAINED",
  TRAINING: "TRAINING",
  NOT_TRAINED: "NOT_TRAINED",
};
