import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal as ModalUI, TextField } from "@pypestream/ui-elements";

const PassthroughModal = ({
  sandboxPreviewMetadata,
  parametersError,
  passthroughModal,
  onClose,
  submitPassthrough,
  onChange,
  flags,
}) => {
  const [value, setValue] = useState(sandboxPreviewMetadata);
  return (
    <ModalUI
      hasOverlayBackground={false}
      open={passthroughModal}
      size="medium"
      valign="center"
      onClose={onClose}
    >
      <ModalUI.Header>
        <ModalUI.Title>Initializing Data</ModalUI.Title>
      </ModalUI.Header>
      <ModalUI.Content>
        <ModalUI.Text>
          This data is given to a solution before it starts, and typically
          originates from an API call or from the host website.
        </ModalUI.Text>
        <TextField
          data-testid="metadata-parameters"
          value={flags.pe20257 ? value : sandboxPreviewMetadata}
          label="Parameters"
          variant="outlined"
          multiline
          rowsMax={5}
          error={parametersError}
          helperText={"Data must be provided in valid JSON format"}
          onChange={flags.pe20257 ? (e) => setValue(e.target.value) : onChange}
        />
      </ModalUI.Content>
      <ModalUI.Footer>
        <ModalUI.PrimaryButton
          onClick={
            flags?.pe20257 ? () => submitPassthrough(value) : submitPassthrough
          }
        >
          Save and Restart
        </ModalUI.PrimaryButton>
      </ModalUI.Footer>
    </ModalUI>
  );
};

PassthroughModal.propTypes = {
  sandboxPreviewMetadata: PropTypes.string,
  flags: PropTypes.object,
  parametersError: PropTypes.bool,
  passthroughModal: PropTypes.bool,
  onClose: PropTypes.func,
  submitPassthrough: PropTypes.func,
  onChange: PropTypes.func,
};

export default PassthroughModal;
