import React, { useMemo, useEffect, memo } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  Icon,
  IconButton,
  IconNames,
  Nav,
  TextButton,
  Tooltip,
} from "@pypestream/ui-elements";
import { userLogout } from "@pypestream/bot-user-session";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { BOT_DIALOG, BOT_MONITOR } from "../Manager/Components/Bot/defs";
import PreviewPublishNav from "./PreviewPublishNav";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  getCustomer,
  getCustomersIfNeeded,
  updateChannels,
} from "Manager/Store/actions";
import { useHome } from "../../src/Manager/Components/use-home";
import config from "../../src/config";

export const Header = ({
  showProfile,
  bots,
  customer,
  customerId,
  botId,
  userLogout,
  history,
  getCustomer,
  updateChannels,
  userId,
  location,
}) => {
  const {
    pe20903,
    pe21135,
    pe20998,
    pe21802,
    pe21804,
    pe21856,
    pe22013,
    pe22650,
    pe23272,
  } = useFlags() || {};

  const { pathname } = location;

  const { customers, handleSelectCustomer, selectedCustomer, isLoading } =
    useHome({ userId, history });

  useEffect(() => {
    //selects first customer unless there is a selected customer
    if (!selectedCustomer && !customerId) {
      handleSelectCustomer(customers[0]?.name);
    } else {
      handleSelectCustomer(customerId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSelectCustomer, customers, customerId]);
  const consoleUrl = `${config.pypestreamApiUrl}/console/customers`;
  const parsedConsoleUrl = consoleUrl.replace("mocker-service", "platform");

  useEffect(() => {
    if (customerId) {
      getCustomer(customerId);
    } else if (customers.length < 0) {
      getCustomersIfNeeded();
    }
  }, [customerId, getCustomer, customers]);

  const signOutButton = (
    <Dropdown.Item key="sign-out" onClick={userLogout}>
      Sign Out
    </Dropdown.Item>
  );

  const menu = [
    ...(showProfile && pe20903
      ? [
          <Dropdown.Item
            key="profile"
            component="a"
            href="/console/profile"
            target="_blank"
          >
            Profile
          </Dropdown.Item>,
        ]
      : []),
    ...(showProfile && pe21856
      ? [
          <Dropdown.Item key="user-profile" component="a" href="/user-profile">
            User Profile
          </Dropdown.Item>,
        ]
      : []),
    signOutButton,
  ];

  let afterBotPath;
  const botsMenu = bots.map((bot) => {
    //afterBotID path(manage or build or analytics)
    const url = window.location.pathname;
    const splittedURL = url.split("/");
    if (splittedURL.length > 4) {
      afterBotPath = splittedURL[4];
    }
    return (
      <Dropdown.Item
        to={
          afterBotPath && pe22013
            ? `/bot/${customerId}/${bot.name}/${afterBotPath}`
            : `/bot/${customerId}/${bot.name}`
        }
        key={bot.name}
        component={Link}
        onClick={updateChannels}
      >
        {bot.name}
      </Dropdown.Item>
    );
  });

  const isMonitorTab = window.location.href.includes(`tab=${BOT_MONITOR}`);

  const navLinks = useMemo(
    () =>
      !!customerId && !!botId
        ? [
            {
              route: `/bot/${customerId}/${botId}/manage`,
              label: "Manage",
            },
            {
              route: `/bot/${customerId}/${botId}/build`,
              label: "Build",
            },
            {
              route: `/bot/${customerId}/${botId}/${
                pe21804 ? "analytics" : "monitor"
              }`,
              label: pe21804 ? "Analytics" : "Monitor",
            },
          ]
        : [],
    [customerId, botId, pe21804]
  );

  const activeRoute = useMemo(
    () => history?.location?.pathname,
    [history?.location]
  );

  const isHomePage = useMemo(() => {
    return activeRoute === "/" || activeRoute === `/${customerId}`;
  }, [activeRoute, customerId]);

  return (
    <Nav hideSidebar={pe21802 && isHomePage} className={pe22650 && "ps-header"}>
      <a
        href={
          customerId && pe20998
            ? `/${customerId}`
            : pe20998
            ? "/"
            : "/customers"
        }
      >
        <Nav.Logo />
      </a>
      <a
        href={
          customerId && pe20998
            ? `/${customerId}`
            : pe20998
            ? "/"
            : "/customers"
        }
      >
        <Nav.Product style={{ userSelect: "none" }}>
          {pe23272 ? "DesignPro" : "Studio"}
        </Nav.Product>
      </a>

      <Nav.SolutionDropdown style={{ minWidth: 0 }}>
        {customer ? (
          <Dropdown
            menu={[
              <Dropdown.Item
                key="AllCustomers"
                component={Link}
                to={
                  customerId && pe20998
                    ? `/${customerId}`
                    : pe20998
                    ? "/"
                    : "/customers"
                }
              >
                Home
              </Dropdown.Item>,
              <Dropdown.Divider key="menu-solutions-divider" />,
              ...botsMenu,
            ]}
          >
            {(open) => (
              <Nav.SolutionDropdownTrigger open={open}>
                {botId}
              </Nav.SolutionDropdownTrigger>
            )}
          </Dropdown>
        ) : null}
      </Nav.SolutionDropdown>
      <Nav.Links>
        {pe21135 ? (
          <>
            {navLinks.map((link, index) => (
              <Nav.LinksItem key={index}>
                <Link to={link.route}>
                  <Nav.LinksItemInner active={activeRoute === link.route}>
                    {link.label}
                  </Nav.LinksItemInner>
                </Link>
              </Nav.LinksItem>
            ))}
          </>
        ) : (
          <>
            {customer && botId ? (
              <>
                <Nav.LinksItem>
                  <Link to={`/bot/${customerId}/${botId}?tab=${BOT_DIALOG}`}>
                    <Nav.LinksItemInner active={!isMonitorTab}>
                      Manage
                    </Nav.LinksItemInner>
                  </Link>
                </Nav.LinksItem>
                <Nav.LinksItem>
                  <a href={`/solution/${customerId}/${botId}`}>
                    <Nav.LinksItemInner>Build</Nav.LinksItemInner>
                  </a>
                </Nav.LinksItem>
                <Nav.LinksItem>
                  <Link to={`/bot/${customerId}/${botId}?tab=${BOT_MONITOR}`}>
                    <Nav.LinksItemInner active={isMonitorTab}>
                      Monitor
                    </Nav.LinksItemInner>
                  </Link>
                </Nav.LinksItem>
              </>
            ) : null}
          </>
        )}
      </Nav.Links>
      <Nav.Extra>
        {customer && customerId && botId && (
          <PreviewPublishNav customerId={customerId} botId={botId} />
        )}
      </Nav.Extra>
      <div className="flex gap-6">
        {/* hide dropdown if current page is build or manage */}
        {!pathname.includes("build") && !pathname.includes("manage") && (
          <div className="mb-1 ml-2">
            <Dropdown
              menu={customers
                ?.sort((a, b) => a.name.localeCompare(b.name))
                ?.map((customer) => {
                  return (
                    <Dropdown.Item
                      disabled={selectedCustomer === customer.name || isLoading}
                      key={customer.name}
                      onClick={() => {
                        history.replace({
                          pathname: `/${customer.name}`,
                        });
                        handleSelectCustomer(customer.name);
                      }}
                    >
                      {customer?.name?.length <= 17
                        ? customer?.name
                        : customer?.name?.substr(0, 17) + "..."}
                    </Dropdown.Item>
                  );
                })}
              anchorHorizontalOrigin="right"
            >
              {(open) => (
                <TextButton variant="secondary">
                  {selectedCustomer}
                  <Icon
                    name={IconNames.ThinChevronDown}
                    fill="currentColor"
                    style={{
                      position: "relative",
                      top: "6.2px",
                      transform: `rotate(${open ? "180deg" : "0"})`,
                      transition: "250ms ease",
                    }}
                  />
                </TextButton>
              )}
            </Dropdown>
          </div>
        )}
        <div className="flex flex-row">
          <Tooltip label="Console">
            <IconButton
              style={{
                marginRight: "10px",
                marginTop:
                  !pathname.includes("build") &&
                  !pathname.includes("manage") &&
                  "5px",
              }}
              dark
              iconName="system"
              inactive={false}
              loaded={false}
              onClick={() => {
                window.open(`${parsedConsoleUrl}`, "_blank");
              }}
            />
          </Tooltip>

          <Nav.Profile>
            <Dropdown menu={menu} anchorHorizontalOrigin="right">
              {(open) => (
                <Tooltip label="Account">
                  <IconButton dark iconName={IconNames.Profile} active={open} />
                </Tooltip>
              )}
            </Dropdown>
          </Nav.Profile>
        </div>
      </div>
    </Nav>
  );
};

Header.propTypes = {
  userLogout: PropTypes.func,
  bots: PropTypes.array,
  customer: PropTypes.object,
  history: PropTypes.object,
  showProfile: PropTypes.bool,
  botId: PropTypes.string,
  customerId: PropTypes.string,
  getCustomer: PropTypes.func,
  updateChannels: PropTypes.func,
  customers: PropTypes.array,
  userId: PropTypes.string,
  location: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => ({
  userLogout() {
    dispatch(userLogout());
  },
  getCustomer(customerId) {
    return dispatch(getCustomer(customerId));
  },
  updateChannels: () => dispatch(updateChannels(null)),
  getCustomers: () => dispatch(getCustomersIfNeeded()),
});

let headerProps = {
  customer: undefined,
  botId: undefined,
  bots: [],
  customerId: undefined,
};

const changedMapStateToProps = (state, ownProps) => {
  const { customerId, botId } = ownProps.computedMatch.params;
  const customer = state.manager.customers[customerId];

  headerProps.customer = customer;
  headerProps.botId = botId;
  headerProps.customerId = customerId;

  if (customer?.bots && customer?.bots.length !== headerProps.bots.length) {
    headerProps = {
      customer,
      botId,
      customerId,
      bots:
        customer.bots
          .sort()
          .map((bot) => ({ name: bot.substr(customer?.id?.length + 1) })) || [],
    };
  }

  return headerProps;
};

const mapStateToProps = (state, ownProps) => {
  let bots = [];
  const { customerId, botId } = ownProps.computedMatch.params;
  const customer = state.manager.customers[customerId];
  if (customer && customer.bots) {
    bots = customer.bots
      .sort()
      .map((bot) => ({ name: bot.substr(customerId.length + 1) }));
  }

  return {
    userId: state.botUserSession.user?.user_id,
    customer,
    botId,
    bots,
    customerId,
  };
};

const NormalRenderedHeader = withRouter(
  connect(changedMapStateToProps, mapDispatchToProps)(memo(Header))
);

const MultiRerenderedHeader = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Header)
);

const _Header = (props) => {
  const { pe22386 } = useFlags();

  return pe22386 ? (
    <NormalRenderedHeader {...props} />
  ) : (
    <MultiRerenderedHeader {...props} />
  );
};

export default _Header;
