import "@babel/polyfill"; // This must be the first line in src/index.js
import "react-app-polyfill/ie11"; // This must be the second line in src/index.js

import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.css";
import "./Manager/Styles/Globals.scss";
import App from "./app";
import { StoreProvider } from "./StoreProvider";
import LDSetup from "./setupFeatureFlags";

(async () => {
  const LDProvider = await LDSetup();
  ReactDOM.render(
    <StoreProvider>
      <LDProvider>
        <App />
      </LDProvider>
    </StoreProvider>,
    document.getElementById("root")
  );
})();
