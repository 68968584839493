/* eslint-disable no-empty */
import * as t from "./actionTypes";
import isEmpty from "lodash/isEmpty";
import botManagerAPI from "../../bot-manager-api";
import activityTracker from "../../activityTracker";
import {
  executeWorkflowDefaultScript_v5_FixGlobalVars,
  executeWorkflowDefaultScript_v6_DynamicInteractions,
} from "scripts/ExecuteWorkflow";
import { makeN8NConfig } from "../../scripts/n8n_config";
import config from "../../config";
import Cookies from "js-cookie";
import axios from "axios";
import { uploadActionScripts } from "./botManager";

export function addBotToCustomer(customerId, botId) {
  return {
    type: t.ADD_BOT_TO_CUSTOMER,
    customerId,
    botId,
  };
}

export function deleteFromCustomer(customerId, botId) {
  return {
    type: t.DELETE_BOT_FROM_CUSTOMER,
    customerId,
    botId,
  };
}

export function setIfTrainingNeeded(flag) {
  return {
    type: t.SET_IF_TRAINING_NEEDED,
    data: flag,
  };
}

export function getCustomer(customerId) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({
        type: t.GET_CUSTOMER,
      });
      botManagerAPI
        .getCustomer(customerId)
        .then((customer) => {
          if (!customer.errors) {
            dispatch({
              type: t.GET_CUSTOMER_SUCCESSFULLY,
              customer,
            });
            resolve();
          } else {
            reject(customer.errors);
            dispatch({
              type: t.GET_CUSTOMER_FAILED,
              errors: customer.errors,
            });
          }
        })
        .catch((e) => {
          console.error(e);
          reject(e);
        });
    });
}

export function trainBot(versionId, callback) {
  return async (dispatch) => {
    dispatch({
      type: t.TRAIN_BOT,
      versionId,
      progress: 1,
    });

    try {
      const response = await botManagerAPI.trainVersion(versionId);
      activityTracker.logEvent(activityTracker.eventTypeNames.TRAIN);
      dispatch({
        type: t.TRAIN_BOT,
        versionId,
        progress: 100,
      });

      dispatch({
        type: t.SET_IF_TRAINING_NEEDED,
        data: false,
      });

      if (callback && typeof callback === "function") {
        callback(false);
      }

      const { id, version } = response.data;
      if (id) {
        dispatch(validateSolution(id, version, false));
        return false;
      } else {
        dispatch(
          updateSolutionInfo({
            loading: false,
            error: true,
            title: `Your latest draft (${
              versionId.split(".").slice(-1)[0]
            }) isn't ready to be published.`,
            description: response?.data?.errors ?? "Something went wrong!",
          })
        );
        return true;
      }
    } catch (err) {
      console.log(err);
      if (callback && typeof callback === "function") {
        callback(true);
      }
    }
  };
}

export function getEnvsFromBack() {
  return (dispatch) =>
    botManagerAPI.getEnvsFromBack().then((envs) => {
      dispatch({
        type: t.SET_ENVS,
        envs,
      });
    });
}

export function getCustomersIfNeeded() {
  return (dispatch, getState) => {
    const state = getState();
    if (!state.manager.wasCustomersFetched) {
      dispatch({
        type: t.GET_CUSTOMERS,
      });
      botManagerAPI
        .getCustomers(state?.botUserSession?.user?.user_id)
        .then((customers) => {
          dispatch({
            type: t.GET_CUSTOMERS_SUCCESSFULLY,
            customers,
          });
        })
        .catch(() => {
          dispatch({
            type: t.GET_CUSTOMERS_FAILED,
          });
        });
    }
  };
}

async function uploadActionScriptAndApiCredentials(
  execWorkflowContent,
  n8n_config_content,
  botData
) {
  /*
   * Upload ExecuteWorkflow and n8n_config files to the bot.
   * Avoid uploading files which are already in botData.latestVersion.files
   * execWorkflowContent: content of the ExecuteWorkflow file
   * n8n_config_content: content of the n8n_config file
   * botData: bot data from Bot Manager API
   */
  const fileData = [
    {
      path: "services/ExecuteWorkflow.py",
      content: execWorkflowContent,
      name: `ExecuteWorkflow.py`,
    },
    {
      path: "services/n8n_config.py",
      content: n8n_config_content,
      name: `n8n_config.py`,
    },
  ];

  // Filter out files that are already in the bot
  const botFiles = botData.latestVersion.files;
  const filteredFileData = fileData.filter(
    (file) => !botFiles.find((f) => f === file.path)
  );
  // Create the file upload objects
  const filesToUpload = filteredFileData.map((file) => {
    return new File([file.content], file.name, {
      type: "text/x-python-script",
    });
  });

  // if filesToUpload is not empty, upload files
  if (filesToUpload.length > 0) {
    await uploadActionScripts(botData?.latestVersion, filesToUpload);
  }
}

async function initActionNode_v5(botData, n8nConfig) {
  const n8n_config_content = JSON.stringify(n8nConfig, null, 4);
  console.log("Uploading ExecuteWorkflow.py v5 in actions.js");
  await uploadActionScriptAndApiCredentials(
    executeWorkflowDefaultScript_v5_FixGlobalVars,
    n8n_config_content,
    botData
  );
}

async function initActionNode_v6(botData, n8nConfig) {
  console.log("Uploading ExecuteWorkflow.py v6 in actions.js");
  const n8n_config_content = JSON.stringify(n8nConfig, null, 4);
  await uploadActionScriptAndApiCredentials(
    executeWorkflowDefaultScript_v6_DynamicInteractions,
    n8n_config_content,
    botData
  );
}

export async function initActionNode(botData, version, email, password) {
  // Initialize the action node in the bot
  // This function is intended to encapsulate all feature flag logic
  // related to uploading the action node script and credentials

  const base_url = config.n8nEndpoint; // defaults to production
  let n8n_config = makeN8NConfig(email, password, base_url);

  if (window.featureFlags?.pe22000) {
    await initActionNode_v6(botData, n8n_config);
  } else {
    await initActionNode_v5(botData, n8n_config);
  }
}

function verifyTokenAndInitActionNode(botData, version) {
  const token = Cookies.get("__session");
  axios
    .get(`${config.pypestreamApiUrl}/verify-token`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      const { username, password } = res.data;
      if (username && password) {
        initActionNode(botData, version, username, password);
      }
    })
    .catch((err) => {
      console.error(err);
    });
}

export function initBot(customerId, botId, compilerVersion) {
  return async (dispatch) => {
    try {
      // init solution info
      dispatch(
        updateSolutionInfo({
          loading: true,
          error: false,
          title: "Loading your latest published versions...",
          description: "",
        })
      );
      const id = `${customerId}.${botId}`;
      // noinspection JSCheckFunctionSignatures

      const [bot, envs, botHistory] = await Promise.all([
        botManagerAPI.getBot(id),
        botManagerAPI.getEnvironmentBotVersion(id),
        botManagerAPI.getBotHistory(customerId, botId),
      ]);

      const environmentVersion = envs.envs;
      let botData = {};
      const allUuids = botHistory.data.data.reduce((acc, cur) => {
        if (!acc.includes(cur.uuid)) {
          acc.push(cur.uuid);
        }
        return acc;
      }, []);

      let idToNameMapping = {};
      for (let i = 0; i < allUuids.length; ++i) {
        let user = null;
        let publisherNameLabel = "";

        try {
          user = await botManagerAPI.getUsername(allUuids[i]);
        } catch (error) {
          console.error("User not available");
        }

        if (!user) {
          publisherNameLabel = "Removed User";
        } else {
          publisherNameLabel =
            user.status === 404
              ? "Removed User"
              : user.status !== 401
              ? `${user.data.first_name} ${user.data.last_name}`
              : "******";
        }

        idToNameMapping[allUuids[i]] = publisherNameLabel;
      }
      botHistory.data.data.forEach((entry) => {
        if (idToNameMapping[entry.uuid]) {
          entry.username = idToNameMapping[entry.uuid];
        }
      });

      let currentVersions = [];
      let presVersions = [];
      let hasDraft = false;

      if (bot.versions && Array.isArray(bot.versions)) {
        const maxVersion = Math.max(
          ...bot.versions.map((v) => Number(v.substr(1)))
        );
        const versionData =
          bot.versions && Array.isArray(bot.versions)
            ? bot.versions.map((v) => {
                const versionEnvs = [];
                Object.keys(environmentVersion).forEach((envName) => {
                  const envVersion = environmentVersion[envName];
                  if (envVersion === v) versionEnvs.push(envName);
                });
                if (versionEnvs.length === 0 && v === `v${maxVersion}`) {
                  versionEnvs.push("draft");
                  hasDraft = true;
                }
                return { version: v, envs: versionEnvs };
              })
            : [];
        currentVersions = versionData.filter((v) => v.envs.length);
        presVersions = versionData.filter((v) => !v.envs.length);

        if (!Number.isNaN(maxVersion) && maxVersion !== -Infinity) {
          const version = await botManagerAPI.getBotVersion(
            `${bot.id}.v${maxVersion}`
          );
          version.compilerVersion || compilerVersion;
          botData = {
            ...botData,
            latestVersion: version,
            canPublish: true,
            botHistory,
          };

          dispatch({
            type: t.BOT_DATA_UPDATE,
            customerId,
            botId,
            botData,
          });

          if (window.featureFlags.pe22084) {
            const validationResult = await botManagerAPI.validateVersion(
              version.id
            );

            if (validationResult.status === 200) {
              botData = {
                ...botData,
                canPublish: true,
                publishMsg: `${
                  validationResult.data.messages &&
                  Array.isArray(validationResult.data.messages)
                    ? validationResult.data.messages.join(" ")
                    : ""
                }`,
                botHistory,
                isTrained: true,
              };

              dispatch({
                type: t.BOT_DATA_UPDATE,
                customerId,
                botId,
                botData,
              });

              dispatch(
                updateSolutionInfo({
                  loading: false,
                  error: false,
                  title: `Your latest draft (${botData.latestVersion.version}) is ready to be published.`,
                  description: "",
                })
              );
            } else {
              const trainError = (validationResult.data.messages || []).find(
                (msg) =>
                  msg ===
                  "Solution version is NLU enabled and has not been trained."
              );
              botData = {
                ...botData,
                canPublish: false,
                publishMsg: `${
                  validationResult.data.messages &&
                  Array.isArray(validationResult.data.messages)
                    ? validationResult.data.messages.join(" ")
                    : ""
                }`,
                botHistory,
                isTrained:
                  trainError ===
                  "Solution version is NLU enabled and has not been trained."
                    ? false
                    : true,
              };
              dispatch({
                type: t.BOT_DATA_UPDATE,
                customerId,
                botId,
                botData,
              });

              dispatch(
                updateSolutionInfo({
                  loading: false,
                  error: true,
                  title: `Your latest draft (${botData.latestVersion.version}) isn't ready to be published.`,
                  description: botData.publishMsg,
                })
              );
            }
          } else {
            botManagerAPI
              .validateVersion(version.id)
              .then(({ status, data }) => {
                if (status === 200) {
                  botData = {
                    ...botData,
                    canPublish: true,
                    publishMsg: `${
                      data.messages && Array.isArray(data.messages)
                        ? data.messages.join(" ")
                        : ""
                    }`,
                    botHistory,
                    isTrained: true,
                  };

                  dispatch({
                    type: t.BOT_DATA_UPDATE,
                    customerId,
                    botId,
                    botData,
                  });

                  dispatch(
                    updateSolutionInfo({
                      loading: false,
                      error: false,
                      title: `Your latest draft (${botData.latestVersion.version}) is ready to be published.`,
                      description: "",
                    })
                  );
                  verifyTokenAndInitActionNode(botData, version);
                } else {
                  const trainError = (data.messages || []).find(
                    (msg) =>
                      msg ===
                      "Solution version is NLU enabled and has not been trained."
                  );
                  botData = {
                    ...botData,
                    canPublish: false,
                    publishMsg: `${
                      data.messages && Array.isArray(data.messages)
                        ? data.messages.join(" ")
                        : ""
                    }`,
                    botHistory,
                    isTrained:
                      trainError ===
                      "Solution version is NLU enabled and has not been trained."
                        ? false
                        : true,
                  };
                  dispatch({
                    type: t.BOT_DATA_UPDATE,
                    customerId,
                    botId,
                    botData,
                  });

                  dispatch(
                    updateSolutionInfo({
                      loading: false,
                      error: true,
                      title: `Your latest draft (${botData.latestVersion.version}) isn't ready to be published.`,
                      description: botData.publishMsg,
                    })
                  );
                  verifyTokenAndInitActionNode(botData, version);
                }
              })
              .catch((e) => {
                console.error(e);
              });
          }
        }
      }

      botData = {
        ...botData,
        bot,
        currentVersions,
        presVersions,
        hasDraft,
        botHistory,
      };

      return dispatch({
        type: t.BOT_DATA_UPDATE,
        customerId,
        botId,
        botData,
      });
    } catch (e) {
      console.error(e);

      dispatch(
        updateSolutionInfo({
          loading: false,
          error: true,
          title: `Your latest draft (${compilerVersion}) isn't ready to be published.`,
          description: e,
        })
      );
    }
  };
}

export function initBotIfNeeded(customerId, botId) {
  return (dispatch, getState) => {
    const state = getState();
    if (
      !state.manager.customers[customerId] ||
      !state.manager.customers[customerId].botsData[botId]
    ) {
      dispatch(initBot(customerId, botId));
    }
  };
}

export function updateBot(botId, data) {
  return (dispatch) =>
    botManagerAPI.updateBot(botId, data).then((res) => {
      if (res.status === 200) {
        dispatch({
          type: t.BOT_DATA_UPDATE,
          customerId: res.data.data.customerName,
          botId: res.data.data.botName,
          botData: {
            bot: res.data.data,
          },
        });
      } else {
        Promise.reject(new Error("Error in setting language"));
      }
    });
}

export function updateStream(
  customerId,
  versionId,
  pypeId,
  streamId,
  botId,
  value,
  startChatId,
  endChatId,
  solution
) {
  return () =>
    new Promise((resolve, reject) => {
      botManagerAPI
        .updateStream(
          customerId,
          versionId,
          pypeId,
          streamId,
          botId,
          value,
          startChatId,
          endChatId,
          solution
        )
        .then(() => {
          resolve();
        })
        .catch((e) => {
          console.error(e);
          reject();
        });
    });
}

export function getPypes(customerId, versionId) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      botManagerAPI
        .getPypes(customerId, versionId)
        .then(async (pypes) => {
          const filteredPypes =
            pypes && pypes.filter((p) => p.customer_id === customerId);

          let requests = [];
          filteredPypes.forEach((pype) => {
            const pypeId = pype.id;

            requests.push(
              botManagerAPI.getPypeStream(customerId, versionId, pypeId)
            );
          });

          const results = await Promise.all(requests);

          results.forEach((streams, index) => {
            const pypeId = filteredPypes[index].id;

            if (streams.length) {
              streams.forEach((st) => {
                const streamId = st.id;
                const stream = {
                  auto_start_with_bot: st.auto_start_with_bot,
                  bot_enabled: st.bot_enabled,
                  end_chat_bot_enabled: st.end_chat_bot_enabled,
                  end_chat_bot_id: st.end_chat_bot_id,
                  id: st.id,
                  start_chat_bot_enabled: st.start_chat_bot_enabled,
                  start_chat_bot_id: st.start_chat_bot_id,
                };

                dispatch({
                  type: t.GET_BOT_STREAM_SUCCESSFULLY,
                  stream,
                  pypeId,
                  streamId,
                });
              });
            }
            dispatch({
              type: t.GET_PYPE_STREAM_SUCCESSFULLY,
              streams,
              pypeId,
            });
          });
          const isEmptyPypes = !pypes.length;
          dispatch({
            type: t.GET_PYPES_SUCCESSFULLY,
            pypes,
            isEmptyPypes,
          });
          resolve();
        })
        .catch((e) => {
          console.error(e);
          reject();
        });
    });
}

export function updatePypes(pypes) {
  return (dispatch) => {
    dispatch({
      type: t.UPDATE_PYPES_SUCCESSFULLY,
      pypes,
    });
  };
}

export function setPypeLoading(value) {
  return (dispatch) => {
    dispatch({
      type: t.SET_PYPE_LOADING,
      value,
    });
  };
}

export function setAllIntents(intents) {
  return (dispatch) => {
    dispatch({
      type: t.SET_ALL_INTENTS,
      intents,
    });
  };
}

export function updateIntentData(intentData) {
  return (dispatch) => {
    dispatch({
      type: t.SET_INTENT_DATA,
      intentData,
    });
  };
}
export function updateEntityData(entityData) {
  return (dispatch) => {
    dispatch({
      type: t.SET_ENTITY_DATA,
      entityData,
    });
  };
}

export function resetIntentData() {
  return (dispatch) => {
    dispatch({
      type: t.RESET_INTENT_DATA,
    });
  };
}

export function resetEntityData() {
  return (dispatch) => {
    dispatch({
      type: t.RESET_ENTITY_DATA,
    });
  };
}

export function getInterfaces(pypeId, env, interfacesAreEnabled) {
  return (dispatch) => {
    botManagerAPI
      .getInterfaces(pypeId, env, interfacesAreEnabled)
      .then((interfaces) => {
        let newInterfaces = interfaces.data;
        dispatch({
          type: t.GET_INTERFACES,
          interfaces: newInterfaces,
          correlation_id: interfaces.correlation_id,
        });
      })
      .catch((e) => console.error(e));
  };
}

export function selectedInterface(selectedInterface) {
  return (dispatch) => {
    dispatch({
      type: t.SELECTED_INTERFACE,
      selectedInterface,
    });
  };
}

export function removeInterfaces() {
  return (dispatch) => {
    dispatch({
      type: t.REMOVE_INTERFACES,
    });
  };
}
export function setEditedStopWords(isEdited = false) {
  return (dispatch) => {
    dispatch({
      type: t.SET_STOP_WORDS_EDITED,
      isEdited,
    });
  };
}

export function setNotification(data) {
  return (dispatch) => {
    dispatch({
      type: t.SET_NOTIFICATION,
      data,
    });
  };
}

export function updateSolutionInfo({ loading, error, title, description }) {
  return (dispatch) => {
    dispatch({
      type: t.UPDATE_SOLUTION_INFO,
      data: {
        loading,
        error,
        title,
        description,
      },
    });
  };
}

export function validateSolution(solutionId, version, autoTrain) {
  return async (dispatch) => {
    const [customerId, botId, version] = solutionId.split(".");
    botManagerAPI
      .validateVersion(solutionId)
      .then(({ status, data: { messages, data } }) => {
        if (status === 412) {
          if (
            messages[0].includes("train") ||
            messages[0].includes("action node")
          ) {
            dispatch({
              type: t.UPDATE_SOLUTION_INFO,
              data: {
                loading: true,
                error: false,
                title: `Loading your latest published versions...`,
                description: !autoTrain ? messages[0] : "",
              },
            });
            if (messages[0].includes("train")) {
              dispatch({
                type: t.SET_IF_TRAINING_NEEDED,
                data: true,
              });
            }

            if (autoTrain) {
              dispatch(trainBot(solutionId));
            } else {
              dispatch({
                type: t.UPDATE_SOLUTION_INFO,
                data: {
                  loading: false,
                  error: true,
                  title: `Your latest draft (${version}) isn't ready to be published.`,
                  description: messages[0],
                },
              });
              dispatch({
                type: t.BOT_DATA_UPDATE,
                customerId,
                botId,
                botData: {
                  publishMsg: `${
                    data.messages && Array.isArray(data.messages)
                      ? data.messages.join(" ")
                      : ""
                  }`,
                },
              });
            }
          }
        } else if (status === 200) {
          dispatch({
            type: t.BOT_DATA_UPDATE,
            customerId,
            botId,
            botData: {
              canPublish: true,
              isTrained: true,
              publishMsg: `${
                data.messages && Array.isArray(data.messages)
                  ? data.messages.join(" ")
                  : ""
              }`,
            },
          });
          dispatch({
            type: t.UPDATE_SOLUTION_INFO,
            data: {
              loading: false,
              error: false,
              title: `Your latest draft (${version}) is ready to be published.`,
              description: "",
            },
          });
          dispatch({
            type: t.SET_IF_TRAINING_NEEDED,
            data: false,
          });
        }
      })
      .catch((e) => console.log(e));
  };
}

export function updateChannels(channels) {
  return (dispatch) => {
    dispatch({
      type: t.UPDATE_CHANNELS,
      channels,
    });
  };
}

export const setPreviewModalOpen = (open, config) => {
  return (dispatch) => {
    dispatch({
      type: t.SET_PREVIEW_MODAL_OPEN,
      open,
      config,
    });
  };
};

export const resetPreviewRefreshFlag = () => {
  return (dispatch) => {
    dispatch({
      type: t.RESET_PREVIEW_REFRESH_FLAG,
    });
  };
};

export const fetchChannels = (customerGesId, customerId, botId) => {
  return async (dispatch) => {
    let allChannels = [];
    const envs = ["live", "sandbox"];

    dispatch({
      type: t.LOADING_CHANNELS,
      loading: true,
    });

    dispatch({
      type: t.UPDATE_CHANNELS,
      channels: null,
    });

    try {
      // 1. Get Channels.
      const channelResults = await Promise.all(
        envs.map((env) =>
          botManagerAPI.getAllChannelsInSolution(
            customerGesId,
            `${customerId}.${botId}`,
            env
          )
        )
      );
      channelResults.forEach((resC, index) => {
        allChannels = [
          ...allChannels,
          ...resC.data.map((item) => {
            const isWrongChannel =
              item.id == null ||
              Object.values(item.config).some((value) => !value) ||
              item.pype_id === undefined ||
              item.pype_id === null ||
              isEmpty(item.config);

            return {
              widget: {
                channel_id: item.id,
                isAChannel: !isWrongChannel,
                widget_config: item.config,
                widget_name: item.name,
                widget_icon: isWrongChannel ? "Warning Icon" : item.logo,
                widget_type: item.type,
                last_updated: item.last_updated,
                last_update_by: item.last_update_by,
                pype_id: item.pype_id,
                env: envs[index],
                start_bot: item.start_bot,
              },
            };
          }),
        ];
      });
      // 2. Get Interfaces.
      // 2.1 Get pypes
      const reqPypes = await Promise.all(
        envs.map((env) => botManagerAPI.getPypes(customerGesId, env))
      );
      const allPypes = reqPypes.reduce(
        (res, resPypes, index) => [
          ...res,
          ...resPypes.map((pype) => ({
            ...pype,
            env: envs[index],
          })),
        ],
        []
      );
      // 2.2 Get streams
      const reqStreams = await Promise.all(
        allPypes.map((pype) =>
          botManagerAPI.getPypeStream(pype.customer_id, pype.env, pype.id)
        )
      );
      const allStreams = reqStreams.reduce(
        (res, resStreams, index) => [
          ...res.filter((stream) =>
            resStreams.every((str) => str.id !== stream.id)
          ),
          ...resStreams.map((stream) => ({
            ...stream,
            env: allPypes[index].env,
          })),
        ],
        []
      );
      // 2.3 Get interfaces
      const reqInterfaces = await Promise.all(
        allPypes.map((pype) =>
          botManagerAPI.getInterfaces(pype.id, pype.env, true)
        )
      );
      const allInterfaces = reqInterfaces.reduce(
        (res, resInterfaces, index) => [
          ...res,
          ...resInterfaces.data.map((iface) => ({
            widget: {
              ...iface.widget,
              env: allPypes[index].env,
            },
          })),
        ],
        []
      );
      // 2.4 Filter interfaces
      const mergedId = `${customerId}.${botId}`;
      const filteredStreams = allStreams.filter(
        (stream) => stream.start_chat_bot_id === mergedId
      );
      let filteredInterfaces = allInterfaces
        .filter((iface) =>
          filteredStreams.some((stream) => stream.id === iface.widget.stream_id)
        )
        .filter(({ widget }) => !widget.created_by.startsWith("preview"));

      dispatch({
        type: t.UPDATE_CHANNELS,
        channels: [...allChannels, ...filteredInterfaces],
      });
      dispatch({
        type: t.LOADING_CHANNELS,
        loading: false,
      });
    } catch (err) {
      dispatch({
        type: t.UPDATE_CHANNELS,
        channels: null,
      });
      dispatch({
        type: t.LOADING_CHANNELS,
        loading: false,
      });
    }
  };
};
