import React, { Fragment } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";

function NotFound() {
  const { pe23272 } = useFlags() || {};

  return (
    <Fragment>
      <h2>Page not found</h2>
      <p className="mt-3">
        Sorry! The page you requested does not exist. Click{" "}
        <a
          id="root"
          href={window.config.PS_BOT_MANAGER_FRONTEND_HOMEPAGE || "/"}
        >
          here
        </a>{" "}
        to go back to {pe23272 ? "DesignPro" : "Studio"}.
      </p>
    </Fragment>
  );
}

export default NotFound;
