import botManagerAPI from "../../bot-manager-api";

export const uploadActionScripts = async (latestVersion, scripts) => {
  const draftVersion = latestVersion.id;

  try {
    const formData = new FormData();

    // loop over scripts and add to formData
    scripts.forEach((script) => {
      formData.append(`scriptFile`, script);
    });

    await botManagerAPI.uploadScript(draftVersion, formData);
  } catch (error) {
    console.log(error);
  }
};
