import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import {
  isPermitted,
  PermissionAction,
  PermissionResource,
} from "@pypestream/bot-user-session";
import UnpublishedChangesBadge from "./UnpublishedChangesBadge";
import Loader from "../Manager/Components/Loader";
import botManagerAPI from "../bot-manager-api";
import "../Manager/Styles/Bot.scss";
import { PreviewButton } from "../Containers";
import {
  getCustomer,
  getCustomersIfNeeded,
  initBot,
  getPypes,
  updateStream,
  updatePypes,
  setPypeLoading,
  updateBot,
  getInterfaces,
  removeInterfaces,
  trainBot,
  setNotification,
  validateSolution,
  setPreviewModalOpen,
} from "Manager/Store/actions";
import {
  BOT_VERSION_FLAGS,
  NLU_INTENT_HEADERS_H,
  NLU_INTENT_HEADERS_SIMPLIFIED_H,
  BOT_DIALOG,
} from "../Manager/Components/Bot/defs";
import { Helmet } from "react-helmet";
import activityTracker from "../activityTracker";
import { getIntentFormat } from "../Manager/Components/Bot/utils";
import { Button, Tooltip, Stack } from "@pypestream/ui-elements";
import RedExclamationIcon from "../Manager/Assets/ico-exclamation-red.svg";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { backgroundGrey600 } from "utils/styleVariables.js";

const ENVS = ["sandbox", "live"];

const SimplePublishSlnModalLazy = React.lazy(() =>
  import("../Manager/Components/modals/SimplePublishSlnModal")
);

const PublishMicroAppButton = React.lazy(() =>
  import("../Containers/PublishMicroAppButton/PublishMicroAppButton")
);

const LazyRoutes = ({ children }) => (
  <React.Suspense fallback={<Loader />}>{children}</React.Suspense>
);

LazyRoutes.propTypes = {
  children: PropTypes.node,
};

export class PreviewPublishNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPublishModal: false,
      isValidatingBeforePublish: false,
      selectedVersion2Publish: null,
      pypes: [],
      allPypes: [],
      streams: [],
      versionLoading: false,
      noCreateDraft: false,
      keysPressed: [],
      selectedSolutionsForPublish: [],
      showPreviewPublishOptions: false,
    };

    this.publishLatest = this.publishLatest.bind(this);
    this.closePublishModal = this.closePublishModal.bind(this);
    this.publishVersion = this.publishVersion.bind(this);
    this.getVersionEnvironmentLatest =
      this.getVersionEnvironmentLatest.bind(this);
    this.getFileFromServer = this.getFileFromServer.bind(this);
    this.initBot = this.initBot.bind(this);
    this.clearAndFetchInterfaces = this.clearAndFetchInterfaces.bind(this);
    this.uploadDefaultNLUData = this.uploadDefaultNLUData.bind(this);
    this.isPublishDisabled = this.isPublishDisabled.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.onSimplePublish = this.onSimplePublish.bind(this);
    this.getPypeStreamInSolutions = this.getPypeStreamInSolutions.bind(this);
    this.setPreviewData = this.setPreviewData.bind(this);
    this.getBotName = this.getBotName.bind(this);
    this.updateVisibilityOfPreviewPublishOptions =
      this.updateVisibilityOfPreviewPublishOptions.bind(this);
    this.redirectToManageTab = this.redirectToManageTab.bind(this);
  }

  async componentDidMount() {
    if (!this.props.customer) {
      await this.props.getCustomer(this.props.customerId);
    }

    await this.props.initBot();
    if (
      this.props.botData &&
      this.props.botData.latestVersion &&
      this.props.botData.latestVersion.compilerVersion
    ) {
      this.setState({
        compilerVersionDetail: this.props.botData.latestVersion.compilerVersion,
      });
      const { id, version } = this.props.botData.latestVersion;
      this.props.validateSolution(id, version, true);
    }
    activityTracker.logEvent(
      activityTracker.eventTypeNames.ACCESSED_SOLUTION_MANAGEMENT,
      { solutionName: `${this.props.customerId}.${this.props.botId}` }
    );

    this.updateVisibilityOfPreviewPublishOptions(this.props.location?.pathname);
  }

  componentDidUpdate(prevProps) {
    const { botData } = this.props;

    if (prevProps.botId !== this.props.botId && this.props.flags.pe22013) {
      this.initBot();
    }

    if (
      prevProps.botData &&
      botData &&
      prevProps.botData.latestVersion &&
      botData.latestVersion &&
      botData.latestVersion.compilerVersion &&
      prevProps.botData.latestVersion.compilerVersion !==
        botData.latestVersion.compilerVersion
    ) {
      this.setState({
        compilerVersionDetail: this.props.botData.latestVersion.compilerVersion,
      });
    }

    if (prevProps.location?.pathname !== this.props.location?.pathname) {
      this.updateVisibilityOfPreviewPublishOptions(
        this.props.location?.pathname
      );
    }
  }

  updateVisibilityOfPreviewPublishOptions(pathname) {
    let showPreview = true;

    if (pathname) {
      const isMonitorTab =
        pathname.startsWith("/bot") && pathname.endsWith("/analytics");
      const isManageTab = this.props.flags.pe21811
        ? false
        : pathname.startsWith("/bot") && pathname.endsWith("/manage");

      showPreview = !isMonitorTab && !isManageTab;
    }

    this.setState({ showPreviewPublishOptions: showPreview });
  }

  getBotName() {
    const parts = this.props.botId.split(".");
    return parts.length >= 2 ? parts[1] : parts[0];
  }

  redirectToManageTab() {
    this.props.history.push(
      `/bot/${this.props.customerId}/${this.props.botId}?tab=${BOT_DIALOG}`
    );
  }

  getVersionEnvironmentLatest(currentVersions, latestVersion) {
    let envs = (currentVersions || [])
      .filter((ver) => ver.version === latestVersion.version)
      .reduce((res, ver) => [...res, ...ver.envs], []);
    let env = "";

    if (envs) {
      if (envs.includes("live") || envs.includes("prod")) {
        env = "Live";
      } else if (envs.includes("draft")) {
        env = "Draft";
      } else {
        env = "Sandbox";
      }
    }

    return env;
  }

  isPublishDisabled() {
    const {
      botData: { canPublish },
      isTrainingNeeded,
      solutionInfo,
      processing,
    } = this.props;

    return !canPublish || isTrainingNeeded || solutionInfo.error || processing;
  }

  async publishVersion(version, noCreateDraft = false) {
    await this.clearAndFetchInterfaces();

    let csvFileFromServerIntent;
    let csvFileFromServerEntity;
    let simplifiedTrainingData;
    this.setState({ isValidatingBeforePublish: true });
    const csvFileFromServerBot = await this.getFileFromServer(
      "templates/bot.csv"
    );

    const formData = new FormData();
    const blobCsv = new Blob([csvFileFromServerBot], { type: "text/xml" });
    formData.append("templateFile", blobCsv, "bot.csv");
    if (
      this.props.botData.latestVersion.compilerVersion ===
        BOT_VERSION_FLAGS.NLU_ENABLED ||
      this.props.botData.latestVersion.compilerVersion ===
        BOT_VERSION_FLAGS.NLU_ROUTING
    ) {
      csvFileFromServerIntent = await this.getFileFromServer(
        "templates/intent.csv"
      );

      csvFileFromServerEntity = await this.getFileFromServer(
        "templates/entity.csv"
      );
      simplifiedTrainingData = getIntentFormat(
        csvFileFromServerIntent,
        NLU_INTENT_HEADERS_H,
        NLU_INTENT_HEADERS_SIMPLIFIED_H
      );
      const blobIntent = new Blob([csvFileFromServerIntent], {
        type: "text/xml",
      });
      const blobEntity = new Blob([csvFileFromServerEntity], {
        type: "text/xml",
      });
      formData.append("trainingIntentFile", blobIntent, "intent.csv");
      formData.append("trainingEntityFile", blobEntity, "entity.csv");
      formData.append("simplifiedTrainingData", simplifiedTrainingData);
    }
    const { status, data } = await botManagerAPI.compileTemplate(
      formData,
      this.props.botData.latestVersion.compilerVersion
    ); // 5 = NLU_ENABLED
    let msg = "error";
    if (status === 200) {
      this.setState({
        showPublishModal: true,
        selectedVersion2Publish: version,
        noCreateDraft,
      });
    } else {
      if (typeof data === "object") {
        const errors = JSON.stringify(data.errors);
        msg += " " + errors;
      } else if (typeof data === "string") {
        msg = data;
      }
      this.props.setNotification({
        openNotification: true,
        notificationDuration: 3000,
        notificationTitle: msg,
        notificationType: "error",
      });
    }
    this.setState({ isValidatingBeforePublish: false });
  }

  publishLatest() {
    this.publishVersion(this.props.botData.latestVersion.version);
  }

  async getFileFromServer(filename) {
    return new Promise((resolve) => {
      botManagerAPI
        .downloadTemplateFile(this.props.botData.latestVersion.id, filename)
        .then((response) => {
          resolve(response.file_data);
        });
    });
  }

  async closePublishModal() {
    await this.props.updatePypes([]);
    this.setState({
      showPublishModal: false,
      pypes: [],
    });
  }

  initBot() {
    this.props.initBot();
  }

  async clearAndFetchInterfaces() {
    await this.props.removeInterfaces();
    botManagerAPI.getPypes(this.props.customer.gesId, "live").then((pypes) => {
      this.setState({
        allPypes: [...this.state.allPypes, ...pypes],
      });
      pypes.forEach(async (pype) => {
        await this.props.getInterfaces(pype.id, "live", true);
        botManagerAPI
          .getPypeStream(pype.customer_id, "live", pype.id)
          .then((newStream) =>
            this.setState({
              streams: [
                ...this.state.streams.filter(
                  (stream) => stream.id !== newStream.id
                ),
                ...newStream,
              ],
            })
          );
      });
    });

    botManagerAPI
      .getPypes(this.props.customer.gesId, "sandbox")
      .then((pypes) => {
        this.setState({
          allPypes: [...this.state.allPypes, ...pypes],
        });
        pypes.forEach(async (pype) => {
          await this.props.getInterfaces(pype.id, "sandbox", true);
          botManagerAPI
            .getPypeStream(pype.customer_id, "sandbox", pype.id)
            .then((newStream) => {
              this.setState({
                streams: [
                  ...this.state.streams.filter(
                    (stream) => stream.id !== newStream.id
                  ),
                  ...newStream,
                ],
              });
            });
        });
      });
  }

  async getPypeStreamInSolutions(env, selectedSolutions) {
    const { customer, customerId } = this.props;
    let res = [];

    try {
      const pypes = await botManagerAPI.getPypes(customer.gesId, env);
      const filteredPypes =
        pypes && pypes.filter((p) => p.customer_id === customer.gesId);

      let requestsForStreams = filteredPypes.map((pype) =>
        botManagerAPI.getPypeStream(customer.gesId, env, pype.id)
      );

      const resStreams = await Promise.all(requestsForStreams);

      resStreams.forEach((streams, index) => {
        res = [
          ...res,
          ...(streams || [])
            .filter((st) => !st.stream_name.includes("preview_stream"))
            .filter((stream) =>
              selectedSolutions.some(
                (sln) => stream.start_chat_bot_id === `${customerId}.${sln}`
              )
            )
            .map((st) => ({
              ...filteredPypes[index],
              stream_id: st.id,
              stream_name: st.stream_name,
              auto_start_with_bot: st.auto_start_with_bot,
              bot_enabled: st.bot_enabled,
              end_chat_bot_enabled: st.end_chat_bot_enabled,
              end_chat_bot_id: st.end_chat_bot_id,
              start_chat_bot_enabled: st.start_chat_bot_enabled,
              start_chat_bot_id: st.start_chat_bot_id,
              channel: st.auto_start_with_bot ? "web" : "facebook",
            })),
        ];
      });
    } catch (err) {
      console.log(err);
    }

    return res;
  }

  async onSimplePublish(botType, env, selectedSolutions) {
    this.setState({ versionLoading: true });

    const { customerId, botId, flags, customer } = this.props;
    const { selectedVersion2Publish, compilerVersionDetail, noCreateDraft } =
      this.state;
    const version = `${customerId}.${botId}.${selectedVersion2Publish}`;
    const environment = JSON.stringify({
      environment: env,
    });
    this.props.setPypeLoading(true);
    const user_id =
      this.props.botUserSession &&
      this.props.botUserSession.user &&
      this.props.botUserSession.user.user_id;
    if (typeof user_id !== "string" || !user_id) {
      throw Error(
        `User ID is not the correct type or is not valid: { Type: ${typeof user_id}, value: ${user_id} }`
      );
    }

    let csvFileFromServerIntent;
    let csvFileFromServerEntity;

    try {
      // 1. Update bot config.
      if (compilerVersionDetail === BOT_VERSION_FLAGS.NLU_ENABLED) {
        csvFileFromServerIntent = await this.getFileFromServer(
          "templates/intent.csv"
        );
        await this.props.updateBot(this.props.botData.bot.id, {
          botLanguage: this.props.botData.bot.botLanguage,
          botType,
          simplifiedTrainingData: getIntentFormat(
            csvFileFromServerIntent,
            NLU_INTENT_HEADERS_H,
            NLU_INTENT_HEADERS_SIMPLIFIED_H
          ),
        });

        csvFileFromServerEntity = await this.getFileFromServer(
          "templates/entity.csv"
        );
      } else {
        await this.props.updateBot(this.props.botData.bot.id, {
          botLanguage: this.props.botData.bot.botLanguage,
          botType,
        });
      }
      // 2. Update bot streams in case of survey
      // get pype/streams of selected solutions
      if (botType === "survey") {
        const selectedPypeStreams = await this.getPypeStreamInSolutions(
          env,
          selectedSolutions
        );
        if (!isEmpty(selectedPypeStreams)) {
          let requests = selectedPypeStreams.map((sp) => {
            return this.props.updateStream(
              customer.gesId,
              env,
              sp.id,
              sp.stream_id,
              `${customerId}.${botId}`,
              !!sp.auto_start_with_bot,
              sp.start_chat_bot_id,
              sp.end_chat_bot_id,
              "end_chat_bot_id"
            );
          });
          await Promise.all(requests);
        }
      }
      // 3. Deploy Version
      const csvFileFromServerBot = await this.getFileFromServer(
        "templates/bot.csv"
      );
      const blob = new Blob([csvFileFromServerBot], { type: "text/xml" });
      const formData = new FormData();
      formData.append("templateFile", blob, "bot.csv");

      if (
        csvFileFromServerIntent !== undefined &&
        csvFileFromServerEntity !== undefined
      ) {
        const intentBlob = new Blob([csvFileFromServerIntent], {
          type: "text/xml",
        });
        const entityBlob = new Blob([csvFileFromServerEntity], {
          type: "text/xml",
        });

        formData.append("trainingIntentFile", intentBlob, "intent.csv");
        formData.append("trainingEntityFile", entityBlob, "entity.csv");
        formData.append(
          "simplifiedTrainingData",
          getIntentFormat(
            csvFileFromServerIntent,
            NLU_INTENT_HEADERS_H,
            NLU_INTENT_HEADERS_SIMPLIFIED_H
          )
        );
      }
      const deployStatus = await botManagerAPI.deployVersion(
        version,
        environment
      );

      if (deployStatus === 200) {
        // 4. Automatically create new draft
        if (!noCreateDraft && this.props.flags.pe22301) {
          await this.uploadDefaultNLUData(customerId, botId);
        }
        // 5. Create interfaces
        // no need to create interfaces anymore

        if (flags.pe17497) {
          await this.props.initBot();
        }

        this.props.setNotification({
          openNotification: true,
          notificationDuration: 5000,
          notificationTitle: `You have published ${selectedVersion2Publish} of ${this.props.botId} solution!`,
          notificationType: "success",
        });

        activityTracker.logEvent(activityTracker.eventTypeNames.PUBLISH, {
          targetEnv: env,
          solutionName: `${customerId}.${botId}`,
          success: true,
          solutionVersion: selectedVersion2Publish,
        });
      } else {
        this.props.setNotification({
          openNotification: true,
          notificationDuration: 3000,
          notificationTitle: `${deployStatus.errors}`,
          notificationType: "error",
        });
        activityTracker.logEvent(activityTracker.eventTypeNames.PUBLISH, {
          targetEnv: env,
          solutionName: `${customerId}.${botId}`,
          success: false,
        });
      }
    } catch (err) {
      this.props.setNotification({
        openNotification: true,
        notificationDuration: 3000,
        notificationTitle: `Something went wrong ${err || ""}`,
        notificationType: "error",
      });
    } finally {
      this.setState({ versionLoading: false });
      this.props.setPypeLoading(false);
      this.closePublishModal();

      if (flags.pe21656) {
        this.redirectToManageTab();
      }
    }
  }

  async uploadDefaultNLUData(customerId, botId) {
    // create draft version
    await botManagerAPI.createNewBotVersion(`${customerId}.${botId}`, null);
    const res = await this.props.initBot(BOT_VERSION_FLAGS.NLU_ENABLED);
    const draftVersion = res.botData.latestVersion.id;
    console.log("default templates are successfully uploaded.");
    // train data
    await this.props.trainBot(draftVersion);
  }

  handleKeyDown(e) {
    const key = e.key;
    this.setState(
      (prevState) => {
        return {
          keysPressed: [...prevState.keysPressed, key],
        };
      },
      () => {
        const { keysPressed } = this.state;
        const { flags } = this.props;
        const keyComboPressed =
          keysPressed.includes("Shift") &&
          (keysPressed.includes("Control") || keysPressed.includes("Meta"));

        if (keyComboPressed) {
          if (
            flags["pe17357"] &&
            (keysPressed.includes("u") || keysPressed.includes("U"))
          ) {
            this.props.trainBot(this.props.botData.latestVersion.id);
          } else if (keysPressed.includes("e") || keysPressed.includes("E")) {
            const jwt = window.sessionStorage.getItem("global_accessToken");
            navigator.clipboard
              .writeText(jwt)
              .then(() => {
                activityTracker.logEvent(
                  activityTracker.eventTypeNames.COPY_JWT
                );
                this.props.setNotification({
                  openNotification: true,
                  notificationDuration: 5000,
                  notificationTitle:
                    "JWT token has been copied to your clipboard",
                  notificationType: "success",
                });
              })
              .catch((e) => console.error(e));
          }
        }
      }
    );
  }
  setPreviewData() {
    const { customerId, botId } = this.props;

    this.props.setPreviewModalOpen(true, {
      variant: "temporary",
      customerId,
      botId,
    });
  }

  handleKeyUp() {
    this.setState({
      keysPressed: [],
    });
  }

  render() {
    const {
      customer,
      isTrainingNeeded,
      solutionInfo,
      botData,
      flags,
      customerId,
      botId,
    } = this.props;

    const {
      isValidatingBeforePublish,
      versionLoading,
      selectedSolutionsForPublish,
      showPreviewPublishOptions,
    } = this.state;

    if (!customer || !botData || !botData.bot) return <Loader />;

    const { currentVersions, publishMsg, latestVersion } = botData;
    const lv =
      currentVersions && currentVersions.length > 0
        ? currentVersions[currentVersions.length - 1]
        : null;

    const customerGesId = customer.gesId;

    if (
      !isPermitted(PermissionResource.BOT, PermissionAction.READ, customerGesId)
    ) {
      return <Redirect to="/unauthorized" />;
    }

    const popoverErrors = () => {
      return isTrainingNeeded
        ? "Solution version has not been trained"
        : solutionInfo.error
        ? solutionInfo.description
        : publishMsg;
    };

    return (
      <div
        style={{ display: "contains" }}
        onKeyDown={this.handleKeyDown}
        onKeyUp={this.handleKeyUp}
      >
        <Stack
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          size="xsmall"
        >
          {!flags["pe21811"] && (
            <Stack.Item
              flexBasis="auto"
              flexGrow={0}
              flexShrink={1}
              zeroMinWidth={false}
            >
              <UnpublishedChangesBadge
                botId={this.props.botId}
                customerId={this.props.customerId}
              />
            </Stack.Item>
          )}
          {(!flags.pe21135 || showPreviewPublishOptions) && (
            <>
              <Stack.Item>
                <PreviewButton
                  processing={this.props.processing}
                  customerId={customerId}
                  botId={botId}
                />
              </Stack.Item>
              <Stack.Item>
                <div className="flex items-center">
                  {flags.pe21811 ? (
                    <PublishMicroAppButton
                      {...(isValidatingBeforePublish
                        ? { isLoading: true }
                        : {})}
                      disabled={this.isPublishDisabled()}
                      version={this.state.selectedVersion2Publish}
                      botType={this.props.botData.bot.botType}
                      envs={ENVS}
                      bots={this.props.bots}
                      latestVersion={lv ? lv.version : ""}
                      customerId={customerId}
                      botId={botId}
                      versionLoading={versionLoading}
                      selectedSolutionsForPublish={selectedSolutionsForPublish}
                      onCancel={this.closePublishModal}
                      publishVersion={this.publishVersion}
                      open={this.state.showPublishModal}
                      onPublish={this.onSimplePublish}
                      onSelectedSolutions={(slns) => {
                        this.setState({ selectedSolutionsForPublish: slns });
                      }}
                    />
                  ) : (
                    <Button
                      {...(isValidatingBeforePublish
                        ? { isLoading: true }
                        : {})}
                      disabled={this.isPublishDisabled()}
                      onClick={this.publishLatest}
                    >
                      Publish
                    </Button>
                  )}
                  {this.isPublishDisabled() && (
                    <span className="inline-block mr-1.5">
                      <Tooltip label={popoverErrors()} placement="bottom">
                        <div>
                          <img
                            className="h-4"
                            src={RedExclamationIcon}
                            alt="Exclamation Icon"
                          />
                          <span style={{ color: "#e67b88" }}></span>
                        </div>
                      </Tooltip>
                    </span>
                  )}
                </div>
              </Stack.Item>
            </>
          )}
        </Stack>
        <Helmet>
          <title>{`${this.getBotName()} - ${customer.id}`}</title>
        </Helmet>
        <React.Suspense fallback={<Loader />}>
          {!flags.pe21811 ? (
            <SimplePublishSlnModalLazy
              show={this.state.showPublishModal}
              version={this.state.selectedVersion2Publish}
              botType={this.props.botData.bot.botType}
              envs={ENVS}
              bots={this.props.bots}
              latestVersion={lv ? lv.version : ""}
              versionLoading={versionLoading}
              selectedSolutionsForPublish={selectedSolutionsForPublish}
              onCancel={this.closePublishModal}
              onPublish={this.onSimplePublish}
              onSelectedSolutions={(slns) => {
                this.setState({ selectedSolutionsForPublish: slns });
              }}
            />
          ) : null}
        </React.Suspense>
        <div
          onKeyDown={this.handleKeyDown}
          onKeyUp={this.handleKeyUp}
          tabIndex={0}
          style={{ outline: "none", display: "none" }}
        >
          <div className="flex flex-row justify-between my-3.5">
            <div className="flex items-center">
              <span className="font-semibold text-base">
                {this.getVersionEnvironmentLatest(
                  currentVersions,
                  latestVersion
                )}
              </span>
              <span
                style={{
                  color: backgroundGrey600,
                }}
                className="ml-2 text-base"
              >
                {latestVersion ? latestVersion.version : null}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let processing = state.sampleNode.nodeInfo.processing;
  let bots = [];
  const { customerId, botId } = ownProps;
  const customer = state.manager.customers[customerId];
  if (customer && customer.bots) {
    bots = customer.bots
      .sort()
      .map((bot) => ({ name: bot.substr(customerId.length + 1) }));
  }
  return {
    customer,
    customerId,
    botId,
    bots,
    processing,
    pypes: state.manager.pypes,
    streams: state.manager.streams,
    botData: customer ? customer.botsData[botId] : null,
    botUserSession: state.botUserSession,
    interfaces: state.manager.interfaces,
    isTrainingNeeded: state.manager.isTrainingNeeded,
    solutionInfo: state.manager.solutionInfo,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getCustomer(customerId) {
    dispatch(getCustomersIfNeeded());
    return dispatch(getCustomer(customerId));
  },
  getPypes(customerId, versionId) {
    dispatch(getPypes(customerId, versionId));
  },
  updateStream(
    customerId,
    versionId,
    pypeId,
    streamId,
    botId,
    value,
    startChatId,
    endChatId,
    solution
  ) {
    dispatch(
      updateStream(
        customerId,
        versionId,
        pypeId,
        streamId,
        botId,
        value,
        startChatId,
        endChatId,
        solution
      )
    );
  },
  initBot(compilerVersion) {
    const { customerId, botId } = ownProps;
    return dispatch(initBot(customerId, botId, compilerVersion));
  },
  updatePypes(pypes) {
    dispatch(updatePypes(pypes));
  },
  setPypeLoading(value) {
    dispatch(setPypeLoading(value));
  },
  updateBot(botId, data) {
    return dispatch(updateBot(botId, data));
  },
  removeInterfaces: () => dispatch(removeInterfaces()),
  getInterfaces: (pypeId, env, interfacesAreEnabled) =>
    dispatch(getInterfaces(pypeId, env, interfacesAreEnabled)),
  trainBot(versionId) {
    dispatch(trainBot(versionId));
  },
  setNotification: (data) => dispatch(setNotification(data)),
  validateSolution: (botId, version, autoTrain) =>
    dispatch(validateSolution(botId, version, autoTrain)),
  setPreviewModalOpen: (open, config) =>
    dispatch(setPreviewModalOpen(open, config)),
});

const PreviewPublishNavContainer = withLDConsumer()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(PreviewPublishNav))
);

PreviewPublishNav.propTypes = {
  flags: PropTypes.object,
  botData: PropTypes.object,
  botUserSession: PropTypes.object,
  pypes: PropTypes.array,
  streams: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  bots: PropTypes.array,
  interfaces: PropTypes.array,
  isTrainingNeeded: PropTypes.bool,
  customer: PropTypes.objectOf(PropTypes.any),
  customerId: PropTypes.string.isRequired,
  getCustomer: PropTypes.func.isRequired,
  botId: PropTypes.string.isRequired,
  initBot: PropTypes.func,
  updatePypes: PropTypes.func,
  getPypes: PropTypes.func,
  updateBot: PropTypes.func,
  removeInterfaces: PropTypes.func,
  getInterfaces: PropTypes.func,
  setPypeLoading: PropTypes.func,
  updateStream: PropTypes.func,
  solutionInfo: PropTypes.object,
  trainBot: PropTypes.func,
  setNotification: PropTypes.func,
  validateSolution: PropTypes.func,
  setPreviewModalOpen: PropTypes.func,
  processing: PropTypes.bool,
};

PreviewPublishNavContainer.propTypes = {
  customerId: PropTypes.string.isRequired,
  botId: PropTypes.string.isRequired,
};

PreviewPublishNavContainer.defaultProps = {};

export default PreviewPublishNavContainer;
