import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { EditHeader } from "@pypestream/ui-elements";

import PrivateRoute from "./PrivateRoute";
import Header from "./Header";

const ManagerRoute = ({
  isAuthenticated,
  authenticationError,
  fullWidth,
  ...rest
}) => (
  <div>
    <Helmet titleTemplate="Pypestream">
      <title />
    </Helmet>
    {window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ] === "createNewInterface" ? (
      <EditHeader title="Create Interface" />
    ) : window.location.pathname.split("/")[
        window.location.pathname.split("/").length - 2
      ] === "editInterface" ? (
      <EditHeader title="Interface Design" />
    ) : // <div className="top-0 sticky bg-white z-10">
    // </div> // Will Implement Later
    window.location.pathname.split("/")[
        window.location.pathname.split("/").length - 2
      ] === "editAction" ? (
      <EditHeader title="Edit Action" />
    ) : (
      <Header
        showProfile={!authenticationError}
        computedMatch={rest.computedMatch}
      />
    )}
    {fullWidth ? (
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        {...rest}
        authenticationError={authenticationError}
      />
    ) : (
      <div className="manager" id="container">
        <div className="routeContainer">
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            {...rest}
            authenticationError={authenticationError}
          />
        </div>
      </div>
    )}
  </div>
);

ManagerRoute.propTypes = {
  isAuthenticated: PropTypes.bool,
  authenticationError: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

export default ManagerRoute;
