import config from "../config";

export const isJSONString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }

  return true;
};

export const getFisherCatLink = ({
  widgetId,
  customerId,
  botId,
  env,
  streamId,
  pypeId,
  isPreviewAvailable = true,
}) => {
  const preview = isPreviewAvailable ? `preview=${customerId}.${botId}&` : "";

  const link = `${config.fisherCatVercelLink}/${widgetId}?${preview}env=${env}&streamId=${streamId}&pypeId=${pypeId}`;

  return link;
};
