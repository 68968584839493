import { IconNames } from "@pypestream/ui-elements";

const NUMBER = 1;
const TEXT_OR_NUMBER = 2;
const NO_NEED = 3;

export default [
  {
    label: "Contains",
    value: "contains",
    icon: "",
    menu: "Contains...",
    placeholder: "Text or Number",
    inputType: TEXT_OR_NUMBER,
    disabled: true,
  },
  {
    label: "Exists with any value",
    value: "exists_with_any_value",
    icon: "",
    menu: "Exists with any value",
    placeholder: "",
    inputType: NO_NEED,
    border: true,
    disabled: true,
  },
  {
    label: "",
    value: "equal",
    icon: IconNames.Equals,
    menu: "Equals",
    placeholder: "Text or Number",
    inputType: TEXT_OR_NUMBER,
  },
  {
    label: "",
    value: "not-equal",
    icon: IconNames.NotEqual,
    menu: "Not Equal",
    placeholder: "Text or Number",
    inputType: TEXT_OR_NUMBER,
    border: true,
    disabled: true,
  },
  {
    label: "",
    value: "greater_than",
    icon: IconNames.Greater,
    menu: "Greater Than",
    placeholder: "Number",
    inputType: NUMBER,
    disabled: true,
  },
  {
    label: "",
    value: "greater_equal",
    icon: IconNames.GreaterOrEqual,
    menu: "Greater Than or Equals",
    placeholder: "Number",
    inputType: NUMBER,
    disabled: true,
  },
  {
    label: "",
    value: "less_than",
    icon: IconNames.Less,
    menu: "Less Than",
    placeholder: "Number",
    inputType: NUMBER,
    disabled: true,
  },
  {
    label: "",
    value: "less_equal",
    icon: IconNames.LessOrEqual,
    menu: "Less Than or Equals",
    placeholder: "Number",
    inputType: NUMBER,
    disabled: true,
  },
];
