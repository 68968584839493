export default {
  TYPE: {
    DECISION: "decision",
    ACTION: "action",
    ORPHAN: "orphan",
    TOP_PARENT: "top_parent",
    PLUS: "plus",
    UNKNOWN: "unknown",
  },
  STACK_TYPE: {
    NONE: -1,
    ALL_DECISION_NODES: 0,
    ALL_ACTION_NODES: 1,
    MIX_OF_DECISION_ACTION: 2,
    ERRORS_IN_STACK: 3,
  },
  MODE: {
    NORMAL: "normal",
    GROUP: "group",
  },
  MENUS: {
    COPY_NODE: {
      label: "Copy Node",
      value: "copy-node",
    },
    COPY_MESSAGE: {
      label: "Copy Message",
      value: "copy-node",
    },
    COPY_BRANCH: {
      label: "Copy Branch",
      value: "copy-branch",
    },
    CUT_NODE: {
      label: "Cut Node",
      value: "cut-node",
    },
    CUT_MESSAGE: {
      label: "Cut Message",
      value: "cut-node",
    },
    CUT_BRANCH: {
      label: "Cut Branch",
      value: "cut-branch",
    },
    PASTE_NODE: {
      label: "Paste Node",
      value: "paste-node",
    },
    PASTE_MESSAGE: {
      label: "Paste Message",
      value: "paste-node",
    },
    PASTE_BRANCH: {
      label: "Paste Branch",
      value: "paste-branch",
    },
    DELETE_NODE: {
      label: "Delete Node",
      value: "delete-node",
    },
    DELETE_MESSAGE: {
      label: "Delete Message",
      value: "delete-node",
    },
    DELETE_BRANCH: {
      label: "Delete Branch",
      value: "delete-branch",
    },
    DELETE_ACTION: {
      label: "Delete Action",
      value: "delete-node",
    },
    COLLAPSE_BRANCH: {
      label: "Collapse Branch",
      value: "collapse-branch",
    },
    EXPAND_BRANCH: {
      label: "Expand Branch",
      value: "expand-branch",
    },
    SELECT_BRANCH: {
      label: "Select Branch",
      value: "select-branch",
    },
    CREATE_NODE: {
      label: "Create Node",
      value: "create-node",
    },
  },
  BUBBLE_TYPES: {
    TEXT: "text",
    MEDIA: "image",
    VIDEO: "video",
    FILE: "document",
    PAUSE: "pause",
    WEBSITE: "webview",
    DELETED: "deleted",
  },
  EMBED_TYPES: {
    BUTTONS: "Buttons",
    LIST_PICKER: "List Picker",
    CAROUSEL: "Carousel",
    NONE: "None",
    DATE_PICKER: "Date Picker",
    TIME_PICKER: "Time Picker",
    AUTHN: "AuthN",
    UPLOAD: "Upload",
    UNKNOWN: "Unknown",
    OTHER: "Other",
  },
};
