import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import config from "./config";

const LDSetup = async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: config.launchDarklyClientId,
    user: {
      key: `unauthenticated-studio-user-${window.location.hostname}`,
      //prettier-ignore
      custom: {
          "domain": window.location.href,
        },
    },
    options: {
      /* ... */
    },
  });
  return LDProvider;
};
export default LDSetup;
