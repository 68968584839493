import botManagerAPI from "bot-manager-api";
import { isEmpty } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";

export const useHome = ({ userId, history }) => {
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [microApps, setMicroApps] = useState([]);
  const [showCreateMicroAppModal, setShowCreateMicroAppModal] = useState(false);
  const [selectedMicroApp, setSelectedMicroApp] = useState({
    id: "",
    customerName: "",
    botName: "",
  });
  const [openDeleteMicroAppModal, setOpenDeleteMicroAppModal] = useState(false);
  const [openTemplateModal, setShowTemplateModal] = useState(false);
  const selectedTemplate = "Lead Capture Template";

  const { pe20999, pe22382 } = useFlags() || {};

  //hardcoded docs for now
  // const [sortedDocumentation, setSortedDocumentation] = useState([]);

  useEffect(() => {
    const fetchCustomers = async () => {
      if (userId) {
        const res = await botManagerAPI.getCustomers(userId);
        setCustomers(res);
      }
    };
    fetchCustomers();
  }, [userId]);

  const handleSelectCustomer = useCallback((customer) => {
    setMicroApps([]);
    setSelectedCustomer(customer);
    setIsLoading(true);
  }, []);

  const createMicroApp = () => {
    setShowCreateMicroAppModal(true);
  };

  const handleTemplateModal = (open) => {
    setShowTemplateModal(open);
  };

  const closeCreateMicroAppModal = (close) => {
    setShowCreateMicroAppModal(close);
  };

  const onClickMicroApp = (microApp) => {
    if (!openDeleteMicroAppModal) {
      const link = `/bot/${selectedCustomer}/${microApp.bot.split(".").pop()}${
        pe20999 ? "/manage" : ""
      }`;

      history.push(link);
    }
  };

  function formatMicroAppDate(publishedDate) {
    return publishedDate.toLocaleDateString(undefined, {
      weekday: "long",
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  }

  const createMicroAppItem = useCallback((botName, versionData) => {
    const unpublished = "unpublished";
    const latestVersionEnv = versionData?.env ?? unpublished;
    const latestVersionTime = versionData?.time ?? unpublished;
    const publishedDate = new Date(latestVersionTime);
    const timeAgo = moment(publishedDate).local().fromNow();

    return {
      bot: botName,
      env: latestVersionEnv,
      publishedDate: formatMicroAppDate(publishedDate),
      timeAgo,
    };
  }, []);

  const setMicroappsForLoop = useCallback(
    async (res) => {
      /*
     Iterate through the list of bots.  For each one, get the latest version from the history API
     and update the microApps state.
     */
      for (let i = 0; i < res.bots.length; i++) {
        const bot = res.bots[i];
        await botManagerAPI
          .getBotHistory(selectedCustomer, bot.split(".").pop())
          .then((history) => {
            const latestVersion = history?.data?.data[0];
            const newMicroappItem = createMicroAppItem(bot, latestVersion);
            setMicroApps((prevState) => [...prevState, newMicroappItem]);
          });
      }
    },
    [createMicroAppItem, selectedCustomer]
  );

  const setMicroappsBatch = useCallback(
    async (res) => {
      /*
    Fetch all bot history for a customer in one request, and then update microApps state only once.
    */
      await botManagerAPI.getBotHistoryAll(selectedCustomer).then((history) => {
        // build a lookup table mapping bot name to latest version
        const latestVersions = {};
        history?.data?.data.forEach((versionData) => {
          const botName = versionData.bot.split(".").pop();
          const versionTime = new Date(versionData.time);
          if (
            !latestVersions[botName] ||
            versionTime > new Date(latestVersions[botName].time)
          ) {
            latestVersions[botName] = versionData;
          }
        });

        // create list of solution items from bots in res.bots
        const newMicroappItems = res.bots.map((bot) => {
          const botName = bot.split(".").pop();
          return createMicroAppItem(botName, latestVersions[botName]);
        });

        setMicroApps(newMicroappItems);
      });
    },
    [createMicroAppItem, selectedCustomer]
  );

  useEffect(() => {
    if (
      !isEmpty(selectedCustomer) &&
      selectedCustomer !== undefined &&
      selectedCustomer !== ""
    ) {
      const fetchMicroApps = async () => {
        await botManagerAPI.getCustomer(selectedCustomer).then(async (res) => {
          if (!res.errors) {
            if (res.bots) {
              if (pe22382) {
                await setMicroappsBatch(res);
              } else {
                await setMicroappsForLoop(res);
              }
            }
          } else {
            //Error case
            setIsLoading(false);
          }
        });
        setIsLoading(false);
      };
      fetchMicroApps();
    }
  }, [pe22382, selectedCustomer, setMicroappsBatch, setMicroappsForLoop]);
  const isDeleted = (isDeleted) => {
    setOpenDeleteMicroAppModal(false);
    openDeleteModal(selectedMicroApp.id, false, isDeleted);
  };

  const openDeleteModal = (bot, open, isDeleted) => {
    setOpenDeleteMicroAppModal(true);
    if (bot.length >= 1) {
      setSelectedMicroApp({
        id: bot,
        customerName: selectedCustomer,
        botName: bot?.split(".")?.pop(),
      });
    }
    if (open === false) {
      setOpenDeleteMicroAppModal(false);
    }

    if (isDeleted) {
      setMicroApps((prevState) =>
        prevState.filter((microApp) => microApp.bot !== bot)
      );
    }
  };

  return {
    selectedCustomer,
    customers,
    microApps,
    isLoading,
    handleSelectCustomer,
    showCreateMicroAppModal,
    createMicroApp,
    closeCreateMicroAppModal,
    onClickMicroApp,
    openDeleteMicroAppModal,
    openDeleteModal,
    isDeleted,
    selectedMicroApp,
    openTemplateModal,
    handleTemplateModal,
    selectedTemplate,
  };
};
