import React from "react";

export default {
  DELETE: (
    <g>
      <circle cx="12" cy="12" r="12" fill="#FFF" />
      <path
        fill="#BBB"
        d="M15.628 20c.757 0 1.426-.612 1.494-1.366L18.159 7h.591c.138 0 .25-.112.25-.25v-.5c0-.138-.112-.25-.25-.25H15.5l-1.05-1.4c-.248-.331-.786-.6-1.2-.6h-2.5c-.414 0-.952.269-1.2.6L8.5 6H5.25c-.138 0-.25.112-.25.25v.5c0 .138.112.25.25.25h.59l1.038 11.634c.068.754.737 1.366 1.494 1.366h7.256zM14.25 6h-4.5l.6-.8c.083-.11.262-.2.4-.2h2.5c.138 0 .317.09.4.2l.6.8zm1.38 13H8.37c-.252 0-.474-.204-.495-.456L6.844 7h10.312l-1.031 11.544c-.02.252-.243.456-.496.456z"
      />
    </g>
  ),
  TEXT: (
    <g>
      <circle cx="12" cy="12" r="12" fill="#FFF" />
      <path
        fill="#0A3AB4"
        d="M14 17H4v2h10v-2zm6-8H4v2h16V9zM4 15h16v-2H4v2zM4 5v2h16V5H4z"
      />
    </g>
  ),
  MEDIA: (
    <g>
      <circle cx="12" cy="12" r="12" fill="#FFF" />
      <path
        fill="#0A3AB4"
        d="M18.5 18c.828 0 1.5-.672 1.5-1.5v-9c0-.828-.672-1.5-1.5-1.5h-13C4.672 6 4 6.672 4 7.5v9c0 .828.672 1.5 1.5 1.5h13zm0-1h-13c-.276 0-.5-.224-.5-.5v-9c0-.276.224-.5.5-.5h13c.276 0 .5.224.5.5v9c0 .276-.224.5-.5.5zm-11-5.75c.966 0 1.75-.784 1.75-1.75S8.466 7.75 7.5 7.75s-1.75.784-1.75 1.75.784 1.75 1.75 1.75zm0-1c-.414 0-.75-.336-.75-.75s.336-.75.75-.75.75.336.75.75-.336.75-.75.75zM17.625 16c.207 0 .375-.168.375-.375V12.75c0-.199-.08-.39-.22-.53l-2.75-2.75c-.293-.293-.767-.293-1.06 0L11 12.44l-.97-.97c-.293-.293-.767-.293-1.06 0l-2.75 2.75c-.122.12-.22.359-.22.53v.875c0 .207.168.375.375.375h11.25zM17 15H7v-.146l2.5-2.5 1.5 1.5 3.5-3.5 2.5 2.5V15z"
      />
    </g>
  ),
  PAPERCLIP: (
    <g>
      <circle cx="12" cy="12" r="12" fill="#FFF" />
      <path
        fill="#0A3AB4"
        d="M8.535 20c1.033 0 2.067-.393 2.854-1.18l6.804-6.805c.147-.146.147-.384 0-.53l-.177-.177c-.146-.146-.384-.146-.53 0l-6.805 6.804c-1.183 1.184-3.11 1.184-4.293 0-1.184-1.183-1.184-3.11 0-4.293l8.19-8.19c.838-.839 2.204-.84 3.043 0s.839 2.204 0 3.044l-6.957 6.957c-.495.494-1.3.494-1.794 0-.494-.495-.494-1.3 0-1.794l5.322-5.321c.147-.147.147-.384 0-.53l-.177-.178c-.146-.146-.383-.146-.53 0L8.163 13.13c-.884.884-.884 2.323 0 3.208.885.884 2.324.884 3.208 0l6.957-6.957c1.23-1.23 1.23-3.23 0-4.458-1.229-1.23-3.229-1.23-4.458 0l-8.19 8.19c-1.573 1.573-1.573 4.134 0 5.708.787.787 1.82 1.18 2.855 1.18z"
      />
    </g>
  ),
  WEBSITE: (
    <g>
      <circle cx="12" cy="12" r="12" fill="#FFF" />
      <path
        fill="#0A3AB4"
        d="M18.5 19c.828 0 1.5-.672 1.5-1.5v-11c0-.828-.672-1.5-1.5-1.5h-13C4.672 5 4 5.672 4 6.5v11c0 .828.672 1.5 1.5 1.5h13zM7 8H5V6.5c0-.275.225-.5.5-.5H7v2zm12 0H8V6h10.5c.275 0 .5.225.5.5V8zm-.5 10h-13c-.275 0-.5-.225-.5-.5V9h14v8.5c0 .275-.225.5-.5.5z"
      />
    </g>
  ),
  PAUSE: (
    <g>
      <circle cx="12" cy="12" r="12" fill="#FFF" />
      <path
        fill="#0A3AB4"
        d="M12 19.75c4.281 0 7.75-3.469 7.75-7.75 0-4.281-3.469-7.75-7.75-7.75-4.281 0-7.75 3.469-7.75 7.75 0 4.281 3.469 7.75 7.75 7.75zm0-1c-3.71 0-6.75-3.003-6.75-6.75 0-3.71 3.003-6.75 6.75-6.75 3.71 0 6.75 3.003 6.75 6.75 0 3.71-3.003 6.75-6.75 6.75zM10.812 15c.104 0 .188-.084.188-.188V9.187c0-.103-.084-.187-.188-.187h-.625c-.103 0-.187.084-.187.187v5.625c0 .104.084.188.187.188h.625zm3 0c.104 0 .188-.084.188-.188V9.187c0-.103-.084-.187-.188-.187h-.625c-.103 0-.187.084-.187.187v5.625c0 .104.084.188.187.188h.625z"
      />
    </g>
  ),
  EDIT: (
    <g>
      <circle cx="12" cy="12" r="12" fill="none" />
      <path
        fill="#0A3AB4"
        d="M4.833 19.995L8.4 19.6 19.414 8.586c.781-.781.781-2.048 0-2.829l-1.171-1.171c-.781-.781-2.048-.781-2.829 0L4.401 15.599l-.396 3.568c-.053.478.35.881.828.828zM17.146 9.44l-2.585-2.585 1.56-1.561c.391-.39 1.024-.391 1.414 0l1.172 1.171c.39.391.39 1.024 0 1.415l-1.56 1.56zm-7.709 7.71v-1.087h-1.5v-1.5H6.852l7.002-7.001 2.585 2.585-7.002 7.002zm-3.339 1.7l-.947-.948.205-1.843.62-.62h1.086v1.5h1.5v1.085l-.62.62-1.844.205z"
      />
    </g>
  ),
  VIEW: (
    <g>
      <circle cx="12" cy="12" r="12" fill="none" />
      <path
        fill="#0A3AB4"
        d="M12 18c3.841 0 7.197-2.239 8.891-5.544.06-.119.109-.323.109-.456s-.049-.338-.109-.456C19.196 8.237 15.841 6 12 6c-3.842 0-7.198 2.239-8.891 5.544-.06.119-.109.323-.109.456s.049.338.109.456C4.803 15.763 8.159 18 11.999 18zm0-3c-2.208 0-4-1.792-4-4 .002-2.206 1.794-3.998 4-4 2.208 0 4 1.792 4 4s-1.792 4-4 4zm0 2c-3.355 0-6.42-1.916-8-5 .755-1.481 2.572-3.292 4.056-4.042C7.403 8.803 7 9.85 7 11c0 2.76 2.24 5 5 5s5-2.24 5-5c0-1.15-.404-2.197-1.056-3.042 1.484.75 3.301 2.56 4.056 4.042-1.58 3.084-4.645 5-8 5zm0-4c1.104 0 2-.896 2-2s-.896-2-2-2c-.031 0-.059.008-.09.01.05.135.09.362.09.506 0 .82-.665 1.484-1.484 1.484-.144 0-.371-.04-.507-.09L10 11c0 1.104.896 2 2 2z"
      />
    </g>
  ),
  UPLOAD: (
    <g>
      <circle cx="12" cy="12" r="12" fill="none" />
      <path
        fill="#0A3AB4"
        d="M18 19c2.21 0 4-1.788 4-4 0-1.469-.806-2.838-2.134-3.538.087-.309.134-.63.134-.962C20 8.566 18.434 7 16.5 7c-.522 0-1.028.112-1.5.337C14.012 5.931 12.366 5 10.5 5c-2.95 0-5.366 2.328-5.494 5.256C3.225 10.881 2 12.572 2 14.5 2 16.987 4.013 19 6.5 19H18zm0-1H6.5C4.566 18 3 16.434 3 14.5c0-1.775 1.319-3.24 3.031-3.469C6.01 10.856 6 10.678 6 10.5 6 8.016 8.016 6 10.5 6c1.884 0 3.497 1.156 4.169 2.8.456-.49 1.106-.8 1.831-.8 1.381 0 2.5 1.119 2.5 2.5 0 .578-.197 1.112-.528 1.537C19.906 12.262 21 13.503 21 15c0 1.656-1.344 3-3 3zm-6.372-2c.206 0 .375-.169.375-.375v-4.71l2.131 2.132c.147.147.385.147.532 0l.178-.178c.147-.147.147-.385 0-.532l-3.078-3.071c-.147-.147-.385-.147-.532 0l-3.072 3.071c-.146.147-.146.385 0 .532l.179.178c.146.147.384.147.53 0l2.132-2.131v4.709c0 .206.169.375.375.375h.25z"
      />
    </g>
  ),
  MORE: (
    <g>
      <circle cx="12" cy="12" r="12" fill="none" />
      <path
        fill="#0A3AB4"
        d="M12 8.75c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5zm0 4.75c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5zm0 4.75c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5z"
      />
    </g>
  ),
  REDO: (
    <g>
      <circle cx="12" cy="12" r="12" fill="none" />
      <path
        fill="#0A3AB4"
        d="M12 19.75c1.998 0 3.819-.756 5.193-1.997.16-.144.167-.393.015-.545l-.22-.22c-.142-.142-.368-.148-.516-.015-1.184 1.066-2.751 1.714-4.472 1.714-3.68 0-6.687-2.979-6.687-6.687 0-3.68 2.979-6.687 6.687-6.687 2.59 0 4.836 1.47 5.947 3.624h-3.572c-.207 0-.375.168-.375.375v.313c0 .207.168.375.375.375h5c.207 0 .375-.168.375-.375v-5c0-.207-.168-.375-.375-.375h-.313c-.207 0-.375.168-.375.375v3.457c-1.35-2.3-3.85-3.84-6.712-3.832-4.262.013-7.732 3.5-7.725 7.762.007 4.275 3.474 7.738 7.75 7.738z"
      />
    </g>
  ),
  UNDO: (
    <g>
      <circle cx="12" cy="12" r="12" fill="none" />
      <path
        fill="#0A3AB4"
        d="M12 19.75c4.276 0 7.743-3.463 7.75-7.738.007-4.262-3.463-7.749-7.725-7.762-2.861-.009-5.363 1.533-6.713 3.832V4.625c0-.207-.167-.375-.375-.375h-.312c-.207 0-.375.168-.375.375v5c0 .207.168.375.375.375h5c.207 0 .375-.168.375-.375v-.313c0-.207-.168-.375-.375-.375H6.053C7.164 6.784 9.411 5.313 12 5.313c3.708 0 6.687 3.007 6.687 6.687 0 3.708-3.008 6.687-6.687 6.687-1.72 0-3.288-.648-4.472-1.714-.148-.133-.375-.127-.515.014l-.221.221c-.152.152-.145.4.015.545C8.18 18.994 10.002 19.75 12 19.75z"
      />
    </g>
  ),
  EQUALS: (
    <g>
      <circle cx="12" cy="12" r="12" fill="none" />
      <path
        fill="#0A3AB4"
        d="M17.75 10.5c.138 0 .25-.112.25-.25v-1c0-.138-.112-.25-.25-.25H6.25c-.138 0-.25.112-.25.25v1c0 .138.112.25.25.25h11.5zm0 4.5c.138 0 .25-.112.25-.25v-1c0-.138-.112-.25-.25-.25H6.25c-.138 0-.25.112-.25.25v1c0 .138.112.25.25.25h11.5z"
      />
    </g>
  ),
  GREATER_OR_EQUAL: (
    <g>
      <circle cx="12" cy="12" r="12" fill="none" />
      <path
        fill="#0A3AB4"
        d="M8.196 17c.033 0 .083-.012.112-.027l9.054-4.528c.076-.039.138-.139.138-.224v-.403c0-.095-.053-.18-.138-.223L8.308 7.026c-.123-.061-.273-.011-.335.112l-.447.895c-.014.029-.026.079-.026.111 0 .086.062.186.138.224L14.902 12l-7.264 3.632c-.076.038-.138.138-.138.223 0 .033.012.083.026.112l.447.894c.038.077.138.139.223.139zm10.054 3c.138 0 .25-.112.25-.25v-1c0-.138-.112-.25-.25-.25H6.75c-.138 0-.25.112-.25.25v1c0 .138.112.25.25.25h11.5z"
      />
    </g>
  ),
  GREATER: (
    <g>
      <circle cx="12" cy="12" r="12" fill="none" />
      <path
        fill="#0A3AB4"
        d="M8.308 16.973l9.054-4.528c.076-.039.138-.139.138-.224v-.403c0-.095-.053-.18-.137-.223L8.309 7.026c-.124-.061-.274-.011-.336.112l-.447.895c-.014.029-.026.079-.026.111 0 .086.062.186.138.224L14.902 12l-7.264 3.632c-.123.061-.173.212-.112.335l.447.894c.061.124.212.174.335.112z"
      />
    </g>
  ),
  LESS_OR_EQUAL: (
    <g>
      <circle cx="12" cy="12" r="12" fill="none" />
      <path
        fill="#0A3AB4"
        d="M16.027 16.861l.446-.894c.062-.123.012-.273-.111-.335L9.098 12l7.264-3.632c.123-.062.173-.212.112-.335l-.447-.895C15.99 7.062 15.89 7 15.804 7c-.033 0-.083.012-.112.026l-9.054 4.568c-.061.03-.113.1-.131.171l-.007.052v.404c0 .095.053.181.138.223l9.054 4.529c.123.062.273.012.335-.112zM17.25 20c.138 0 .25-.112.25-.25v-1c0-.138-.112-.25-.25-.25H5.75c-.138 0-.25.112-.25.25v1c0 .138.112.25.25.25h11.5z"
      />
    </g>
  ),
  LESS: (
    <g>
      <circle cx="12" cy="12" r="12" fill="none" />
      <path
        fill="#0A3AB4"
        d="M16.027 16.861l.446-.894c.062-.123.012-.273-.111-.335L9.098 12l7.264-3.632c.123-.062.173-.212.111-.335l-.446-.895c-.062-.123-.212-.173-.335-.112l-9.054 4.53c-.06.03-.113.1-.131.17l-.007.053v.403c0 .094.053.18.138.223l9.054 4.568c.123.062.273.012.335-.112z"
      />
    </g>
  ),
  NOT_EQUALS: (
    <g>
      <circle cx="12" cy="12" r="12" fill="none" />
      <path
        fill="#0A3AB4"
        d="M7.134 17.927L10.06 15h7.689c.138 0 .25-.112.25-.25v-1c0-.138-.112-.25-.25-.25h-6.19l3-3h3.19c.138 0 .25-.112.25-.25v-1c0-.138-.112-.25-.25-.25h-1.69l1.206-1.205.66-.661c.098-.098.098-.256 0-.354l-.706-.707c-.098-.097-.256-.097-.354 0L13.94 9H6.25c-.138 0-.25.112-.25.25v1c0 .138.112.25.25.25h6.19l-3 3H6.25c-.138 0-.25.112-.25.25v1c0 .138.112.25.25.25h1.69l-1.867 1.866c-.097.097-.097.256 0 .353l.707.708c.098.097.256.097.354 0z"
      />
    </g>
  ),
  ROUTE: (
    <g>
      <circle cx="12" cy="12" r="12" fill="none" />
      <path
        fill="#0A3AB4"
        d="M17.344 20C18.808 20 20 18.808 20 17.344c0-1.465-1.192-2.657-2.656-2.657h-3.719c-.88 0-1.594-.713-1.594-1.593s.714-1.594 1.594-1.594h3.187S20 7.947 20 6.187C20 4.427 18.572 3 16.812 3c-1.76 0-3.187 1.428-3.187 3.187 0 1.15 1.358 3.062 2.3 4.25h-2.3c-1.464 0-2.656 1.192-2.656 2.657 0 1.464 1.192 2.656 2.656 2.656h3.719c.88 0 1.593.714 1.593 1.594 0 .88-.713 1.593-1.593 1.593H7.074c.943-1.188 2.301-3.1 2.301-4.25 0-1.76-1.428-3.187-3.188-3.187C4.427 11.5 3 12.928 3 14.687 3 16.447 6.187 20 6.187 20h11.157zm-.532-10.147c-1.128-1.414-2.125-3.008-2.125-3.666 0-1.172.953-2.125 2.125-2.125 1.173 0 2.125.953 2.125 2.125 0 .678-.999 2.258-2.125 3.666zm0-3.134c.704 0 .701-1.063 0-1.063-.703 0-.7 1.063 0 1.063zM6.187 18.353c-1.128-1.414-2.125-3.008-2.125-3.666 0-1.172.953-2.125 2.125-2.125 1.173 0 2.125.953 2.125 2.125 0 .678-.999 2.258-2.125 3.666zm0-3.134c.704 0 .701-1.063 0-1.063-.703 0-.7 1.063 0 1.063z"
      />
    </g>
  ),
  STATUS_OK: (
    <g>
      <circle cx="12" cy="12" r="12" fill="none" />
      <path
        fill="#0A3AB4"
        d="M12 19.75c4.28 0 7.75-3.47 7.75-7.75 0-4.28-3.47-7.75-7.75-7.75-4.28 0-7.75 3.47-7.75 7.75 0 4.28 3.47 7.75 7.75 7.75zm0-1c-3.708 0-6.75-3.002-6.75-6.75 0-3.708 3.002-6.75 6.75-6.75 3.708 0 6.75 3.002 6.75 6.75 0 3.708-3.002 6.75-6.75 6.75zm-1.214-2.998l5.64-5.595c.147-.145.148-.383.002-.53l-.264-.266c-.146-.147-.383-.148-.53-.002l-5.108 5.066-2.155-2.173c-.146-.147-.384-.148-.53-.002l-.267.264c-.147.146-.148.384-.002.53l2.684 2.706c.145.147.383.148.53.002z"
      />
    </g>
  ),
};
