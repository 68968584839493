import {
  BOT_USER_SESSION_LOGOUT,
  doesUserHasRole,
  SUPER_ADMIN_ROLE,
  BOT_MANAGER_ROLES,
} from "@pypestream/bot-user-session";
import * as t from "./actionTypes";

export const getInitialState = () => ({
  isAuthenticated: false,
  username: "",
  customersList: [],
  wasCustomersFetched: false,
  customers: {},
  trainBot: {},
  envs: [],
  pypes: [],
  streams: {},
  botStreams: {},
  entityData: "",
  intentData: "",
  errorMessage: [],
  isPypeLoading: false,
  isEmptyPypes: false,
  editedIntent: false,
  editedEntity: false,
  interfaces: [],
  selectedInterface: {},
  editedStopWords: false,
  isTrainingNeeded: false,
  // notification
  openNotification: false,
  notificationDuration: null,
  notificationTitle: "",
  notificationType: "",
  notificationIcon: "",
  solutionInfo: {
    loading: true,
    error: false,
    title: "Loading your latest published versions...",
    description: "",
  },
  channels: null,
  loadingChannels: false,
  previewModalOpen: false,
  previewModalRefresh: false,
  previewModalConfig: {},
});

export default (state = getInitialState(), action) => {
  let preStreams;
  let trainBot;
  switch (action.type) {
    case BOT_USER_SESSION_LOGOUT: {
      return getInitialState();
    }
    case t.SET_IF_TRAINING_NEEDED:
      return Object.assign({}, state, {
        isTrainingNeeded: action.data,
      });
    case t.USER_LOGIN:
      return Object.assign({}, state, {
        isAuthenticated: true,
        username: action.username,
      });
    case t.USER_LOGOUT:
      return Object.assign({}, state, {
        isAuthenticated: false,
        username: "",
      });

    case t.GET_CUSTOMER_SUCCESSFULLY:
      return {
        ...state,
        customers: {
          ...state.customers,
          [action.customer.id]: {
            ...action.customer,
            botsData: {
              ...((state.customers &&
                state.customers[action.customer.id]?.botsData) ||
                {}),
            },
          },
        },
      };
    case t.GET_CUSTOMER_FAILED:
      return {
        ...state,
        errorMessage: action.errors,
      };

    case t.GET_PYPES_SUCCESSFULLY:
      return {
        ...state,
        pypes: action.pypes,
        isPypeLoading: false,
        isEmptyPypes: action.isEmptyPypes,
      };

    case t.GET_PYPE_STREAM_SUCCESSFULLY:
      return {
        ...state,
        streams: {
          ...state.streams,
          [action.pypeId]: [...action.streams],
        },
      };

    case t.UPDATE_PYPES_SUCCESSFULLY:
      return {
        ...state,
        pypes: action.pypes,
        streams: {},
        botStreams: {},
        isPypeLoading: true,
        isEmptyPypes: false,
      };

    case t.SET_PYPE_LOADING:
      return {
        ...state,
        isPypeLoading: action.value,
      };

    case t.GET_BOT_STREAM_SUCCESSFULLY:
      preStreams = state.botStreams[action.streamId] || [];
      return {
        ...state,
        botStreams: {
          ...state.botStreams,
          [action.streamId]: [...preStreams, action.stream],
        },
      };

    case t.BOT_DATA_UPDATE:
      return {
        ...state,
        customers: {
          ...state.customers,
          [action.customerId]: {
            ...(state.customers[action.customerId] || {}),
            botsData: {
              ...(state.customers[action.customerId]?.botsData ?? {}),
              ...(state.customers[action.customerId]?.botHistory ?? {}),
              [action.botId]: {
                ...(state.customers[action.customerId]?.botsData?.[
                  action.botId
                ] ?? {}),
                ...action.botData,
              },
            },
          },
        },
      };

    case t.ADD_BOT_TO_CUSTOMER: {
      return {
        ...state,
        customers: {
          ...state.customers,
          [action.customerId]: {
            ...state.customers[action.customerId],
            bots: [
              ...(state?.customers[action?.customerId]?.bots || []),
              action?.botId,
            ],
          },
        },
      };
    }

    case t.DELETE_BOT_FROM_CUSTOMER:
      return {
        ...state,
        customers: {
          ...state.customers,
          [action.customerId]: {
            ...state.customers[action.customerId],
            bots: [
              ...state?.customers[action.customerId]?.bots?.filter(
                (id) => id !== action.botId
              ),
            ],
          },
        },
      };

    case t.TRAIN_BOT:
      trainBot = Object.assign({}, state.trainBot);
      trainBot[action.versionId] = {
        progress: action.progress,
        error: action.error,
      };
      return Object.assign({}, state, { trainBot });

    case t.SET_ENVS:
      return Object.assign({}, state, {
        envs: action.envs,
      });

    case t.GET_CUSTOMERS_SUCCESSFULLY:
      return Object.assign({}, state, {
        customersList: action.customers
          .filter(
            (customer) =>
              doesUserHasRole(SUPER_ADMIN_ROLE) ||
              doesUserHasRole(BOT_MANAGER_ROLES, null, customer.id)
          )
          .map((customer) => ({ customerName: customer.name })),
        wasCustomersFetched: true,
      });

    case t.GET_CUSTOMERS_FAILED:
      return Object.assign({}, state, {
        customersList: [],
        wasCustomersFetched: true,
      });

    case t.SET_INTENT_DATA:
      return Object.assign({}, state, {
        intentData: action.intentData.data,
        editedIntent: action.intentData.isEdited,
      });
    case t.RESET_INTENT_DATA:
      return Object.assign({}, state, {
        intentData: "",
        editedIntent: false,
      });
    case t.SET_ALL_INTENTS:
      return {
        ...state,
        intents: action.intents,
      };
    case t.SET_ENTITY_DATA:
      return Object.assign({}, state, {
        entityData: action.entityData.data,
        editedEntity: action.entityData.isEdited,
      });
    case t.RESET_ENTITY_DATA:
      return Object.assign({}, state, {
        entityData: "",
        editedEntity: false,
      });
    case t.BOT_WAS_UPLOADED_WITH_ERROR:
      return Object.assign({}, state, {
        errorMessage: action.data,
      });
    case t.BOT_WAS_UPLOADED_WITHOUT_ERROR:
      return Object.assign({}, state, {
        errorMessage: [],
      });
    // eslint-disable-next-line no-duplicate-case
    case t.BOT_WAS_UPLOADED_WITH_ERROR:
      return Object.assign({}, state, {
        errorMessage: action.data,
      });
    // eslint-disable-next-line no-duplicate-case
    case t.BOT_WAS_UPLOADED_WITHOUT_ERROR:
      return Object.assign({}, state, {
        errorMessage: [],
      });
    case t.GET_INTERFACES:
      return {
        ...state,
        interfaces: [...state.interfaces, ...action.interfaces],
      };
    case t.SELECTED_INTERFACE:
      return {
        ...state,
        selectedInterface: action.selectedInterface,
      };
    case t.REMOVE_INTERFACES:
      return {
        ...state,
        interfaces: [],
      };
    case t.SET_STOP_WORDS_EDITED:
      return Object.assign({}, state, {
        editedStopWords: action.isEdited,
      });
    case t.SET_NOTIFICATION:
      return Object.assign({}, state, {
        ...action.data,
      });
    case t.UPDATE_SOLUTION_INFO:
      return Object.assign({}, state, {
        solutionInfo: {
          ...action.data,
        },
      });
    case t.UPDATE_CHANNELS:
      return {
        ...state,
        channels: action.channels,
      };
    case t.SET_PREVIEW_MODAL_OPEN:
      return {
        ...state,
        previewModalOpen: action.open,
        previewModalRefresh: state.previewModalOpen && action.open,
        previewModalConfig: action.config,
      };
    case t.RESET_PREVIEW_REFRESH_FLAG:
      return {
        ...state,
        previewModalRefresh: false,
      };
    case t.LOADING_CHANNELS:
      return {
        ...state,
        loadingChannels: action.loading,
      };
    default:
      return state;
  }
};
