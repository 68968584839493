import { QaDumpContext } from "app";
import { useContext } from "react";

export const useQadump = () => {
  const data = useContext(QaDumpContext);
  return data;
};

// function for qaDumpDataProvider
export const downloadTxtFile = (config, env, flags) => {
  const element = document.createElement("a");
  const file = new Blob(
    [
      "Configuration: " +
        "\n" +
        JSON.stringify(config) +
        "\n" +
        "Environment Variables: " +
        "\n" +
        JSON.stringify(env) +
        "\n" +
        "Flags: " +
        "\n" +
        JSON.stringify(flags),
    ],
    { type: "text/plain" }
  );
  element.href = URL.createObjectURL(file);
  element.download = `${new Date().toLocaleString()}.qaDumpdata.txt`;
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
};
