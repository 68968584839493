import React, { useState, useMemo, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { withLDConsumer } from "launchdarkly-react-client-sdk";

import { default as PreviewModalWith20644FF } from "../PreviewPanel/PreviewModalWith20644FF";
import { default as PreviewModalWithout20644FF } from "../PreviewPanel/PreviewModalWithout20644FF";

import PassthroughModal from "../PreviewPanel/PassthroughModal";

import {
  setPreviewModalOpen,
  setNotification,
  resetPreviewRefreshFlag,
} from "../../../Store/actions";
import botManagerAPI from "../../../../bot-manager-api";
import {
  uploadSolutionGraph,
  setSolutionErrors,
} from "Solution/store/solution/actions";
import { Emitter, EMITTER_EVENTS } from "Solution/services/emitter";

import "./style.scss";

export const WebChatPreview = ({
  previewModalOpen,
  previewModalRefresh,
  previewModalConfig,
  customers,
  flags,
  botUserSession,
  setPreviewModalOpen,
  uploadSolutionGraph,
  setSolutionErrors,
  setNotification,
  resetPreviewRefreshFlag,
}) => {
  const [showPassthroughModal, setShowPassthroughModal] = useState(false);
  const [sandboxPreviewMetadata, setSandboxPreviewMetadata] = useState(
    `{"preview_metadata": {"onlyedit": "thesevalues"}}`
  );
  const [parametersError, setParametersError] = useState(false);

  const previewRefreshRef = useRef(null);

  const customer = useMemo(() => {
    if (isEmpty(customers) || isEmpty(previewModalConfig)) {
      return null;
    }

    return customers[previewModalConfig.customerId];
  }, [customers, previewModalConfig]);

  const botData = useMemo(() => {
    if (isEmpty(customer)) {
      return null;
    }

    return customer ? customer.botsData[previewModalConfig.botId] : null;
  }, [customer, previewModalConfig]);

  const closePreviewHandler = (Pypestream) => {
    try {
      if (typeof Pypestream === "function") {
        Pypestream.shutdown();
      }
    } catch (err) {
      console.log(err);
    }
    setPreviewModalOpen(false, previewModalConfig);
  };

  const refreshPreviewModal = () => {
    console.log("refresh clicked");
  };

  const updateMetadata = (metadata) => {
    if (flags?.pe20222) {
      setSandboxPreviewMetadata(
        metadata != "undefined" && metadata != undefined
          ? `{"preview_metadata": ${JSON.stringify({ ...metadata })}}`
          : `{"preview_metadata": {"title": "value"}}`
      );
    } else {
      setSandboxPreviewMetadata(
        metadata != "undefined" && metadata != undefined
          ? `{"preview_metadata": ${JSON.stringify([...metadata])}}`
          : `{"preview_metadata": [{"title": "value"}]}`
      );
    }
  };

  const handleSubmitPassthrough = (value) => {
    if (flags?.pe20257) {
      setSandboxPreviewMetadata(value);
      previewRefreshRef?.current?.handleSubmitPassthrough(value);

      try {
        JSON.parse(value);
        setShowPassthroughModal(false);
        setParametersError(false);
      } catch (e) {
        setParametersError(true);
      }
    } else {
      previewRefreshRef?.current?.handleSubmitPassthrough();

      try {
        JSON.parse(sandboxPreviewMetadata);
        setShowPassthroughModal(false);
        setParametersError(false);
      } catch (e) {
        setParametersError(true);
      }
    }
  };

  const onChangeParameters = (value) => {
    setSandboxPreviewMetadata(value);
  };

  const showSolutionErrors = (errorType, messages) => {
    let notificationTitle = "";
    switch (errorType) {
      case "upload":
        notificationTitle = `<ul>${messages.reduce(
          (res, err, index) =>
            res +
            `<li><b>Error ${index + 1}:</b> ${
              err.err_msgs[0].error_description
            }</li>`,
          ""
        )}</ul>`;
        setSolutionErrors(messages);
        break;
      default:
        setSolutionErrors([]);
        notificationTitle = Array.isArray(messages)
          ? messages.join("<br>")
          : messages;
    }

    setNotification({
      openNotification: true,
      notificationDuration: 6000,
      notificationTitle,
      notificationType: "error",
    });
    Emitter.emit(EMITTER_EVENTS.SHOW_NODE_ERROR_PANEL);
  };

  const acceptRefreshRequest = (callback) => {
    uploadSolutionGraph(
      previewModalConfig.customerId,
      previewModalConfig.botId,
      true,
      (err, messages, errorType) => {
        if (err) {
          showSolutionErrors(errorType, messages);
        } else {
          setSolutionErrors([]);
          if (errorType === "open-preview") {
            if (flags?.pe22564) {
              const { latestVersion } = botData;
              const { id: latestVersionId } = latestVersion;
              botManagerAPI
                .setPreviewWidget(latestVersionId)
                .then(() => {
                  previewRefreshRef?.current?.refresh();
                  if (callback) {
                    callback();
                  }
                })
                .catch((error) => {
                  console.log(error.message);
                  callback();
                });
            } else {
              previewRefreshRef?.current?.refresh();
            }
          }
          if (!flags?.pe22564 && callback) {
            callback(false);
          }
        }
      }
    );
  };

  useEffect(() => {
    const activePreviewPanelClassName = "has-preview-panel-active";

    if (!previewModalOpen) {
      document.body.classList.remove(activePreviewPanelClassName);

      return;
    }

    document.body.classList.add(activePreviewPanelClassName);
  }, [previewModalOpen]);

  useEffect(() => {
    if (previewModalRefresh && flags.pe19501) {
      previewRefreshRef?.current?.refresh();
      resetPreviewRefreshFlag();
    }
  }, [previewModalRefresh, flags.pe19501, resetPreviewRefreshFlag]);

  /**
   * While retiring pe20644 feature flag,
   * 1. We do need to rename `PreviewModalWith20644FF` file to PreviewModal, and we should update imports w.r.t. that
   * 2. We should delete PreviewModalWithout20644FF file altogether.
   */
  const PreviewModal = flags?.pe20644
    ? PreviewModalWith20644FF
    : PreviewModalWithout20644FF;

  return customer && botData ? (
    <React.Fragment>
      <PreviewModal
        ref={previewRefreshRef}
        title={flags.pe19501 ? "Preview Solution" : "Preview Conversation"}
        envs={["live", "sandbox"]}
        show={previewModalOpen}
        flags={flags}
        customer={customer}
        botData={botData}
        botUserId={botUserSession?.user?.user_id}
        sandboxPreviewMetadata={sandboxPreviewMetadata}
        handleClose={closePreviewHandler}
        handleRefresh={refreshPreviewModal}
        handleEdit={() => setShowPassthroughModal(true)}
        updateMetadata={updateMetadata}
        refreshPreview={acceptRefreshRequest}
        {...previewModalConfig}
      />
      {showPassthroughModal && (
        <PassthroughModal
          flags={flags}
          passthroughModal={showPassthroughModal}
          sandboxPreviewMetadata={sandboxPreviewMetadata}
          parametersError={parametersError}
          onClose={() => setShowPassthroughModal(false)}
          submitPassthrough={handleSubmitPassthrough}
          onChange={(value) =>
            onChangeParameters(flags?.pe20257 ? value : value.target.value)
          }
        />
      )}
    </React.Fragment>
  ) : null;
};

const mapStateToProps = (state) => {
  const { previewModalOpen, previewModalConfig, previewModalRefresh } =
    state.manager;

  return {
    previewModalOpen,
    previewModalRefresh,
    previewModalConfig,
    customers: state.manager.customers,
    botUserSession: state.botUserSession,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setPreviewModalOpen: (open, config) =>
    dispatch(setPreviewModalOpen(open, config)),
  uploadSolutionGraph: (customerId, botId, isChecking, callback) =>
    dispatch(uploadSolutionGraph(customerId, botId, isChecking, callback)),
  setSolutionErrors: (errors) => dispatch(setSolutionErrors(errors)),
  setNotification: (data) => dispatch(setNotification(data)),
  resetPreviewRefreshFlag: () => dispatch(resetPreviewRefreshFlag()),
});

WebChatPreview.propTypes = {
  previewModalOpen: PropTypes.bool,
  previewModalRefresh: PropTypes.bool,
  flags: PropTypes.object,
  previewModalConfig: PropTypes.object,
  customers: PropTypes.object,
  botUserSession: PropTypes.object,
  setPreviewModalOpen: PropTypes.func,
  uploadSolutionGraph: PropTypes.func,
  setSolutionErrors: PropTypes.func,
  setNotification: PropTypes.func,
  resetPreviewRefreshFlag: PropTypes.func,
};

WebChatPreview.defaultProps = {
  previewModalOpen: false,
  previewModalRefresh: false,
  previewModalConfig: {},
  flags: {},
  customers: {},
  botUserSession: {},
  setPreviewModalOpen: () => {},
  uploadSolutionGraph: () => {},
  setSolutionErrors: () => {},
  setNotification: () => {},
  resetPreviewRefreshFlag: () => {},
};

export default withLDConsumer()(
  connect(mapStateToProps, mapDispatchToProps)(WebChatPreview)
);
