export function makeN8NConfig(email, password, base_url, timeout) {
  let conf = {
    n8n_login_email: email,
    n8n_login_password: password,
  };

  if (base_url) {
    conf["n8n_base_url"] = base_url;
  }
  if (timeout) {
    conf["n8n_timeout_seconds"] = timeout;
  }
  return conf;
}
